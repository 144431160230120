import {
    Button,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card";
// import EventService from "../../../../axios/service/EventService"

export default function UserCreatePage() {
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const [DataList, setDataList] = useState([]);
    const [loadingData, setLoadingData] = useState(true)

    useEffect(() => {
        getDatas()
    }, [])


    const getDatas = () => {
        // setLoadingData(true)
        // EventService.getAll().then(response => {
        //     console.log("GetAll response is ", response)
        //     setLoadingData(false)
        //     if (response.status == 200) {
        //         setDataList(response.data)
        //     } else {
        //         setDataList([])
        //     }
        // })
    }

    const handleDate = (dat) => {  
        if (dat == null) { return ''; }
        const milliseconds = Date.parse(dat);
        const date = new Date(milliseconds);
        
        const options = { year: "numeric", month: "long", day: "numeric"};
        const dateString = date.toLocaleDateString("en-US", options);
        return dateString;
    }

    const handleTime = (dat) => {  
        if (dat == null) { return ''; }
        const milliseconds = Date.parse(dat);
        const date = new Date(milliseconds);
        const options = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: '2-digit', hour12: true  };
        const dateString = date.toLocaleDateString("en-US", options);
        return dateString;
    }

    return (
        <>
            <Card
                direction='column'
                w='100%'
                px='0px'
                overflowX={{ sm: "scroll", lg: "hidden" }}>
                <Flex px='25px' justify='space-between' mb='20px' align='center'>
                    <Text
                        color={textColor}
                        fontSize='22px'
                        fontWeight='700'
                        lineHeight='100%'>
                        User Activity Log
                    </Text>
                </Flex>

                <Table variant='simple' color='gray.500' mb='24px'>
                    <Thead>
                        <Tr>
                            <Th
                                pe='10px'
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color='gray.400'>
                                    Event Type
                                </Flex>
                            </Th>
                            <Th
                                pe='10px'
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color='gray.400'>
                                    User
                                </Flex>
                            </Th>
                            <Th
                                pe='10px'
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color='gray.400'>
                                    Event Data
                                </Flex>
                            </Th>
                            <Th
                                pe='10px'
                                borderColor={borderColor}>
                              <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color='gray.400'>
                                    Create Date
                                </Flex>
                            </Th>
                        </Tr>
                    </Thead>
                    {loadingData ? (
                        <Flex align='center' justify='center' minHeight='100px' marginLeft='auto' w='100%'>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                            />
                        </Flex>
                    ) : (
                        <Tbody>
                            {
                                DataList.map((data, index) => {
                                    return (
                                        <Tr key={data.data_id}>
                                            <Td align='center'>
                                                <Text
                                                    color={textColor}
                                                    fontSize='15px'
                                                    fontWeight='700'
                                                    lineHeight='100%'>
                                                    {data.event_type}
                                                </Text>
                                            </Td>
                                            <Td align='center'>
                                                <Text
                                                    color={textColor}
                                                    fontSize='15px'
                                                    fontWeight='700'
                                                    lineHeight='100%'>
                                                    {data.user_id}
                                                </Text>
                                            </Td>
                                            <Td align='center'>
                                                <Text
                                                    color={textColor}
                                                    fontSize='15px'
                                                    fontWeight='700'
                                                    lineHeight='100%'>
                                                    { data.event_data }
                                                </Text>
                                            </Td>
                                            <Td align='center'>
                                                <Text
                                                    color={textColor}
                                                    fontSize='15px'
                                                    fontWeight='700'
                                                    lineHeight='100%'>
                                                    {handleDate(data.created_at)}
                                                </Text>
                                            </Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    )}

                </Table>
            </Card>
        </>

    );
}
