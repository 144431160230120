// FormFields/NumberField.jsx
import React from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';

const NumberField = ({ label, value, onChange, ...rest }) => (
  <FormControl mb={4}>
    <FormLabel>{label}</FormLabel>
    <Input
      type="number"
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      {...rest}
    />
  </FormControl>
);

export default NumberField;