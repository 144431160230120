// mockData.js
export const mockBanners = {
  pending: Array.from({ length: 0 }, (_, i) => ({
    id: i + 1,
    order: i + 1,
    url: `https://example.com/pending_banner_${i + 1}`,
    start_date: '2024-07-01',
    end_date: '2024-07-31',
    translations: {
      en: { image_url: `https://via.placeholder.com/200x100?text=Pending+Banner+${i + 1}` },
      fr: { image_url: '' },
      jp: { image_url: '' },
      es: { image_url: '' },
      de: { image_url: '' },
      hant: { image_url: '' },
      hans: { image_url: '' },
    },
  })),
  active: Array.from({ length: 1 }, (_, i) => ({
    id: i + 21,
    order: i + 21,
    url: `https://example.com/active_banner_${i + 1}`,
    start_date: '2024-08-01',
    end_date: '2024-08-31',
    translations: {
      en: { image_url: `https://via.placeholder.com/200x100?text=Active+Banner+${i + 1}` },
      fr: { image_url: '' },
      jp: { image_url: '' },
      es: { image_url: '' },
      de: { image_url: '' },
      hant: { image_url: '' },
      hans: { image_url: '' },
    },
  })),
  other: Array.from({ length: 4 }, (_, i) => ({
    id: i + 41,
    order: i + 41,
    url: `https://example.com/other_banner_${i + 1}`,
    start_date: '2024-09-01',
    end_date: '2024-09-30',
    translations: {
      en: { image_url: `https://via.placeholder.com/200x100?text=Other+Banner+${i + 1}` },
      fr: { image_url: '' },
      jp: { image_url: '' },
      es: { image_url: '' },
      de: { image_url: '' },
      hant: { image_url: '' },
      hans: { image_url: '' },
    },
  })),
};
