
// import React from 'react';
// import { Box, Text, Image, VStack, HStack, Button, IconButton, Badge } from '@chakra-ui/react';
// import { EditIcon, DeleteIcon } from '@chakra-ui/icons';

// const CouponCard = ({ item, onEdit, onDelete }) => {
//   const {
//     title = '',
//     description = '',
//     coupon_code = '',
//     image_url,
//     redemption_start_date = '',
//     redemption_end_date = '',
//     total_quantity = 0,
//     remaining_quantity = 0,
//     effective_start_date = '',
//     effective_end_date = '',
//     points_required = '',
//     created_at = '',
//     updated_at = '',
//   } = item || {};
//   return (
//     <Box
//       borderWidth="1px"
//       borderRadius="lg"
//       overflow="hidden"
//       p={5}
//       boxShadow="md"
//       minWidth="200px"
//       maxWidth="300px"
//     >
//       <Image src={image_url} alt={title} boxSize="200px" objectFit="cover" mb={4} />
//       <VStack align="start" spacing={2} wrap="wrap">
//         <Text fontSize="xl" fontWeight="bold" noOfLines={2}>{title}</Text>
//         <Text fontSize="md" noOfLines={3}>{description}</Text>
//         <VStack wrap="wrap" align="start">
//           <Badge colorScheme="blue">Coupon Code: {coupon_code}</Badge>
//           <Badge colorScheme="green">Redemption: {new Date(redemption_start_date).toLocaleDateString()} - {new Date(redemption_end_date).toLocaleDateString()}</Badge>
//           <Badge colorScheme="green">Effect: {new Date(effective_start_date).toLocaleDateString()} - {new Date(effective_end_date).toLocaleDateString()}</Badge>
//           <Badge colorScheme="green">Quantity: {total_quantity}</Badge>
//           <Badge colorScheme="green">Remain: {remaining_quantity}</Badge>
//         </VStack>
//         <Text fontSize="sm">Points Required: {points_required}</Text>
//         <Text fontSize="sm">Create Date: {new Date(created_at).toLocaleDateString()}</Text>
//         <Text fontSize="sm">Update Date: {new Date(updated_at).toLocaleDateString()}</Text>
//       </VStack>
//       <HStack spacing={2} mt={4}>
//           <IconButton icon={<EditIcon />} aria-label="Edit Coupon" onClick={() => onEdit(item)} />
//           <IconButton icon={<DeleteIcon />} aria-label="Delete Coupon" onClick={() => onDelete(item)} />
//       </HStack>
//     </Box>
//   );
// };

// export default CouponCard;


import React from 'react';
import { Box, Text, Image, VStack, HStack, Button, IconButton, Badge } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

const CouponCard = ({ item, onEdit, onDelete }) => {
  const {
    title = '',
    description = '',
    coupon_code = '',
    image_url,
    redemption_start_date = '',
    redemption_end_date = '',
    total_quantity = 0,
    remaining_quantity = 0,
    effective_start_date = '',
    effective_end_date = '',
    points_required = '',
    created_at = '',
    updated_at = '',
  } = item || {};

  // console.log('item',item);
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
      boxShadow="md"
      minWidth="200px"
      maxWidth="300px"
    >
      <Image src={image_url} alt={title} boxSize="200px" objectFit="cover" mb={4} />
      <VStack align="start" spacing={2} wrap="wrap">
        <Text fontSize="xl" fontWeight="bold" noOfLines={2}>{title}</Text>
        <Text fontSize="md" noOfLines={3}>{description}</Text>
        <VStack wrap="wrap" align="start">
          <Badge colorScheme="blue">Coupon Code: {coupon_code}</Badge>
          <Badge colorScheme="green">Redemption Start: {new Date(redemption_start_date).toLocaleDateString()}</Badge>
          <Badge colorScheme="green">Redemption End: {new Date(redemption_end_date).toLocaleDateString()}</Badge>
          <Badge colorScheme="green">Effect: {new Date(effective_start_date).toLocaleDateString()} - {new Date(effective_end_date).toLocaleDateString()}</Badge>
          <Badge colorScheme="green">Quantity: {total_quantity}</Badge>
          <Badge colorScheme="green">Remain: {remaining_quantity}</Badge>
        </VStack>
        <Text fontSize="sm">Points Required: {points_required}</Text>
        <Text fontSize="sm">Create Date: {new Date(created_at).toLocaleDateString()}</Text>
        <Text fontSize="sm">Update Date: {new Date(updated_at).toLocaleDateString()}</Text>
      </VStack>
      <HStack spacing={2} mt={4}>
          <IconButton icon={<EditIcon />} aria-label="Edit Coupon" onClick={() => onEdit(item)} />
          <IconButton icon={<DeleteIcon />} aria-label="Delete Coupon" onClick={() => onDelete(item)} />
      </HStack>
    </Box>
  )
};

export default CouponCard;




