
// // Chakra imports
// import { Box, SimpleGrid } from "@chakra-ui/react";
// import BannerPage from "views/admin/promotion/home/BannerPage";
// import React from "react";

// export default function Settings() {

//     const BannerColum = [
//         {
//             Header: "Banners",
//             accessor: "name",
//         },
//         {
//             Header: "Action",
//             accessor: "progress",
//         },
//     ]

//     return (
//         <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//             <SimpleGrid
//                 // mb='20px'
//                 // columns={{ sm: 1, md: 2 }}
//                 spacing={{ base: "20px", xl: "20px" }}>
//                 <BannerPage
//                     columnsData={BannerColum}
//                 />
//             </SimpleGrid>
//         </Box>
//     );
// }


// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import BannerManagementPage from '../../components/banners/BannerManagementPage';
import React from "react";
import Card from "components/card/Card";

export default function Settings() {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card
                direction='column'
                w='100%'
                px='20px'
                overflowX={{ sm: "scroll", lg: "hidden" }}>
                <BannerManagementPage bannerType='home_banner'></BannerManagementPage>
            </Card>
        </Box>
    );
}
