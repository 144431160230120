// components/Pagination.js
import React from 'react';
import { Box, Button, Flex, IconButton, Select, Text, HStack, Spacer } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const Pagination = ({
    currentPage,
    totalPages,
    onPageChange,
    itemsPerPage,
    setItemsPerPage,
    itemsPerPageOptions,
}) => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
    const endPage = Math.min(totalPages, startPage + 4);

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const handleChangePage = (e) => {
        onPageChange(Number(e.target.value));
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
    };

    return (
        totalPages > 1 && (
            <Flex w="100%" wrap="wrap" direction="row" align="center">
                <Flex align="center" pt={2}>
                    <Text mr={2}>Items per page:</Text>
                    <Select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        width="auto"
                        variant="outline"
                        size="md"
                    >
                        {itemsPerPageOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </Select>
                </Flex>
                <Spacer></Spacer>
                <Flex align="center" wrap="wrap" pt={2}>
                    <IconButton
                        icon={<ChevronLeftIcon />}
                        onClick={() => onPageChange(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        aria-label="Previous page"
                        // variant="outline"
                        mx={1}
                        size="md"
                    />
                    {pageNumbers.map((page) => (
                        <Button
                            key={page}
                            onClick={() => onPageChange(page)}
                            isActive={page === currentPage}
                            variant={page === currentPage ? 'solid' : 'outline'}
                            mx={1}
                            size="md"
                        >
                            {page}
                        </Button>
                    ))}
                    <IconButton
                        icon={<ChevronRightIcon />}
                        onClick={() => onPageChange(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        aria-label="Next page"
                        // variant="outline"
                        mx={1}
                        size="md"
                    />
                    <Select
                        value={currentPage}
                        onChange={handleChangePage}
                        ml={4}
                        width="auto"
                        variant="outline"
                        size="md"
                    >
                        {Array.from({ length: totalPages }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                                Page {i + 1}
                            </option>
                        ))}
                    </Select>
                </Flex>
            </Flex>
        )
    );
};

export default Pagination;
