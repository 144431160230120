import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditMaintainenceModal = ({ isOpen, onClose, initialData, onSave }) => {
  const dataStructure = {
    start_date: { type: 'date', label: 'Start Date', required: true },
    end_date: { type: 'date', label: 'End Date', required: true },
  };

  return (
    <BaseAddEditModal
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="maintenance"
      // mainKey='tier'
    />
  );
};
export default AddEditMaintainenceModal;










