import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditPushNotificationModal = ({ isOpen, onClose, initialData, onSave }) => {
  const dataStructure = {
    target: { type: 'text', label: 'Target', required: true },
    deep_link: { type: 'text', label: 'Deep Link' },
    scheduled_at: { type: 'date', label: 'Scheduled At', required: true },
    sent_at: { type: 'date', label: 'Sent At', required: true },
  };

  const tableHeaders = [
    // 'id',
    'translations.0.title',
    'translations.0.message',
    'translations.0.language',
    'target', 
    'push_notification_topics.name', 
    'push_notification_topics.description',
    'deep_link', 
    'status', 
    'scheduled_at', 
    'sent_at', 
    'created_by', 
    'created_at', 
    'updated_at',
  ];

  return (
    <BaseAddEditModal
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="push_notifications"
    />
  );
};

export default AddEditPushNotificationModal;








