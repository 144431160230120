import React, { useState } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent';
import AddEditMaintainenceModal from './AddEditMaintainenceModal';
import MaintainenceCard from './MaintainenceCard';

// {
//   "id": 1,
//   "start_date": "2024-04-18T04:11:56+00:00",
//   "end_date": "2024-05-02T04:11:59+00:00",
//   "created_at": "2024-04-18T04:12:03.088782+00:00",
//   "updated_at": "2024-04-18T04:12:03.088782+00:00"
// }

const MaintainencesPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'maintenance';

  const itemsPerPageOptions = [5, 10, 15];

  const keyMappings = {
    start_date: 'Start Date',
    end_date: 'End Date',
    updated_at: 'Last Modify Date'
  };

  const tableHeaders = [
    'start_date', 
    'end_date', 
    'updated_at', 
  ];
  return (
    <BasePageComponent
      txt="Maintainence"
      addItemComponent={AddEditMaintainenceModal}
      itemCardComponent={MaintainenceCard}
      showToggleView={false}
      tableName={tableName}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} // Pass key mappings to BasePageComponent
      dateCondition={{
        type: 'range',
        fields: {
          start: 'start_date',
          end: 'end_date',
        },
      }}
      disabledButtons={['edit', 'delete']}
    />
  );
};

export default MaintainencesPage;
