import React from 'react';
import { Box, Text, Image, VStack, HStack, Button, IconButton, Badge, Flex, Spacer } from '@chakra-ui/react';
import { EditIcon, ViewIcon, DeleteIcon } from '@chakra-ui/icons';

const getLanguage = (item) => {
    var name = ''
    if (item?.language == 'zh-Hant') {
      name = 'Traditional Chinese'
    }else if (item?.language == 'zh-Hans') {
      name = 'Simplified Chinese'
    } else {
      name = 'English'
    }
    return name;
  };

const ImageCard = ({ banner, onEdit, onView, onDelete, ...rest }) => {

  // console.log('recount',recount++)

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
      boxShadow="md"
      minWidth="200px"
      maxWidth="300px"
    >
        <Text fontWeight="bold" textAlign="left">{getLanguage(banner)}</Text>
        <Box p={3}></Box>
        <Box
              w="full"
              h="180px"
            //   bg="gray.200"
              // borderRadius="md"
              overflow="hidden"
          // display="flex"
          // alignItems="center"
          // justifyContent="center"
          >
              <Image
                  src={banner.image_url}
                  alt=" "
                  objectFit="contain" // w 232 h 120  Changed to "contain" to ensure the image fits within the container
                  w="full"
                  h="full"
              />
        </Box>
        <Box p={3}></Box>
        <Flex w="100%">
          <Spacer />
          <IconButton icon={<EditIcon />} aria-label="Edit" onClick={() => onEdit(banner)} />
          <Box p={2}></Box>
          <IconButton icon={<DeleteIcon />} aria-label="Delete" onClick={() => onDelete(banner)} />
        </Flex>
    </Box>
  );
};
export default ImageCard;