// {
//   "id": 1,
//   "start_date": "2024-04-22T07:25:32+00:00",
//   "end_date": "2024-04-29T07:25:35+00:00",
//   "created_at": "2024-04-22T07:25:39.664859+00:00",
//   "updated_at": "2024-04-22T07:25:39.664859+00:00",
//   "latest_news_translations": [
//       {
//           "id": 2,
//           "url": null,
//           "title": "title-tc",
//           "news_id": 1,
//           "language": "zh-Hant",
//           "image_url": null,
//           "created_at": "2024-04-22T07:37:45.195513+00:00",
//           "updated_at": "2024-04-22T07:37:45.195513+00:00",
//           "description": null
//       },
//       {
//           "id": 3,
//           "url": null,
//           "title": "title-sc",
//           "news_id": 1,
//           "language": "zh-Hans",
//           "image_url": null,
//           "created_at": "2024-04-22T07:37:58.253859+00:00",
//           "updated_at": "2024-04-22T07:37:58.253859+00:00",
//           "description": null
//       }
//   ]
// }

import React, { useState } from 'react';
import BasePageComponent from '../components/base/BasePageComponent'
import AddEditNewsModal from './AddEditNewsModal';
import NewsCard from './NewsCard';

const NewsPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'latest_news';
  const joins = [
    { table: 'latest_news_translations', fields: '*', foreignKey: 'id' }
  ];

  const keyMappings = {
    // id: 'ID',
    image_url: 'Image',
    // language: 'Language',
    title: 'Title',
    description: 'Description',
    // url: 'Url',
    start_date: 'Start Date',
    end_date: 'End Date',
    created_at: 'Created At',
    updated_at: 'Updated At'
  };

  const tableHeaders = [
    // 'id',
    'translations.0.image_url',
    // 'translations.0.language',
    'translations.0.title',
    'translations.0.description',
    // 'translations.0.url',
    'start_date', 
    'end_date', 
    'created_at', 
    'updated_at',
  ];

  return (
    <BasePageComponent
      txt="Latest News"
      addItemComponent={AddEditNewsModal}
      itemCardComponent={NewsCard}
      showToggleView={false}
      tableName={tableName}
      joins={joins}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} // Pass key mappings to BasePageComponent
    />
  );
};

export default NewsPage;

