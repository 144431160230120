import * as OpenCC from 'opencc-js';
import axios from 'axios';

const DICT_BASE_URL = 'https://raw.githubusercontent.com/BYVoid/OpenCC/master/data/dictionary/';

const dictionaries = {
  'cn2hk': [
    { file: 'TWPhrasesIT.txt', reverse: false },
    { file: 'TWPhrasesName.txt', reverse: false },
    { file: 'TWPhrasesOther.txt', reverse: false },
    { file: 'TWVariants.txt', reverse: false },
    { file: 'STPhrases.txt', reverse: false },
    { file: 'STCharacters.txt', reverse: false },
  ],
  'hk2cn': [
    { file: 'TWPhrasesIT.txt', reverse: true },
    { file: 'TWPhrasesName.txt', reverse: true },
    { file: 'TWPhrasesOther.txt', reverse: true },
    { file: 'TWVariants.txt', reverse: true },
    { file: 'TSPhrases.txt', reverse: false },
    { file: 'TSCharacters.txt', reverse: false },
  ]
};

const loadDictionary = async (files) => {
  const dict = [];
  for (const { file, reverse } of files) {
    const response = await axios.get(`${DICT_BASE_URL}${file}`);
    const lines = response.data.split('\n');
    for (const line of lines) {
      if (line && !line.startsWith('#')) {
        const [key, value] = line.split('\t');
        dict.push(reverse ? [value, key] : [key, value]);
      }
    }
  }
  return dict;
};

let converters = {};
let customConverters = {};
let isInitialized = false;
let initializationPromise = null;

const initializeConverters = async () => {
  if (initializationPromise) {
    return initializationPromise;
  }

  initializationPromise = Promise.all([
    ...Object.entries(dictionaries).map(async ([key, files]) => {
      const [from, to] = key.split('2');
      converters[key] = OpenCC.Converter({ from, to });
      
      const customDict = await loadDictionary(files);
      customConverters[key] = OpenCC.CustomConverter(customDict);
    })
  ]).then(() => {
    isInitialized = true;
    console.log('All converters initialized');
  });

  return initializationPromise;
};

export const ensureConvertersInitialized = async () => {
  if (!isInitialized) {
    await initializeConverters();
  }
};

export const translateText = (text, fromLocale, toLocale) => {
  if (!isInitialized) {
    throw new Error('Converters not initialized. Call ensureConvertersInitialized first.');
  }
  const converterKey = `${fromLocale}2${toLocale}`;
  if (!converters[converterKey]) {
    console.error(`No converter found for ${converterKey}`);
    return text;
  }
  var result = ''
  if (converterKey === 'hk2cn') {
    result = customConverters[converterKey](String(text || ''));
    result = converters[converterKey](result);
  }else {
    result = converters[converterKey](String(text || ''));
    result = customConverters[converterKey](result);
  }

  console.log('result', result);
  return result;
};

export const getLocaleFromLanguage = (language) => {
  return language === 'zh-Hans' ? 'cn' : 'hk';
};

export const handleTranslate = async (sourceText, sourceLanguage, targetLanguage) => {
  await ensureConvertersInitialized();
  const fromLocale = getLocaleFromLanguage(sourceLanguage);
  const toLocale = getLocaleFromLanguage(targetLanguage);
  return translateText(sourceText, fromLocale, toLocale);
};