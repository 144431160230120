import React from 'react';
import { Box, Text, Image, VStack, HStack, Button, IconButton, Divider, Badge } from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';

const CouponCardList = ({ item, onEdit, onDelete }) => {
  const {
    title = '',
    description = '',
    coupon_code = '',
    image_url,
    redemption_start_date = '',
    redemption_end_date = '',
    total_quantity = 0,
    remaining_quantity = 0,
    effective_start_date = '',
    effective_end_date = '',
    points_required = '',
    created_at = '',
    updated_at = '',
  } = item || {};

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} width="full">
      <HStack spacing={4}>
        <Image src={image_url} alt={title} boxSize="100px" objectFit="cover" />
        <VStack align="start" spacing={2} flex="1">
          <Text fontWeight="bold">{title}</Text>
          <Text>{description}</Text>
          <VStack align="start">
          <Badge colorScheme="blue">Coupon Code: {coupon_code}</Badge>
          <Badge colorScheme="green">Redemption Start: {new Date(redemption_start_date).toLocaleDateString()}</Badge>
          <Badge colorScheme="green">Redemption End: {new Date(redemption_end_date).toLocaleDateString()}</Badge>
          <Badge colorScheme="green">Effect: {new Date(effective_start_date).toLocaleDateString()} - {new Date(effective_end_date).toLocaleDateString()}</Badge>
          <Badge colorScheme="green">Quantity: {total_quantity}</Badge>
          <Badge colorScheme="green">Remain: {remaining_quantity}</Badge>
          </VStack>
          <Text fontSize="sm">Create Date: {new Date(created_at).toLocaleDateString()}</Text>
          <Text fontSize="sm">Update Date: {new Date(updated_at).toLocaleDateString()}</Text>
        </VStack>
        <HStack>
          <IconButton icon={<EditIcon />} onClick={() => onEdit(item)} />
          <IconButton icon={<DeleteIcon />} onClick={() => onDelete(item)} />
        </HStack>
      </HStack>
      <Divider mt={2} />
    </Box>
  );
};

export default CouponCardList;
