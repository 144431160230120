// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   FormControl,
//   FormLabel,
//   VStack,
//   Image,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   Stack,
//   IconButton,
//   Input,
//   useToast,
// } from '@chakra-ui/react';
// import { AddIcon } from '@chakra-ui/icons';

// const placeholder = '/path/to/en-placeholder.jpg';

// const UpdateBannerCard = ({ isOpen, onClose, onSave, bannerToEdit }) => {
//   const [enImageUrl, setEnImageUrl] = useState('');
//   const [frImageUrl, setFrImageUrl] = useState('');
//   const [jpImageUrl, setJpImageUrl] = useState('');
//   const [url, setUrl] = useState('');
//   const [order, setOrder] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [enImageFile, setEnImageFile] = useState(null);
//   const [frImageFile, setFrImageFile] = useState(null);
//   const [jpImageFile, setJpImageFile] = useState(null);
//   const [submitting, setSubmitting] = useState(false);
//   const toast = useToast();

//   useEffect(() => {
//     if (isOpen && bannerToEdit) {
//       setEnImageUrl(bannerToEdit.enImageUrl);
//       setFrImageUrl(bannerToEdit.frImageUrl);
//       setJpImageUrl(bannerToEdit.jpImageUrl);
//       setUrl(bannerToEdit.url);
//       setOrder(bannerToEdit.order);
//       setStartDate(bannerToEdit.startDate);
//       setEndDate(bannerToEdit.endDate);
//     }
//   }, [isOpen, bannerToEdit]);

//   const handleImageChange = (e, setImageFile, setImageUrl) => {
//     const file = e.target.files[0];
//     if (file) {
//       setImageFile(file);
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setImageUrl(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleSubmit = async () => {
//     if (!enImageUrl || !frImageUrl || !jpImageUrl || !url || !order || !startDate || !endDate) {
//       toast({
//         title: 'Error',
//         description: 'Please fill in all fields.',
//         status: 'error',
//         duration: 3000,
//         isClosable: true,
//       });
//       return;
//     }

//     setSubmitting(true);

//     try {
//       const updatedBanner = {
//         ...bannerToEdit,
//         enImageUrl,
//         frImageUrl,
//         jpImageUrl,
//         url,
//         order,
//         startDate,
//         endDate,
//       };

//       // Simulate API call delay
//       await new Promise((resolve) => setTimeout(resolve, 1000));

//       onSave(updatedBanner);
//       setSubmitting(false);
//       onClose();
//       toast({
//         title: 'Banner Updated',
//         description: 'Your banner has been updated successfully.',
//         status: 'success',
//         duration: 3000,
//         isClosable: true,
//       });
//     } catch (error) {
//       console.error('Error updating banner:', error);
//       toast({
//         title: 'Error',
//         description: 'There was an error updating the banner.',
//         status: 'error',
//         duration: 3000,
//         isClosable: true,
//       });
//       setSubmitting(false);
//     }
//   };

//   return (
//     <Modal isOpen={isOpen} onClose={onClose} size="lg">
//       <ModalOverlay />
//       <ModalContent>
//         <ModalHeader>{bannerToEdit ? 'Edit Banner' : 'Create Banner'}</ModalHeader>
//         <ModalCloseButton />
//         <ModalBody>
//           <Box borderRadius="lg" border="1px" borderColor="gray.200" p={6} boxShadow="lg" bg="white">
//             <VStack spacing={4}>
//               <FormControl id="enImageUrl" isRequired>
//                 <FormLabel>English Image Upload</FormLabel>
//                 <input
//                   type="file"
//                   accept="image/*"
//                   onChange={(e) => handleImageChange(e, setEnImageFile, setEnImageUrl)}
//                   style={{ display: 'none' }}
//                   id="en-file-upload"
//                 />
//                 <Stack direction="row" align="center" spacing={4}>
//                   {enImageUrl ? (
//                     <Image
//                       src={enImageUrl}
//                       alt="English Image Preview"
//                       borderRadius="lg"
//                       boxShadow="md"
//                       boxSize="150px"
//                     />
//                   ) : (
//                     <Box
//                       w="150px"
//                       h="100px"
//                       bg="gray.100"
//                       borderRadius="lg"
//                       display="flex"
//                       alignItems="center"
//                       justifyContent="center"
//                     >
//                       <Box as={AddIcon} color="gray.400" fontSize="3xl" />
//                     </Box>
//                   )}
//                   <IconButton
//                     icon={<AddIcon />}
//                     onClick={() => document.getElementById('en-file-upload').click()}
//                     colorScheme="blue"
//                     variant="outline"
//                     aria-label="Upload English Image"
//                     borderRadius="full"
//                   />
//                 </Stack>
//               </FormControl>
//               <FormControl id="frImageUrl" isRequired>
//                 <FormLabel>French Image Upload</FormLabel>
//                 <input
//                   type="file"
//                   accept="image/*"
//                   onChange={(e) => handleImageChange(e, setFrImageFile, setFrImageUrl)}
//                   style={{ display: 'none' }}
//                   id="fr-file-upload"
//                 />
//                 <Stack direction="row" align="center" spacing={4}>
//                   {frImageUrl ? (
//                     <Image
//                       src={frImageUrl}
//                       alt="French Image Preview"
//                       borderRadius="lg"
//                       boxShadow="md"
//                       boxSize="150px"
//                     />
//                   ) : (
//                     <Box
//                       w="150px"
//                       h="100px"
//                       bg="gray.100"
//                       borderRadius="lg"
//                       display="flex"
//                       alignItems="center"
//                       justifyContent="center"
//                     >
//                       <Box as={AddIcon} color="gray.400" fontSize="3xl" />
//                     </Box>
//                   )}
//                   <IconButton
//                     icon={<AddIcon />}
//                     onClick={() => document.getElementById('fr-file-upload').click()}
//                     colorScheme="blue"
//                     variant="outline"
//                     aria-label="Upload French Image"
//                     borderRadius="full"
//                   />
//                 </Stack>
//               </FormControl>
//               <FormControl id="jpImageUrl" isRequired>
//                 <FormLabel>Japanese Image Upload</FormLabel>
//                 <input
//                   type="file"
//                   accept="image/*"
//                   onChange={(e) => handleImageChange(e, setJpImageFile, setJpImageUrl)}
//                   style={{ display: 'none' }}
//                   id="jp-file-upload"
//                 />
//                 <Stack direction="row" align="center" spacing={4}>
//                   {jpImageUrl ? (
//                     <Image
//                       src={jpImageUrl}
//                       alt="Japanese Image Preview"
//                       borderRadius="lg"
//                       boxShadow="md"
//                       boxSize="150px"
//                     />
//                   ) : (
//                     <Box
//                       w="150px"
//                       h="100px"
//                       bg="gray.100"
//                       borderRadius="lg"
//                       display="flex"
//                       alignItems="center"
//                       justifyContent="center"
//                     >
//                       <Box as={AddIcon} color="gray.400" fontSize="3xl" />
//                     </Box>
//                   )}
//                   <IconButton
//                     icon={<AddIcon />}
//                     onClick={() => document.getElementById('jp-file-upload').click()}
//                     colorScheme="blue"
//                     variant="outline"
//                     aria-label="Upload Japanese Image"
//                     borderRadius="full"
//                   />
//                 </Stack>
//               </FormControl>
//               <FormControl id="url" isRequired>
//                 <FormLabel>URL</FormLabel>
//                 <Input type="url" value={url} onChange={(e) => setUrl(e.target.value)} />
//               </FormControl>
//               <FormControl id="order" isRequired>
//                 <FormLabel>Order</FormLabel>
//                 <Input type="number" value={order} onChange={(e) => setOrder(e.target.value)} />
//               </FormControl>
//               <FormControl id="startDate" isRequired>
//                 <FormLabel>Start Date</FormLabel>
//                 <Input
//                   type="datetime-local"
//                   value={startDate}
//                   onChange={(e) => setStartDate(e.target.value)}
//                 />
//               </FormControl>
//               <FormControl id="endDate" isRequired>
//                 <FormLabel>End Date</FormLabel>
//                 <Input
//                   type="datetime-local"
//                   value={endDate}
//                   onChange={(e) => setEndDate(e.target.value)}
//                 />
//               </FormControl>
//             </VStack>
//           </Box>
//         </ModalBody>
//         <ModalFooter>
//           <Button
//             colorScheme="blue"
//             mr={3}
//             onClick={handleSubmit}
//             isLoading={submitting}
//             isDisabled={
//               !enImageUrl || !frImageUrl || !jpImageUrl || !url || !order || !startDate || !endDate
//             }
//           >
//             {bannerToEdit ? 'Update Banner' : 'Create Banner'}
//           </Button>
//           <Button variant="outline" onClick={onClose}>
//             Cancel
//           </Button>
//         </ModalFooter>
//       </ModalContent>
//     </Modal>
//   );
// };

// export default UpdateBannerCard;

import BannerService from "../../../../axios/service/BannerService"
// import ImageUploadButton from './ImageUploadButton';
// import { supabase } from '../supabaseClient';

// const ImageUploadButton = ({ onChange }) => {
//   const handleFileChange = (e) => {
//     onChange(e);
//   };

//   return (
//     <>
//       <input
//         type="file"
//         accept="image/*"
//         style={{ display: 'none' }}
//         id="upload-image"
//         onChange={handleFileChange}
//       />
//       <label htmlFor="upload-image">
//         <IconButton
//           icon={<AddIcon />}
//           as="span"
//           aria-label="Upload image"
//           borderRadius="md"
//           size="sm"
//           bg="gray.200"
//           ml={2} // Adjust margin as needed
//         />
//       </label>
//     </>
//   );
// };

// const UpdateBannerCard = ({ isOpen, onClose, bannerToEdit, onSubmit }) => {
//   const [selectedImage, setSelectedImage] = useState(null); // State to track selected image for preview
//   const [images, setImages] = useState({
//     en: '',
//     hant: '',
//     hans: '',
//   });
//   const [start_date, setStart_date] = useState(null);
//   const [end_date, setEnd_date] = useState(null);
//   const [loading, setLoading] = useState(false); // Loading state
//   const [url, setUrl] = useState('');
//   const [order, setOrder] = useState('');
//   const toast = useToast();

//   useEffect(() => {
//     if (bannerToEdit) {
//       // const body = {}
//       // for (const key in bannerToEdit.translations) {
//       //   if (key === 'en') {
//       //     body
          
//       //   }
//       // }
//       console.log(bannerToEdit.translations.en)
//       console.log(bannerToEdit.translations)
//       setStart_date(bannerToEdit.start_date ? new Date(bannerToEdit.start_date) : null);
//       setEnd_date(bannerToEdit.end_date ? new Date(bannerToEdit.end_date) : null);
//       // setImages({
//       //   en: bannerToEdit.translations?.en || '',
//       //   hant: bannerToEdit.translations?.en || '',
//       //   hans: bannerToEdit.translations?.en || '',
//       // });
//       setImages({
//         en: '',
//         hant: '',
//         hans: '',
//       });
//       // setImages({
//       //   en: bannerToEdit.translations?.en || '',
//       //   hant: bannerToEdit.translations?.en || '',
//       //   hans: bannerToEdit.translations?.en || '',
//       // });
//       setUrl(bannerToEdit.url || '');
//       setOrder(bannerToEdit.order || '');
//     } else {
//       setStart_date(null);
//       setEnd_date(null);
//       setImages({
//         en: '',
//         hant: '',
//         hans: '',
//       });
//       setUrl('');
//       setOrder('');
//     }
//   }, [bannerToEdit]);

//   const handleImageClick = (imageUrl) => {
//     setSelectedImage(imageUrl);
//   };

//   const closeImagePreview = () => {
//     setSelectedImage(null);
//   };

//   const handleImageChange = (event, lang) => {
//     const file = event.target.files[0];
//     if (file) {
//       // Assuming you have a function to upload the image to Supabase and get the URL
//       const uploadImage = async (file) => {
 
//         const { data, error } = await BannerService.uploadImage(file); // Upload image to Supabase Storage
//         if (data.publicUrl) {
//           // setImages((prev) => ({ ...prev, [lang]: data.publicUrl }));
//           setImages((prevImages) => ({ ...prevImages, [lang]: data.publicUrl }));
//         }else {
//           setLoading(false);
//           console.error('Error uploading image:', error.message);
//         }
//       };

//       uploadImage(file);
//     }
//   };

//   const handleSubmit = async () => {
//     if (!url || !order || !start_date || !end_date) {
//       toast({
//         title: 'Validation Error',
//         description: 'Start date, end date, URL, and order fields cannot be empty.',
//         status: 'error',
//         duration: 3000,
//         isClosable: true,
//       });
//       return;
//     }

//     const data = {
//       start_date,
//       end_date,
//       images,
//       url,
//       order,
//     };

//     setLoading(true);
//     try {
//       if (bannerToEdit) {
//         // Edit banner
//         const updatedData = { ...bannerToEdit, ...data };
//         const { data: response, error } = await supabase.rpc('update_banner', updatedData);
//         if (error) throw error;
//       } else {
//         // Add new banner
//         const { data: response, error } = await supabase.rpc('add_banner', data);
//         if (error) throw error;
//       }
//       handleClose(); // Close modal or handle navigation
//       onSubmit(); // Call onSubmit callback to refresh the list or perform any action
//     } catch (error) {
//       toast({
//         title: 'Submission Error',
//         description: error.message,
//         status: 'error',
//         duration: 3000,
//         isClosable: true,
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleClose = () => {
//     setSelectedImage(null);
//     setImages({
//       en: '',
//       hant: '',
//       hans: '',
//     });
//     setStart_date(null);
//     setEnd_date(null);
//     setUrl('');
//     setOrder('');
//     onClose();
//   };

//   const languages = [
//     { code: 'en', label: 'English' },
//     { code: 'hant', label: 'Hant' },
//     { code: 'hans', label: 'Hans' },
//   ];

//   return (
//     <>
//       <Modal isOpen={isOpen} onClose={handleClose} size="xl">
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>{bannerToEdit ? 'Edit Banner' : 'Add Banner'}</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             <Box
//               bg="white"
//               borderRadius="xl"
//               border="1px"
//               borderColor="gray.200"
//               p={8}
//               boxShadow="xl"
//               position="relative"
//             >
//               <VStack spacing={6} alignItems="flex-start">
//                 {languages.map(({ code, label }) => (
//                   <Box key={code} w="100%">
//                     <Text fontSize="lg"  mb={2}>
//                       {label} Image:
//                     </Text>
//                     <HStack>
//                       <Box
//                         w="200px"
//                         h="100px"
//                         bg="gray.200"
//                         borderRadius="md"
//                         display="flex"
//                         alignItems="center"
//                         justifyContent="center"
//                         overflow="hidden"
//                         border="1px solid gray"
//                       >
//                         {images[code] ? (
//                           <img
//                             src={images[code]}
//                             alt={`${label} preview`}
//                             style={{ objectFit: 'cover', width: '100%', height: '100%' }}
//                             onClick={() => handleImageClick(images[code])}
//                           />
//                         ) : (
//                           <Text color="gray.500">No Image</Text>
//                         )}
//                       </Box>
//                       <ImageUploadButton onChange={(e) => handleImageChange(e, code)} />
//                     </HStack>
//                   </Box>
//                 ))}
//                 <VStack spacing={4} alignItems="flex-start" w="100%">
//                   <Box w="100%">
//                     <Text fontSize="lg" >URL:</Text>
//                     <HStack justifyContent="space-between">
//                       <Input
//                         value={url}
//                         onChange={(e) => setUrl(e.target.value)}
//                         placeholder="Enter URL"
//                       />
//                       <IconButton
//                         icon={<ExternalLinkIcon />}
//                         onClick={() => window.open(url, '_blank')}
//                         aria-label="Open URL"
//                         isDisabled={!url}
//                       />
//                     </HStack>
//                   </Box>
//                   <Divider />
//                   <Box w="100%">
//                     <Text fontSize="lg" >Order:</Text>
//                     <Input
//                       value={order}
//                       onChange={(e) => setOrder(e.target.value)}
//                       placeholder="Enter order"
//                     />
//                   </Box>
//                   <Divider />
//                   <Box w="100%">
//                     <Text fontSize="lg" >Start Date:</Text>
//                     <HStack>
//                       <DatePicker
//                         selected={start_date}
//                         onChange={(date) => setStart_date(date)}
//                         dateFormat="yyyy/MM/dd"
//                         className="chakra-input"
//                         isClearable
//                       />
//                       <IconButton
//                         icon={<CalendarIcon />}
//                         onClick={() => document.querySelector('.chakra-input').focus()}
//                         aria-label="Select start date"
//                       />
//                     </HStack>
//                   </Box>
//                   <Divider />
//                   <Box w="100%">
//                     <Text fontSize="lg" >End Date:</Text>
//                     <HStack>
//                       <DatePicker
//                         selected={end_date}
//                         onChange={(date) => setEnd_date(date)}
//                         dateFormat="yyyy/MM/dd"
//                         className="chakra-input"
//                         isClearable
//                       />
//                       <IconButton
//                         icon={<CalendarIcon />}
//                         onClick={() => document.querySelectorAll('.chakra-input')[1].focus()}
//                         aria-label="Select end date"
//                       />
//                     </HStack>
//                   </Box>
//                 </VStack>
//               </VStack>
//               {loading && (
//                 <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
//                   <Spinner />
//                 </Box>
//               )}
//             </Box>
//           </ModalBody>
//           <ModalFooter>
//             <Button colorScheme="blue" mr={3} onClick={handleSubmit} isLoading={loading}>
//               {bannerToEdit ? 'Update' : 'Add'}
//             </Button>
//             <Button colorScheme="gray" onClick={handleClose} isDisabled={loading}>
//               Cancel
//             </Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>

//       {/* Use the FullImagePreview component */}
//       {selectedImage && (
//         <FullImagePreview
//           isOpen={!!selectedImage}
//           onClose={closeImagePreview}
//           imageUrl={selectedImage}
//         />
//       )}
//     </>
//   );
// };

// export default UpdateBannerCard;

import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  VStack,
  Divider,
  Button,
  Link,
  IconButton,
  HStack,
  Spinner,
  Input,
  useToast,
} from '@chakra-ui/react';
import { ExternalLinkIcon, CalendarIcon, AddIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FullImagePreview from './FullImagePreview';

const ImageUploadButton = ({ onChange, id }) => {
  const handleFileChange = (e) => {
    onChange(e);
  };

  return (
    <>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        id={id}
        onChange={handleFileChange}
      />
      <label htmlFor={id}>
        <IconButton
          icon={<AddIcon />}
          as="span"
          aria-label="Upload image"
          borderRadius="md"
          size="sm"
          bg="gray.200"
          ml={2} // Adjust margin as needed
        />
      </label>
    </>
  );
};

const UpdateBannerDialog = ({ isOpen, onClose, bannerToEdit, onSubmit, bannerType }) => {
  const [selectedImage, setSelectedImage] = useState(null); // State to track selected image for preview
  const [images, setImages] = useState({});
  const [start_date, setStart_date] = useState(null);
  const [end_date, setEnd_date] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [url, setUrl] = useState('');
  const [order, setOrder] = useState('');
  const toast = useToast();

  const languages = [
    { code: 'en', label: 'English' },
    // { code: 'fr', label: 'French' },
    // { code: 'jp', label: 'Japanese' },
    // { code: 'es', label: 'Spanish' },
    // { code: 'de', label: 'German' },
    { code: 'zh-Hant', label: 'Traditional Chinese' },
    { code: 'zh-Hans', label: 'Simplified Chinese' },
  ];

  useEffect(() => {
    if (bannerToEdit) {
      console.log('bannerToEdit',bannerToEdit)
      setStart_date(bannerToEdit.start_date ? new Date(bannerToEdit.start_date) : null);
      setEnd_date(bannerToEdit.end_date ? new Date(bannerToEdit.end_date) : null);
      const initialImages = languages.reduce((acc, { code }) => {
        if (bannerToEdit.translations && bannerToEdit.translations[code]?.image_url) {
          acc[code] = bannerToEdit.translations[code]?.image_url;
        }
        return acc;
      }, {});
      setImages(initialImages);
      setUrl(bannerToEdit.url || '');
      setOrder(bannerToEdit.order || '');
    } else {
      setStart_date(null);
      setEnd_date(null);
      setImages({});
      setUrl('');
      setOrder('');
    }
    setLoading(false)
  }, [bannerToEdit]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImagePreview = () => {
    setSelectedImage(null);
  };

  const handleImageChange = async (event, lang) => {
    const file = event.target.files[0];
    if (file) {
      // Assuming you have a function to upload the image to Supabase and get the URL
      // Upload image to Supabase Storage
      const { data, error } = (bannerType === 'home_banner') ? await BannerService.uploadHomeBannerImage(file) : await BannerService.uploadMainBannerImage(file); 

      if (error) {
        setLoading(false);
        alert('Image Upload Error');
        return;
      }
      if (data.publicUrl) {
        setImages((prevImages) => ({ ...prevImages, [lang]: data.publicUrl }));
      }
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!start_date || !end_date) {
      alert('Validation Error \nStart date, end date fields cannot be empty.');
      return;
    }

    const translations = languages.reduce((acc, { code }) => {
      acc[code] = { image_url: images[code] };
      return acc;
    }, {});

    if (bannerToEdit) {

    } else {
      if (images.length != languages.length) {
        alert('Please upload all images.');
        return;
      }
    }


    const data = {
      start_date: start_date.toISOString(),
      end_date: end_date.toISOString(),
      translations,
      url,
      order,
    };

    setLoading(true);
    try {
      if (bannerToEdit) {
        // Edit banner
        const updatedData = { ...bannerToEdit, ...data };
        const { data: response, error} = (bannerType === 'home_banner') ? await BannerService.updateHomeBanners(updatedData) : await BannerService.updateMainBanners(updatedData);
        if (error) throw error;
      } else {
        // Add new banner
        const { data: response, error} = (bannerType === 'home_banner') ? await BannerService.createHomeBanners(data) : await BannerService.createMainBanners(data);
        if (error) throw error;
      }
      handleClose(); // Close modal or handle navigation
      onSubmit(); // Call onSubmit callback to refresh the list or perform any action
    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSelectedImage(null);
    setImages({});
    setStart_date(null);
    setEnd_date(null);
    setUrl('');
    setOrder('');
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{bannerToEdit ? 'Edit Banner' : 'Add Banner'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading && (
              <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                <Spinner />
              </Box>
            )}
            <Box
              bg="white"
              borderRadius="xl"
              border="1px"
              borderColor="gray.200"
              p={8}
              boxShadow="xl"
              position="relative"
            >
              <VStack spacing={6} alignItems="flex-start">
                {languages.map(({ code, label }) => (
                  <Box key={code} w="100%">
                    <Text fontSize="lg"  mb={2}>
                      {label} Image:
                    </Text>
                    <HStack>
                      <Box
                        w="200px"
                        h="100px"
                        bg="gray.200"
                        borderRadius="md"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        overflow="hidden"
                        border="1px solid gray"
                      >
                        {images[code] ? (
                          <img
                            src={images[code]}
                            alt={`${label} preview`}
                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                            onClick={() => handleImageClick(images[code])}
                          />
                        ) : (
                          <Text color="gray.500">No Image</Text>
                        )}
                      </Box>
                      <ImageUploadButton id={`upload-${code}`} onChange={(e) => handleImageChange(e, code)} />
                    </HStack>
                  </Box>
                ))}
                <VStack spacing={4} alignItems="flex-start" w="100%">
                  {/* <Box w="100%">
                    <Text fontSize="lg" >URL:</Text>
                    <HStack justifyContent="space-between">
                      <Input
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder="Enter URL"
                      />
                      <IconButton
                        icon={<ExternalLinkIcon />}
                        onClick={() => window.open(url, '_blank')}
                        aria-label="Open URL"
                        isDisabled={!url}
                      />
                    </HStack>
                  </Box>
                  <Divider />
                  <Box w="100%">
                    <Text fontSize="lg" >Order:</Text>
                    <Input
                      value={order}
                      onChange={(e) => setOrder(e.target.value)}
                      placeholder="Enter order"
                    />
                  </Box>
                  <Divider /> */}
                  <Box w="100%">
                    <Text fontSize="lg" >Start Date:</Text>
                    <HStack>
                      <DatePicker
                        selected={start_date}
                        onChange={(date) => setStart_date(date)}
                        dateFormat="yyyy/MM/dd"
                        className="chakra-input"
                        isClearable
                      />
                      <IconButton
                        icon={<CalendarIcon />}
                        onClick={() => document.querySelector('.chakra-input').focus()}
                        aria-label="Select start date"
                      />
                    </HStack>
                  </Box>
                  <Divider />
                  <Box w="100%">
                    <Text fontSize="lg" >End Date:</Text>
                    <HStack>
                      <DatePicker
                        selected={end_date}
                        onChange={(date) => setEnd_date(date)}
                        dateFormat="yyyy/MM/dd"
                        className="chakra-input"
                        isClearable
                      />
                      <IconButton
                        icon={<CalendarIcon />}
                        onClick={() => document.querySelectorAll('.chakra-input')[1].focus()}
                        aria-label="Select end date"
                      />
                    </HStack>
                  </Box>
                </VStack>
              </VStack>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose} mr={3}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSubmit} isLoading={loading}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {selectedImage && (
        <FullImagePreview
          isOpen={!!selectedImage}
          onClose={closeImagePreview}
          imageUrl={selectedImage}
        />
      )}
    </>
  );
};

export default UpdateBannerDialog;
