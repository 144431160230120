// import React from 'react';
// import { Box, Text, Flex, Button, Image } from '@chakra-ui/react';

// const NewsCard = ({ item, onEdit, onDelete, language }) => {
//     // [
//     //     {
//     //         "id": 2,
//     //         "url": null,
//     //         "title": "title-tc",
//     //         "news_id": 1,
//     //         "language": "zh-Hant",
//     //         "image_url": null,
//     //         "created_at": "2024-04-22T07:37:45.195513+00:00",
//     //         "updated_at": "2024-04-22T07:37:45.195513+00:00",
//     //         "description": null
//     //     },
//     //     {
//     //         "id": 3,
//     //         "url": null,
//     //         "title": "title-sc",
//     //         "news_id": 1,
//     //         "language": "zh-Hans",
//     //         "image_url": null,
//     //         "created_at": "2024-04-22T07:37:58.253859+00:00",
//     //         "updated_at": "2024-04-22T07:37:58.253859+00:00",
//     //         "description": null
//     //     }
//     // ]

//     console.log('item',item.latest_news_translations,language)
//   const translation = item.latest_news_translations?.find((trans) => trans.language === language) || '';

//   return (
//     <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
//       <Image src={translation?.image_url} alt="News Image" mt={2} />
//       <Text fontWeight="bold">{translation?.title}</Text>
//       <Text>{translation?.description}</Text>
//       <Text>{translation?.language}</Text>
//       <Text>{item?.date}</Text>
//       <Text>{item?.category}</Text>
//       <Flex mt={2}>
//         <Button size="sm" onClick={onEdit} mr={2}>
//           Edit
//         </Button>
//         <Button size="sm" onClick={onDelete} colorScheme="red">
//           Delete
//         </Button>
//       </Flex>
//     </Box>
//   );
// };

// export default NewsCard;


import React from 'react';
import { Box, Text, Image, VStack, HStack, Button, IconButton, Badge } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

const NewsCard = ({ item, onEdit, onDelete, language }) => {

  const translation = item.latest_news_translations?.find((trans) => trans.language === language) || '';

  console.log('translation',translation);
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
      boxShadow="md"
      minWidth="200px"
      maxWidth="300px"
    >
      <Image src={translation?.image_url} alt={item?.title} boxSize="200px" objectFit="cover" mb={4} />
      <VStack align="start" spacing={2} wrap="wrap">
        <Text fontSize="xl" fontWeight="bold" noOfLines={2}>{item?.title}</Text>
        <Text fontSize="md" noOfLines={3}>{item?.description}</Text>
        <VStack wrap="wrap" align="start">
          <Badge colorScheme="blue">Language: {translation?.language}</Badge>
        </VStack>
        <Text fontSize="sm">Url: {translation?.url}</Text>
        <Text fontSize="sm">Create Date: {new Date(translation?.created_at).toLocaleDateString()}</Text>
        <Text fontSize="sm">Update Date: {new Date(translation?.updated_at).toLocaleDateString()}</Text>
      </VStack>
      <HStack spacing={2} mt={4}>
          <IconButton icon={<EditIcon />} aria-label="Edit Coupon" onClick={() => onEdit(item)} />
          <IconButton icon={<DeleteIcon />} aria-label="Delete Coupon" onClick={() => onDelete(item)} />
      </HStack>
    </Box>
  )
};

export default NewsCard;

