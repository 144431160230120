import React, { useState, useEffect } from 'react';
import {
  Flex,
  Stack,
  Heading,
  Text,
  Input,
  Button,
  Box,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  useMediaQuery,
  Image
} from '@chakra-ui/react';
import { supabase } from '../../api/supabase';
import { FunctionsHttpError, FunctionsRelayError, FunctionsFetchError } from '@supabase/supabase-js';
import Icon_1 from './image/Icon_1.png'; // Assuming you've moved the icon to a separate file
import GradientSubmitButton from './GradientSubmitButton';
import ErrorPopup from './ErrorPopup';

const translations = {
  // title: {
  //   'en': "Subscribe",
  //   'zh-Hant': "Suscribir",
  // },
  description: {
    'en': "Please provide your email address, and we will send you the download link for your customized app. Thank you for your interest in our services.",
    'zh-Hant': "請輸入您的電子郵件地址，我們將向您發送自訂應用程式的下載連結。感謝您對我們服務的興趣。",
  },
  emailPlaceholder: {
    'en': "Email Address",
    'zh-Hant': "電郵地址​​",
  },
  buttonText: {
    'en': "Build My App Now",
    'zh-Hant': "立即建立我的應用程式​",
  },
  // subscribedText: {
  //   'en': "Build My App Now",
  //   'zh-Hant': "Suscribir",
  // },
};

export default function SubscriptionPopup({ isOpen, onClose, transformedData, onSuccess, currentLang, supportedLanguages }) {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('initial');
  const [message, setMessage] = useState('');
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('submitting');
    transformedData.email = email;
    const transformedDataJson = JSON.stringify(transformedData);
    // console.log('transformedDataJson', transformedDataJson)
    const { data, error } = await supabase.functions.invoke('create_preview_settings', { body: transformedDataJson, method: 'POST' });

    let message = '';
    if (error instanceof FunctionsHttpError) {
      const errorContext = await error.context.json();
      message = errorContext.error.message;
    } else if (error instanceof FunctionsRelayError) {
      message = error.message;
    } else if (error instanceof FunctionsFetchError) {
      message = error.message;
    }

    if (error) {
      setStatus('error');
      setMessage(message || 'An error occurred. Please try again.');
      setIsErrorPopupOpen(true);
    } else {
      setStatus('success');
      setMessage('');
      onClose();
      onSuccess();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setStatus('initial');
      setEmail('');
      setIsErrorPopupOpen(false);
    }
  }, [isOpen]);

  const renderMultiLangText = (textKey) => {
    // if (isLargerThan768) {
    //   return (
    //     <VStack align="center" spacing={1}>
    //       {supportedLanguages.map((lang) => (
    //         <Text key={lang}>
    //           {translations[textKey][lang] || `Missing ${lang} translation for ${textKey}`}
    //         </Text>
    //       ))}
    //     </VStack>
    //   );
    // }
    return <Text>{translations[textKey][currentLang]}</Text>;
  };

  const renderMultiLangButtonText = (textKey) => {
    const textObj = translations[textKey];
    if (!textObj) {
      console.error(`No translation found for key: ${textKey}`);
      return null;
    }
    // if (isLargerThan768) {
    //   return supportedLanguages.map(lang => textObj[lang] || ``).join(' / ');
    // }
    return <Text>{textObj[currentLang]}</Text>;
  };

  const getPlaceholder = () => {
    const textObj = translations['emailPlaceholder'];
    if (!textObj) {
      return null;
    }
    // if (isLargerThan768) {
    //   return supportedLanguages.map(lang => textObj[lang] || ``).join(' / ');
    // }
    return textObj[currentLang];
  };

  return (
    <>
    <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay bg="rgba(0, 0, 0, 0.5)" onClick={onClose} />
    <ModalContent
      bg='white'
      color='white'
      mx="auto"
      my="auto"
      borderRadius="md"
      onClick={(e) => e.stopPropagation()}
      w={["90%", "80%", "60%", "40%"]}
      // maxW="500px"
    >
      <ModalBody display="flex" alignItems="center" justifyContent="center">
        <Flex
          direction="column"
          align="center"
          justify="center"
          textAlign="center"
          w="full"
        >
          <Box h={10}></Box>
          <Image src={Icon_1} w={24} h={24} mb={4} />
          <Text fontSize={["md", "lg"]} mb={6} color="black">
            {renderMultiLangText('description')}
          </Text>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Stack spacing={1} width="100%">
              <Input
                type="email"
                placeholder={getPlaceholder()}
                color="gray.800"
                bg="white"
                borderWidth="1px"
                borderRadius={10}
                _focus={{
                  bg: 'white',
                  outline: 'none',
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isRequired
                isDisabled={status === 'submitting' || status === 'success'}
                height="50px"
              />
              <Box width="100%" pt={0}>
                <GradientSubmitButton 
                  borderRadius={10}
                  isLargerThan768={isLargerThan768}
                  isLoading={status === 'submitting'}
                  isDisabled={status === 'submitting' || status === 'success'}
                  width="100%"
                  height="44px"
                >
                  {renderMultiLangButtonText('buttonText')}
                </GradientSubmitButton>
              </Box>
              {/* {status === 'error' && (
                <Text color="red.500">{message}</Text>
              )} */}
              <Box h={10}></Box>
            </Stack>
          </form>
        </Flex>
      </ModalBody>
    </ModalContent>
  </Modal>

  <ErrorPopup
        isOpen={isErrorPopupOpen}
        onClose={() => setIsErrorPopupOpen(false)}
        currentLang={currentLang}
        supportedLanguages={supportedLanguages}
        message={message}
      />
  </>
  );
}