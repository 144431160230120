import React, { useState } from 'react';
import SlidingCard from './SlidingCard';

import BasePageComponent from '../../../components/base/BasePageComponent';

const SlidingPage = () => {
  const tableName = 'left_menu_settings';

  const sortFields = [
    { value: 'name', label: 'Name' },
  ];

  const itemsPerPageOptions = [5, 10, 15];

  return (
    <BasePageComponent
      txt="Sliding Menu"
      itemCardComponent={(props) => <SlidingCard {...props} />}
      sortFields={sortFields}
      itemsPerPageOptions={itemsPerPageOptions}
      maxPaginationButtons={5}
      viewStyle="grid" // Set the initial view style to grid
      tableName={tableName}
      primaryKey='item_type'
      defaultSortKey='sort_order' // Add default sort key
      gridView={true}
      showAddIcon={false}
    />
  );
};

export default SlidingPage;


