import React from 'react';
import { Button } from '@chakra-ui/react';

const GradientSubmitButton = ({ children, isLargerThan768, ...props }) => {
  return (
    <Button
      borderRadius={10}
      type="submit"
      size={isLargerThan768 ? "lg" : "md"}
      height="50px"
      fontSize={["lg", "lg", "lg"]}
      width={["100%", "80%", "60%"]}
      mx="auto"
      mt={[10, 12, 15]}
      bgGradient="linear(169.95deg, #7167E3 16.49%, #5A96E9 84.35%)"
      _hover={{
        bgGradient: "linear(169.95deg, #7167E3 16.49%, #5A96E9 84.35%)"
      }}
      boxShadow="0px 8px 15px 0px #005C5A1F"
      color="white"
      {...props}
    >
      {children}
    </Button>
  );
};

export default GradientSubmitButton;