import React, { useState, useEffect } from 'react';
import { Box, Button, VStack } from '@chakra-ui/react';
import Draggable from 'react-draggable';

const LanguageSelector = ({ currentLang, setCurrentLang, supportedLanguages, isLargerThan768, displayNames }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const fullHeight = document.documentElement.scrollHeight;
      
      setIsAtBottom(scrollPosition + windowHeight < fullHeight - 100);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // if (isLargerThan768) return null;

  const displayButtons = {
    "en": "中",
    "zh-Hant": "EN"
  };

  const handleLanguageToggle = () => {
    const otherLang = supportedLanguages.find(lang => lang !== currentLang);
    setCurrentLang(otherLang);
  };

  return (
    <Box 
      position="fixed" 
    //   bottom={isAtBottom ? "40px" : "auto"}
    //   top={isAtBottom ? "auto" : "40px"}
      top="6px"
      right="20px" 
      zIndex={1000}
      transition="all 0.3s ease"
    >
      <Button 
        onClick={handleLanguageToggle} 
        bg="white" 
        // boxShadow="md" 
        // _hover={{ bg: "gray.100" }}
      >
        {displayButtons[currentLang] || currentLang}
      </Button>
    </Box>
  );
};

export default LanguageSelector;