// BannerCard.jsx
// import React from 'react';
// import { Box, Image, Text, Button, VStack, HStack, IconButton, Flex, Spacer } from '@chakra-ui/react';
// import { EditIcon, ViewIcon, DeleteIcon } from '@chakra-ui/icons';
// import { formatDateString } from './dateUtils';

// const BannerCard = ({ banner, onEdit, onView, onDelete, ...rest }) => {

//   const getFirstImageUrl = () => {
//     const translations = banner.translations;
//     for (const lang in translations) {
//       if (translations[lang]?.image_url) {
//         return translations[lang].image_url;
//       }
//     }
//     return 'https://via.placeholder.com/236x150?text=No+Image'; // Placeholder if no image URL is found
//   };

//   const imageUrl = getFirstImageUrl();

//   return (
//     <Box
//       borderWidth="1px"
//       borderRadius="lg"
//       overflow="hidden"
//       boxShadow="lg"
//       bg="white"
//       p={4}
//       {...rest} // Spread rest props to allow additional styling or props from parent
//     >
//       <VStack spacing={2} align="start">
//         <Box
//           w="full"
//           h="120px"
//           bg="gray.200"
//           // borderRadius="md"
//           overflow="hidden"
//           // display="flex"
//           // alignItems="center"
//           // justifyContent="center"
//         >
//           <Image
//             src={imageUrl}
//             alt="Banner preview"
//             objectFit="contain" // w 232 h 120  Changed to "contain" to ensure the image fits within the container
//             w="full"
//             h="full"
//           />
//         </Box>
//         {/* <Text fontSize="lg" fontWeight="bold" wordBreak="break-word">
//           {banner.url}
//         </Text>
//         <Text>Order: {banner.order}</Text> */}
//         <Text>Start Date: {formatDateString(banner.start_date)}</Text>
//         <Text>End Date: {formatDateString(banner.end_date)}</Text>
//         <Flex w="100%">
//           <HStack spacing={2}>
//             <IconButton icon={<ViewIcon />} aria-label="View" onClick={() => onView(imageUrl)} />
//             <IconButton icon={<EditIcon />} aria-label="Edit" onClick={() => onEdit(banner)} />
//           </HStack>
//           <Spacer />
//           <IconButton icon={<DeleteIcon />} aria-label="Delete" onClick={() => onDelete(banner)} />
//         </Flex>
//       </VStack>
//     </Box>
//   );
// };

// export default BannerCard;



import React from 'react';
import { Box, Text, Image, VStack, HStack, Button, IconButton, Badge, Flex, Spacer } from '@chakra-ui/react';
import { EditIcon, ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import { formatDateString } from './dateUtils';

var recount = 0;

const BannerCard = ({ banner, onEdit, onView, onDelete, ...rest }) => {

  // console.log('recount',recount++)

  const getFirstImageUrl = () => {
    const translations = banner.translations;
    for (const lang in translations) {
      if (translations[lang]?.image_url) {
        return translations[lang].image_url;
      }
    }
    return ''
    // return 'https://via.placeholder.com/236x150?text=No+Image'; // Placeholder if no image URL is found
  };

  const imageUrl = getFirstImageUrl();

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
      boxShadow="md"
      minWidth="200px"
      maxWidth="300px"
    >
      <Box
          w="full"
          h="120px"
          bg="gray.200"
          // borderRadius="md"
          overflow="hidden"
          // display="flex"
          // alignItems="center"
          // justifyContent="center"
        >
          <Image
            src={imageUrl}
            alt=" "
            objectFit="contain" // w 232 h 120  Changed to "contain" to ensure the image fits within the container
            w="full"
            h="full"
          />
        </Box>

      {/* <Image src={imageUrl} alt={banner.id} boxSize="200px,160px" objectFit="cover" mb={4} /> */}
      <VStack align="start" spacing={2} mt='4' mb='3' wrap="wrap">
        <VStack wrap="wrap" align="start">
          <Badge whiteSpace="normal" overflowWrap="break-word" textTransform="none" colorScheme="blue">
            {`ID: ${banner.id}`}
          </Badge>
          <Badge whiteSpace="normal" overflowWrap="break-word" textTransform="none" colorScheme="blue">
            Start Date: {new Date(banner.start_date).toLocaleDateString()}
          </Badge>
          <Badge whiteSpace="normal" overflowWrap="break-word" textTransform="none" colorScheme="blue">
            End Date: {new Date(banner.end_date).toLocaleDateString()}
          </Badge>
        </VStack>
        {/* <Text fontSize="sm">Create Date: {new Date(banner.created_at).toLocaleDateString()}</Text>
        <Text fontSize="sm">Update Date: {new Date(banner.updated_at).toLocaleDateString()}</Text> */}
      </VStack>
      <Flex w="100%">
          <HStack spacing={2}>
            <IconButton icon={<ViewIcon />} aria-label="View" onClick={() => onView(imageUrl)} />
            <IconButton icon={<EditIcon />} aria-label="Edit" onClick={() => onEdit(banner)} />
          </HStack>
          <Spacer />
          <IconButton icon={<DeleteIcon />} aria-label="Delete" onClick={() => onDelete(banner)} />
        </Flex>
    </Box>
  );
};
export default BannerCard;