import React from 'react';
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Flex,
  Box,
  Select,
  Text,
  Tooltip,
  Icon,
  useBreakpointValue,
  Spacer
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

const StatCard = ({
  label,
  value,
  color,
  isEmpty,
  emptyMessage,
  children,
  bgColor,
  borderColor,
  options,
  onOptionChange,
  labelLines = 2,
  tooltip,
  icon: IconComponent,
  trend,
  trendColor,
  onClick,
}) => {
  // Use useBreakpointValue to create responsive values
  const labelFontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'lg' });
  const valueFontSize = useBreakpointValue({ base: '2xl', md: '3xl', lg: '4xl' });
  const helpTextFontSize = useBreakpointValue({ base: 'xs', md: 'sm' });
  const optionFontSize = useBreakpointValue({ base: 'xs', md: 'sm' });
  const padding = useBreakpointValue({ base: 3, md: 4, lg: 5 });
  const iconSize = useBreakpointValue({ base: 4, md: 5, lg: 6 });
  const infoIconSize = useBreakpointValue({ base: 3, md: 4 });
  const selectSize = useBreakpointValue({ base: 'xs', md: 'sm' });

  return (
    <Stat
      bg={bgColor}
      p={padding}
      borderRadius="lg"
      boxShadow="md"
      borderWidth={1}
      borderColor={borderColor}
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'default'}
      transition="all 0.2s"
      _hover={onClick && { transform: 'scale(1.02)' }}
    >
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Flex alignItems="center" flexGrow={1} mr={2}>
          {IconComponent && <Icon as={IconComponent} boxSize={iconSize} mr={2} />}
          <Tooltip label={tooltip} isDisabled={!tooltip}>
            <StatLabel
              fontSize={labelFontSize}
              noOfLines={labelLines}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {label}
            </StatLabel>
          </Tooltip>
          {tooltip && (
            <Tooltip label={tooltip}>
              <InfoIcon ml={1} color="gray.400" boxSize={infoIconSize} />
            </Tooltip>
          )}
        </Flex>
        {options && (
          <Select
            size={selectSize}
            onChange={onOptionChange}
            width="auto"
            maxWidth="40%"
            fontSize={optionFontSize}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      </Flex>
      <StatNumber fontSize={valueFontSize} color={color}>
        {value}
      </StatNumber>
      {/* {trend && (
        <StatHelpText color={trendColor} fontSize={helpTextFontSize}>
          <Text as="span" fontWeight="bold">
            {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}%
          </Text>
          {' since last period'}
        </StatHelpText>
      )} */}
      {isEmpty && (
        <Box bg="gray.100" borderRadius="md" mt={2} p={2}>
          <Text color="gray.500" textAlign="center" fontSize={helpTextFontSize}>
            {emptyMessage}
          </Text>
        </Box>
      )}
    </Stat>
  );
};

export default StatCard;