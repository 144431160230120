import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Grid,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useBreakpointValue,
  RadioGroup,
  Radio,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EditMemberModal = ({ member, isOpen, onClose, onUpdate }) => {

    const [formData, setFormData] = useState({
        date_of_birth: null,
        preferred_language: 'en',
        tier: 'basic'
    });

    // Initialize formData when member changes
    useEffect(() => {
        if (member) {
            setFormData({
                ...member,
                date_of_birth: parseDateOfBirth(member.date_of_birth),
                preferred_language: parseLanguage(member.preferred_language || 'en'),
                tier: (member.tier || 'basic').toLowerCase()
            });
        }
    }, [member]);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  function parseDateOfBirth(dateString) {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  }

    function parseLanguage(languageString) {
        if (!languageString) return null;
        if (languageString === 'Traditional Chinese') {
            return 'zh-Hant'
        } else if (languageString === 'Simplified Chinese') {
            return 'zh-Hans'
        } else {
            return 'en'
        }
    }

  const languages = [
    { label: 'English', code: 'en' },
    { label: 'Traditional Chinese', code: 'zh-Hant' },
    { label: 'Simplified Chinese', code: 'zh-Hans' }
  ];

  const tiers = [
    { label: 'Basic', code: 'basic' },
    { label: 'Silver', code: 'silver' },
    { label: 'Gold', code: 'gold' }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({ ...prev, date_of_birth: date }));
    setErrors((prev) => ({ ...prev, date_of_birth: '' }));
  };

  const handleLanguageChange = (value) => {
    // e.preventDefault();
    setFormData((prev) => ({ ...prev, preferred_language: value }));
  };

  const handleTierChange = (value) => {
    // e.preventDefault();
    setFormData((prev) => ({ ...prev, tier: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = 'First name is required';
    if (!formData.last_name) newErrors.last_name = 'Last name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.mobile_number) newErrors.mobile_number = 'Mobile number is required';
    if (!formData.date_of_birth) newErrors.date_of_birth = 'Date of birth is required';
    if (!formData.tier) newErrors.tier = 'Tier is required';
    if (!formData.preferred_language) newErrors.preferred_language = 'Preferred language is required';
    if (!formData.points) newErrors.points = 'Points is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const { index, ...rest } = formData;

    setIsLoading(true);
    try {
      await global.genericapi.updateRecord('members', member.user_id, rest, 'user_id');
      onUpdate();
      onClose();
    } catch (error) {
      setErrors((prev) => ({ ...prev, submit: error.message }));
    } finally {
      setIsLoading(false);
    }
  };

  const gridTemplateColumns = useBreakpointValue({ base: '1fr', md: 'repeat(2, 1fr)' });
  const modalSize = useBreakpointValue({ base: "sm", md: "2xl", lg: "4xl" });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Member</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box width="100%" p={3}>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="stretch">
                <Grid templateColumns={gridTemplateColumns} gap={4}>
                  <FormControl isInvalid={errors.first_name}>
                    <FormLabel>First Name</FormLabel>
                    <Input name="first_name" value={formData.first_name || ''} onChange={handleInputChange} />
                    {errors.first_name && (
                      <Alert status="error" mt={2} fontSize="sm">
                        <AlertIcon />
                        {errors.first_name}
                      </Alert>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.last_name}>
                    <FormLabel>Last Name</FormLabel>
                    <Input name="last_name" value={formData.last_name || ''} onChange={handleInputChange} />
                    {errors.last_name && (
                      <Alert status="error" mt={2} fontSize="sm">
                        <AlertIcon />
                        {errors.last_name}
                      </Alert>
                    )}
                  </FormControl>
                </Grid>
                <FormControl isInvalid={errors.email}>
                  <FormLabel>Email</FormLabel>
                  <Input name="email" value={formData.email || ''} onChange={handleInputChange} type="email" />
                  {errors.email && (
                    <Alert status="error" mt={2} fontSize="sm">
                      <AlertIcon />
                      {errors.email}
                    </Alert>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.mobile_number}>
                  <FormLabel>Mobile Number</FormLabel>
                  <Input name="mobile_number" value={formData.mobile_number || ''} onChange={handleInputChange} />
                  {errors.mobile_number && (
                    <Alert status="error" mt={2} fontSize="sm">
                      <AlertIcon />
                      {errors.mobile_number}
                    </Alert>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.date_of_birth}>
                  <FormLabel>Date of Birth</FormLabel>
                  <InputGroup onClick={() => document.querySelector('.react-datepicker__input-container input').focus()}>
                    <DatePicker
                      selected={formData.date_of_birth || ''}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      customInput={<Input readOnly />}
                    />
                    <InputRightElement children={<CalendarIcon color="gray.300" />} />
                  </InputGroup>
                  {errors.date_of_birth && (
                    <Alert status="error" mt={2} fontSize="sm">
                      <AlertIcon />
                      {errors.date_of_birth}
                    </Alert>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.tier}>
                  <FormLabel>Tier</FormLabel>
                  <RadioGroup name="tier" value={formData.tier} onChange={handleTierChange}>
                    <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={4}>
                      {tiers.map(({ label, code }) => (
                        <Radio key={code} value={code}>
                          {label}
                        </Radio>
                      ))}
                    </Grid>
                  </RadioGroup>
                  {errors.tier && (
                    <Alert status="error" mt={2} fontSize="sm">
                      <AlertIcon />
                      {errors.tier}
                    </Alert>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.preferred_language}>
                  <FormLabel>Preferred Language</FormLabel>
                  <RadioGroup name="preferred_language" value={formData.preferred_language} onChange={handleLanguageChange}>
                    <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={4}>
                      {languages.map(({ label, code }) => (
                        <Radio key={code} value={code}>
                          {label}
                        </Radio>
                      ))}
                    </Grid>
                  </RadioGroup>
                  {errors.preferred_language && (
                    <Alert status="error" mt={2} fontSize="sm">
                      <AlertIcon />
                      {errors.preferred_language}
                    </Alert>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.points}>
                  <FormLabel>Points</FormLabel>
                  <Input name="points" value={formData.points || ''} onChange={handleInputChange} />
                  {errors.points && (
                    <Alert status="error" mt={2} fontSize="sm">
                      <AlertIcon />
                      {errors.points}
                    </Alert>
                  )}
                </FormControl>
                {errors.submit && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle mr={2}>Error!</AlertTitle>
                    <AlertDescription>{errors.submit}</AlertDescription>
                  </Alert>
                )}
              </VStack>
            </form>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit} isLoading={isLoading} loadingText="Updating">
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditMemberModal;