import React, { useCallback } from 'react';
import { Box, Text, Flex, Button, Spacer, Switch } from '@chakra-ui/react';

const LanguageCard = ({ item, onEdit, onDelete, onToggleActive, language }) => {

  const {
    lang = '',
    display_order = 0,
    is_active = 0,
    created_at = '',
    updated_at = ''
  } = item || {};

  const languages = [
    { code: 'en', label: 'English' },
    // { code: 'fr', label: 'French' },
    // { code: 'jp', label: 'Japanese' },
    // { code: 'es', label: 'Spanish' },
    // { code: 'de', label: 'German' },
    { code: 'zh-Hant', label: 'Traditional Chinese' },
    { code: 'zh-Hans', label: 'Simplified Chinese' },
  ];

  const handleToggleActive = useCallback(() => onToggleActive(item), [onToggleActive, item]);

  // console.log('item',item)
  const translation = languages.find((trans) => trans.code === lang) || '';
  const name = translation.label || '';

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="md" p={4} minW={160} minH={100}>
      <Text fontWeight="bold">{name}</Text>
      <Text>{item.description}</Text>
      <Spacer></Spacer>

      <Flex mt={6} justify="space-between" align="center">
      {/* <IconButton icon={<StarIcon />} onClick={() => onFav(item)} aria-label="Favorite" />
      <IconButton icon={<ViewIcon />} onClick={() => onView(item)} aria-label="View" />
      <IconButton icon={item.enabled ? <CloseIcon /> : <CheckIcon />} onClick={() => onEnableDisable(item)} aria-label="Enable/Disable" />
      <Button onClick={() => onEdit(item)} size="sm">Edit</Button> */}
      {/* <Button onClick={() => onDelete(item)} size="sm" colorScheme="red">Delete</Button> */}
      {/* <Spacer></Spacer> */}
      <Switch isChecked={item.is_active} onChange={handleToggleActive} />
      {/* <IconButton key={item.item_type} icon={item.is_active ? <CloseIcon /> : <CheckIcon />} onClick={() => handleToggleActive(item)} aria-label="Enable/Disable" /> */}
    </Flex>
    </Box>
  );
};

export default LanguageCard;
