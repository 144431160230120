// utils/formatTranslationString.js
export const formatTranslationString = (isoString) => {
  switch (isoString) {
    case 'zh-Hant':
      return 'Traditional Chinese';
    case 'zh-Hans':
      return 'Simplified Chinese';
    default:
      return 'English';
  }
};