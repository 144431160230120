import React, { useState } from 'react';
import IconCard from './IconCard';

import BasePageComponent from '../../../components/base/BasePageComponent';
import AddEditIconModal from './AddEditIconModal'

const IconsPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'icon_settings';

  const keyMappings = {
    icon_url: 'Icon Url',
    created_at: 'Created At',
    updated_at: 'Updated At'
  };

  const tableHeaders = [
    'icon_url',
  ];

  const sortFields = [
    { value: 'name', label: 'Name' },
  ];

  const itemsPerPageOptions = [5, 10, 15];

  return (
    <BasePageComponent
      txt="App Icon"
      itemCardComponent={(props) => <IconCard {...props} />}
      addItemComponent={AddEditIconModal}
      sortFields={sortFields}
      itemsPerPageOptions={itemsPerPageOptions}
      maxPaginationButtons={5}
      viewStyle="grid" // Set the initial view style to grid
      tableName={tableName}
      gridView={true}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} // Pass key mappings to BasePageComponent
      showAddIcon='hideWhenData'
    />
  );
};

export default IconsPage;
