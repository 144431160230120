import { supabase } from "../../api/supabase";

const BannerService= {

    async fetchAllMainBanners () {
        const pendingPromise = supabase.rpc('get_pending_main_banners');
        const activePromise = supabase.rpc('get_main_banners');
        const otherPromise = supabase.rpc('get_submitted_main_banners');
      
        const [pendingBanners, activeBanners, otherBanners] = await Promise.all([pendingPromise, activePromise, otherPromise]);
      
        return {
          active: activeBanners.data || [],
          pending: pendingBanners.data || [],
          other: otherBanners.data || [],
        };
    },

    async getMainBanners () {
        const res = await supabase.rpc('get_main_banners');
        return res
    },

    async getPendingMainBanners () {
        const res = await supabase.rpc('get_pending_main_banners');
        return res
    },

    async getSubmittedMainBanners () {
        const res = await supabase.rpc('get_submitted_main_banners');
        return res
    },

    async deleteMainBanners(id) {
        console.log('props',id)
        const body = {
            'bypass_approval':false,
            'main_banner_id':id
        }
        let res = await supabase
            .rpc('delete_main_banner', body)
        return res
    },

    async createMainBanners(props) {
        console.log('props',props)
        const body = {
            'bypass_approval':false,
            // 'sort_order':props['sort_order'],
            'end_date':props['end_date'],
            'start_date':props['start_date'],
            'translations':props['translations']
        }
        let res = await supabase
            .rpc('create_main_banner', body)
        return res
    },

    async updateMainBanners(props) {
        console.log('props',props)
        const body = {
            'bypass_approval':false,
            'main_banner_id':props['id'],
            // 'sort_order':props['sort_order'],
            'end_date':props['end_date'],
            'start_date':props['start_date'],
            'translations':props['translations']
        }
        let res = await supabase
            .rpc('update_main_banner', body)
        return res
    },

    async uploadMainBannerImage(file) {
        
        const { uploadData, uploadError } = await supabase
            .storage
            .from('main_banner')
            .upload(`${file.name}`, file, {
                upsert: true
            })

        if (uploadError) {
            console.error('Error uploading image:', uploadError.message);
            return { uploadData, uploadError }
        }

        const { data, error } = await supabase.storage.from('main_banner').getPublicUrl(`${file.name}`)
        return { data, error }
    },


    async fetchAllHomeBanners () {
        const pendingPromise = supabase.rpc('get_pending_home_banners');
        const activePromise = supabase.rpc('get_home_banners');
        const otherPromise = supabase.rpc('get_submitted_home_banners');
      
        const [pendingBanners, activeBanners, otherBanners] = await Promise.all([pendingPromise, activePromise, otherPromise]);
      
        return {
          active: activeBanners.data || [],
          pending: pendingBanners.data || [],
          other: otherBanners.data || [],
        };
    },

    async getHomeBanners () {
        const res = await supabase.rpc('get_home_banners');
        return res
    },

    async getPendingHomeBanners () {
        const res = await supabase.rpc('get_pending_home_banners');
        return res
    },

    async getSubmittedMainBanners () {
        const res = await supabase.rpc('get_submitted_home_banners');
        return res
    },

    async deleteHomeBanners(id) {
        console.log('props',id)
        const body = {
            'bypass_approval':false,
            'home_banner_id':id
        }
        let res = await supabase
            .rpc('delete_home_banner', body)
        return res
    },

    async createHomeBanners(props) {
        console.log('props',props)
        const body = {
            'bypass_approval':false,
            // 'sort_order':props['sort_order'],
            'end_date':props['end_date'],
            'start_date':props['start_date'],
            'translations':props['translations']
        }
        let res = await supabase
            .rpc('create_home_banner', body)
        return res
    },

    async updateHomeBanners(props) {
        console.log('props',props)
        const body = {
            'bypass_approval':false,
            'home_banner_id':props['id'],
            // 'sort_order':props['sort_order'],
            'end_date':props['end_date'],
            'start_date':props['start_date'],
            'translations':props['translations']
        }
        let res = await supabase
            .rpc('update_home_banner', body)
        return res
    },

    async uploadHomeBannerImage(file) {
        
        const { uploadData, uploadError } = await supabase
            .storage
            .from('home_banner')
            .upload(`${file.name}`, file, {
                upsert: true
            })

        if (uploadError) {
            console.error('Error uploading image:', uploadError.message);
            return { uploadData, uploadError }
        }

        const { data, error } = await supabase.storage.from('home_banner').getPublicUrl(`${file.name}`)
        return { data, error }
    },

    async fetchTutorials () {
        const res = await supabase.rpc('get_tutorials');
        console.log('res',res)
        return res.data
    },

}

export default BannerService