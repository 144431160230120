import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Image,
  Text,
  IconButton,
  Button,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useMediaQuery,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import SubscriptionPopup from './SubscriptionPopup';
import SuccessPopup from './SuccessPopup';
import LanguageSelector from './LanguageSelector';
import { configData, configData1 } from './constants';
import { translations, translations_languages, displayNames } from './translations';
import { FormControlComponent } from './FormControlComponent';
import GradientSubmitButton from './GradientSubmitButton';

import logoImage from './image/Logo.png';
import logoImageTn from './image/Header_logo_chiense.png';
import logoImageEn from './image/Header_logo_en.png';

import bannerImage from './image/Banner_image.png';

const handleImageUploadBefore = (files, info, uploadHandler) => {
  const file = files[0];
  let response = {}
  global.genericapi.uploadImageAndGetPublicUrl('about_us', file).then((publicUrl) => {
    response = {
      result: [
        {
          url: publicUrl,
          name: file.originalName || 'Imagem',
          size: file.size
        }
      ]
    }
    uploadHandler(response)
  })
}



const createQuestions = (jsonData) => [
  {
    id: 'color_theme_setting',
    title: translations.questions_title.color_theme_setting,
    question: translations.questions.color_theme_setting,
    options: configData.settings.color_theme_setting,
    value: jsonData.settings.color_theme_setting,
  },
  {
    id: 'language_and_menu_settings',
    title: translations.questions_title.language_and_menu_settings,
    question: {
      supported_languages: translations.questions.supported_languages,
      left_menu_settings: translations.questions.left_menu_settings,
    },
    options: {
      supported_languages: configData.settings.supported_languages,
      left_menu_settings: configData.settings.left_menu_settings.map((item) => item.item_type),
    },
    value: {
      supported_languages: jsonData.settings.supported_languages,
      left_menu_settings: jsonData.settings.left_menu_settings.map((item) => item.item_type),
    },
  },
  {
    id: 'about_us',
    title: translations.questions_title.about_us,
    question: translations.questions.about_us,
    value: jsonData.settings.about_us,
  }
];




const transformFormData = (formData, jsonData) => {
  const transformedData = { ...jsonData };
  transformedData.email = formData.email;
  transformedData.settings.color_theme_setting = formData.color_theme_setting;
  transformedData.settings.left_menu_settings = formData.left_menu_settings.map((itemType) => ({
    item_type: itemType,
  }));
  transformedData.settings.supported_languages = formData.supported_languages;
  transformedData.settings.about_us = formData.about_us;
  return transformedData;
};

const QuestionnairePage = () => {
  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState({});
  const [submitedData, setSubmitedData] = useState({});
  const [errors, setErrors] = useState({});
  const [jsonData, setJsonData] = useState(configData);
  const [supportedLanguages, setSupportedLanguages] = useState(configData.settings.supported_languages);
  const [error, setError] = useState(null);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [isSubscriptionOpen, setIsSubscriptionOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState('en');

  const openSubscriptionPopup = () => setIsSubscriptionOpen(true);
  const closeSubscriptionPopup = () => setIsSubscriptionOpen(false);
  const openSuccessPopup = () => setIsSuccessPopupOpen(true);


  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isLargerThan1024] = useMediaQuery("(min-width: 1024px)");

  const closeSuccessPopup = () => {
    setIsSuccessPopupOpen(false);
    fetchPreviousData();
  };

  const fetchPreviousData = async () => {
    // const { data1, error } = await supabase.rpc('fetch_questionnaire_data'); // Update with your RPC function name

    const data = configData1;
    const initialFormData = { ...formData };

    // Update the form data with previous data
    initialFormData.email = data.email || '';
    initialFormData.color_theme_setting = configData.settings.color_theme_setting.includes(data.settings.color_theme_setting) ? data.settings.color_theme_setting : configData.settings.color_theme_setting[0];
    initialFormData.supported_languages = configData.settings.supported_languages.filter(lang =>
      data.settings.supported_languages.includes(lang)
    );
    if (initialFormData.supported_languages.length === 0) {
      initialFormData.supported_languages = configData.settings.supported_languages;
    }
    initialFormData.left_menu_settings = configData.settings.left_menu_settings.filter(item =>
      data.settings.left_menu_settings.some(prevItem => prevItem.item_type === item.item_type)
    ).map(item => item.item_type);
    if (initialFormData.left_menu_settings.length === 0) {
      initialFormData.left_menu_settings = configData.settings.left_menu_settings.map(item => item.item_type);
    }
    // initialFormData.bottom_navigation_settings = configData.settings.bottom_navigation_settings.filter(item =>
    //   data.settings.bottom_navigation_settings.some(prevItem => prevItem.item_type === item.item_type)
    // ).map(item => item.item_type);
    // if (initialFormData.bottom_navigation_settings.length === 0) {
    //   initialFormData.bottom_navigation_settings = configData.settings.bottom_navigation_settings.map(item => item.item_type);
    // }

    initialFormData.language_and_menu_settings = {
      supported_languages: data.settings.supported_languages || [],
      left_menu_settings: data.settings.left_menu_settings.map(item => item.item_type) || []
    };
    initialFormData.about_us = data.settings.about_us;

    setFormData(initialFormData);
  };

  useEffect(() => {

    fetchPreviousData();

    const initialQuestions = createQuestions(configData);
    setQuestions(initialQuestions);

  }, []);

  // const handleChange = (questionId, value) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     [questionId]: value,
  //   }));

  //   if (questionId === 'supported_languages') {
  //     setSupportedLanguages(value);
  //   }

  //   if (value) {
  //     setErrors((prev) => ({
  //       ...prev,
  //       [questionId]: '',
  //     }));
  //   }
  // };

  // const validateForm = () => {
  //   const newErrors = {};
  //   questions.forEach((question) => {
  //     switch (question.id) {
  //       case 'color_theme_setting':
  //         if (!formData[question.id]) {
  //           newErrors[question.id] = 'Please select a color theme setting';
  //         }
  //         break;
  //       case 'supported_languages':
  //         if (!formData[question.id]?.length) {
  //           newErrors[question.id] = 'Please select at least one supported language';
  //         }
  //         break;
  //       case 'left_menu_settings':
  //         if (!formData[question.id]?.length) {
  //           newErrors[question.id] = 'Please select at least one left menu item';
  //         }
  //         break;
  //       // case 'bottom_navigation_settings':
  //       //   if (!formData[question.id]?.length) {
  //       //     newErrors[question.id] = 'Please select at least one bottom navigation item';
  //       //   }
  //       //   break;
  //       case 'about_us':
  //         if (!formData[question.id]) {
  //           newErrors[question.id] = 'Please enter content for about us';
  //         }
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };

  const handleChange = (questionId, value) => {
    setFormData((prev) => {
      if (questionId === 'language_and_menu_settings') {
        return {
          ...prev,
          [questionId]: {
            ...prev[questionId],
            ...value,
          },
        };
      }
      return {
        ...prev,
        [questionId]: value,
      };
    });

    if (questionId === 'language_and_menu_settings' && value.supported_languages) {
      setSupportedLanguages(value.supported_languages);
    }

    setErrors((prev) => ({
      ...prev,
      [questionId]: '',
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    questions.forEach((question) => {
      switch (question.id) {
        case 'color_theme_setting':
          if (!formData[question.id]) {
            newErrors[question.id] = 'Please select a color theme setting';
          }
          break;
        case 'language_and_menu_settings':
          if (!formData[question.id]?.supported_languages?.length) {
            newErrors[question.id] = 'Please select at least one supported language';
          }
          if (!formData[question.id]?.left_menu_settings?.length) {
            newErrors[question.id] = (newErrors[question.id] || '') + ' Please select at least one left menu item';
          }
          break;
        case 'about_us':
          if (!formData[question.id]) {
            newErrors[question.id] = 'Please enter content for about us';
          }
          break;
        default:
          break;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (validateForm()) {
      setError(null);
      const submitedData = transformFormData(formData, jsonData);
      setSubmitedData(submitedData);
      console.log('Submited Data:', submitedData);
      openSubscriptionPopup();
    } else {
      setError('Form validation failed. Please fill in all required fields.');
    }
  };

  const renderMultiLangText = (textKey) => {
    const textObj = translations[textKey];
    if (!textObj) {
      console.error(`No translation found for key: ${textKey}`);
      return null;
    }
    // if (isLargerThan768) {
    //   return (
    //     <VStack align="center" spacing={1}>
    //       {translations_languages.map((lang) => (
    //         <Text key={lang}>
    //           {textObj[lang] || ``}
    //         </Text>
    //       ))}
    //     </VStack>
    //   );
    // }
    return <Text fontSize='32px'>{textObj[currentLang]}</Text>;
  };

  const renderMultiLangButtonText = (textKey) => {
    const textObj = translations[textKey];
    if (!textObj) {
      console.error(`No translation found for key: ${textKey}`);
      return null;
    }
    // if (isLargerThan768) {
    //   return translations_languages.map(lang => textObj[lang] || ``).join(' / ');
    // }
    return <Text>{textObj[currentLang]}</Text>;
  };

  const renderPageTitle = () => {
    return (
      <Box
        bgGradient="linear(169.95deg, #7167E3 16.49%, #5A96E9 84.35%)"
        borderBottomLeftRadius="2rem"
        p={isLargerThan768 ? 6 : 4}
        color="white"
        // mb={8}
      >
        <Flex
          direction={isLargerThan768 ? "row" : "column"}
          alignItems={isLargerThan768 ? "flex-start" : "center"}
          justifyContent="space-between"
        >
          <Flex
            direction={isLargerThan480 ? "row" : "column"}
            alignItems={isLargerThan480 ? "center" : "center"}
            mb={isLargerThan768 ? 0 : 4}
            mt={isLargerThan768 ? 10 : 0}
          >
            <Image
              src={currentLang == 'en' ? logoImageEn : logoImageTn}
              alt="Title image"
              ml={4}
              mt={4}
            />
          </Flex>
          <Image
            src={bannerImage}
            alt="banner image"
            ml={isLargerThan768 ? 4 : 0}
            mt={isLargerThan768 ? 0 : 4}
            maxW={isLargerThan1024 ? "300px" : isLargerThan768 ? "250px" : "375px"}
          />
        </Flex>
      </Box>
    );
  };
  return (
    <ChakraProvider>
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

{/* <Box position="relative"> */}
   
    {/* Fixed logo and LanguageSelector */}
    <Flex
          position="sticky"
          top="0"
          zIndex="sticky"
          bg="white"
          p={3}
          alignItems="center"
          justifyContent="space-between"
          boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
        >
          <Image 
            src={logoImage} 
            alt="Logo" 
            h={isLargerThan1024 ? "40px" : isLargerThan768 ? "35px" : "30px"}
          />
          <LanguageSelector
            currentLang={currentLang}
            setCurrentLang={setCurrentLang}
            supportedLanguages={translations_languages}
            isLargerThan768={isLargerThan768}
            displayNames={displayNames}
          />
        </Flex>
      
        {renderPageTitle()}

      <Box p={[2, 4, 6]} maxW="90%" mx="auto" bg="white" borderRadius={10} position="relative">
        <form onSubmit={handleSubmit}>
          <VStack spacing={[3, 4, 5]}>
            {questions.map((question) => (
              <FormControlComponent
                key={question.id}
                question={question}
                formData={formData}
                handleChange={handleChange}
                errors={errors}
                currentLang={currentLang}
                isLargerThan768={isLargerThan768}
                handleImageUploadBefore={handleImageUploadBefore}
              />
            ))}
            <Flex h={2}></Flex>
            <GradientSubmitButton isLargerThan768={isLargerThan768}>
              {renderMultiLangButtonText('submitButton')}
            </GradientSubmitButton>
          </VStack>
        </form>
      </Box>
      <LanguageSelector
        currentLang={currentLang}
        setCurrentLang={setCurrentLang}
        supportedLanguages={translations_languages}
        isLargerThan768={isLargerThan768}
        displayNames={displayNames}
      />
      <SubscriptionPopup
        isOpen={isSubscriptionOpen}
        onClose={closeSubscriptionPopup}
        transformedData={submitedData}
        onSuccess={openSuccessPopup}
        currentLang={currentLang}
        supportedLanguages={translations_languages}
      />
      <SuccessPopup
        isOpen={isSuccessPopupOpen}
        onClose={closeSuccessPopup}
        currentLang={currentLang}
        supportedLanguages={supportedLanguages}
      />
      {/* </Box> */}
    </ChakraProvider>
  );
};

export default QuestionnairePage;
