import React, { useState } from 'react';
import LanguageCard from './LanguageCard';

import BasePageComponent from '../../../components/base/BasePageComponent';

const LanguagesPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'supported_languages';

  const sortFields = [
    { value: 'name', label: 'Name' },
  ];

  const itemsPerPageOptions = [5, 10, 15];

  const tableHeaders = ['lang', 'display_order', 'is_active'];

  const keyMappings = [
    { lang: 'Language' },
    { display_order: 'Sort Order' },
    { is_active: 'Active' },
  ];

  return (
    <BasePageComponent
      txt="Languages"
      itemCardComponent={(props) => <LanguageCard {...props} language={language} />}
      sortFields={sortFields}
      itemsPerPageOptions={itemsPerPageOptions}
      maxPaginationButtons={5}
      viewStyle="grid" // Set the initial view style to grid
      tableName={tableName}
      primaryKey='lang'
      defaultSortKey='display_order'
      tableHeaders={tableHeaders}
      keyMappings={keyMappings}
      gridView={true}
      showAddIcon={false}
    />
  );
};

export default LanguagesPage;
