// FormFields/ImageField.jsx
import React, { useState } from 'react';
import { FormControl, FormLabel, Input, Image, Box } from '@chakra-ui/react';

const ImageField = ({ label, value, onChange, tableName, ...rest }) => {
  const [uploading, setUploading] = useState(false);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    try {
      const folder = tableName === 'icon_setting' ? 'icon' : 
                     tableName === 'tutorial_settings' ? 'tutorial' : 'icon';
      const publicUrl = await global.genericapi.uploadImageAndGetPublicUrl(folder, file);
      if (publicUrl) {
        onChange(publicUrl);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <FormControl mb={4}>
      <FormLabel>{label}</FormLabel>
      {value && <Image src={value} boxSize="150px"  objectFit="contain" mt={2} />}
      <Input
        type="file"
        mt={4}
        h={12}
        py={2}
        px={2}
        onChange={handleImageChange}
        isDisabled={uploading}
        {...rest}
      />
    </FormControl>
  );
};

export default ImageField;