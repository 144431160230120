import React, {useEffect, useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
import AuthService from "../../../axios/service/AuthService";

function SignOut() {

    const history = useHistory();
    useEffect(() => {
        doSignOut();
    }, []);

    async function doSignOut() {
        history.push({
            pathname: `/`,
        });
        AuthService.signOut().then(response => {
            console.log("LogOut response ",response)
            alert('Log Out Success');
            window.localStorage.clear();
            window.sessionStorage.clear();
        })
    }

    return <></>;
}

export default SignOut;
