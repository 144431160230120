// import React, { useState } from 'react';
// import BasePageComponent from '../../../components/base/BasePageComponent';
// import AddEditThemesModal from './AddEditThemesModal';
// import ThemeCard from './ThemeCard';


// // {
// //   "id": 1,
// //   "start_date": "2024-04-18T04:11:56+00:00",
// //   "end_date": "2024-05-02T04:11:59+00:00",
// //   "created_at": "2024-04-18T04:12:03.088782+00:00",
// //   "updated_at": "2024-04-18T04:12:03.088782+00:00"
// // }

// const ThemesPage = () => {
//   const [language, setLanguage] = useState('en'); // Default to English
//   const tableName = 'theme_settings';

//   const itemsPerPageOptions = [5, 10, 15];

//   const keyMappings = {
//     id: 'ID',
//     main_color: 'Main Color',
//     second_color: 'Second Color',
//     third_color: 'Third Color',
//     created_at: 'Created At',
//     updated_at: 'Updated At'
//   };

//   const tableHeaders = [
//     'id',
//     'main_color', 
//     'second_color', 
//     'third_color', 
//     'created_at', 
//     'updated_at', 
//   ];
//   return (
//     <BasePageComponent
//       txt="Themes"
//       addItemComponent={AddEditThemesModal}
//       itemCardComponent={ThemeCard}
//       showToggleView={false}
//       tableName={tableName}
//       tableHeaders={tableHeaders}
//       keyMappings={keyMappings} // Pass key mappings to BasePageComponent
//     />
//   );
// };

// export default ThemesPage;


// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   RadioGroup,
//   Radio,
//   Stack,
//   Image,
//   Text,
//   Heading,
//   Flex,
//   Spinner,
//   Alert,
//   AlertIcon,
// } from '@chakra-ui/react';
// // import supabase from '../../../supabaseClient'; // Import your Supabase client
// import { supabase } from "../../../../../api/supabase"

// const ThemesPage = () => {
//   const [selectedOption, setSelectedOption] = useState('');
//   const [options, setOptions] = useState([]);
//   const [images, setImages] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');

//   // Fetch options and images from an RPC function
//   useEffect(() => {
//     const fetchOptionsAndImages = async () => {
//       setLoading(true);
//       setError('');

//       try {
//         // Replace 'your_rpc_function' with the actual RPC function name
//         const { data, error } = await supabase.rpc('get_color_theme_configs');
//         console.log('data,error', data, error)
        
//         if (error) throw error;
        
//         if (data) {
//           setOptions(data.options); // Assume data.options contains the options
//           setImages(data.images);   // Assume data.images contains the images
//           setSelectedOption(data.options[0]?.value || ''); // Set default selected option
//         }
//       } catch (error) {
//         setError('Failed to fetch options and images.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchOptionsAndImages();
//   }, []);

//   // Get the images for the selected option
//   const filteredImages = images.filter(image => image.optionValue === selectedOption);

//   if (loading) {
//     return (
//       <Flex justify="center" align="center" height="100vh">
//         <Spinner size="xl" />
//       </Flex>
//     );
//   }

//   if (error) {
//     return (
//       <Alert status="error">
//         <AlertIcon />
//         {error}
//       </Alert>
//     );
//   }

//   return (
//     <Box p={4}>
//       <Heading as="h2" size="lg" mb={4}>Select a Theme</Heading>
      
//       {/* Radio Buttons */}
//       <RadioGroup onChange={setSelectedOption} value={selectedOption}>
//         <Stack direction="row" spacing={5}>
//           {options.map((option, index) => (
//             <Radio key={index} value={option.value}>
//               {option.label}
//             </Radio>
//           ))}
//         </Stack>
//       </RadioGroup>

//       {/* Image List */}
//       <Flex wrap="wrap" mt={8}>
//         {filteredImages.map((image, index) => (
//           <Box key={index} mr={4} mb={4}>
//             <Image
//               src={image.url}
//               alt={`Image ${index + 1}`}
//               boxSize="150px"
//               objectFit="cover"
//             />
//           </Box>
//         ))}
//       </Flex>
//     </Box>
//   );
// };

// export default ThemesPage;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Image,
  Heading,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  useBreakpointValue,
  AspectRatio,
  SimpleGrid
} from '@chakra-ui/react';
import { supabase } from "../../../../../api/supabase"

export const ColorEnum = {
  PURPLE: "purple",
  ORANGE: "orange",
  GREEN: "green",
  MAGENTA: "magenta",
};

export const colorMapping = {
  [ColorEnum.PURPLE]: "#5D40D1",
  [ColorEnum.ORANGE]: "#F58E54",
  [ColorEnum.GREEN]: "#0CC376",
  [ColorEnum.MAGENTA]: "#A91777",
};

const ThemesPage = () => {
  const [themes, setThemes] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [themeId, setThemeId] = useState('');
  const [timeoutId, setTimeoutId] = useState(null); // For managing the timeout

  // List of supported languages, ordered by preference
  const languagePriority = ['en', 'zh-Hans', 'zh-Hant'];

  // Fetch themes and images from RPC
  useEffect(() => {
    const fetchThemesAndImages = async () => {
      setLoading(true);
      setError('');

      try {
        // Fetch themes from the RPC function
        const { data, error } = await supabase.rpc('get_color_theme_configs');
        if (error) throw error;

        if (data) {
          setThemes(data);

          // Fetch the default theme selection from the theme_settings table
          const defaultTheme = await fetchDefaultTheme();

          // Set default selection based on defaultTheme color_theme
          const defaultThemeColor = defaultTheme.color_theme || '';
          setThemeId(defaultTheme.id)
          setSelectedOption(defaultThemeColor);

          // Set images based on the selected theme
          const selectedTheme = data.find(theme => theme.color_theme === defaultThemeColor);
          if (selectedTheme) {
            setImages(getImagesFromTheme(selectedTheme));
          }
        }
      } catch (error) {
        setError('Failed to fetch themes and images.');
      } finally {
        setLoading(false);
      }
    };

    fetchThemesAndImages();

    // Cleanup timeout on component unmount
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };

  }, []);

  // Function to fetch the default theme selection from theme_settings table
  const fetchDefaultTheme = async () => {
    try {
      const { data, error } = await supabase
        .from('color_theme_setting')
        .select('*')
        .limit(1)
        .single();
      if (error) throw error;
      return data || {}; // Return default theme
    } catch (error) {
      setError('Failed to fetch default theme.');
      return {}; // Return an empty object if fetch fails
    }
  };

  // Function to extract images based on the selected theme
  const getImagesFromTheme = (theme) => {
    for (let lang of languagePriority) {
      if (theme.translations[lang]?.length > 0) {
        return theme.translations[lang]; // Return the images for the first available language
      }
    }
    return []; // Return an empty array if no images are found
  };

  // Handle option change (theme change)
  const handleOptionChange = async (newTheme) => {
    setUpdateLoading(true);
    setError('');
    setSuccess('');

    try {
      // Perform database update in Supabase
      const { error } = await supabase
        .from('color_theme_setting') // Use the correct table name
        .update({ color_theme: newTheme.color_theme }) // Update the selected theme
        .eq('id', themeId); // Use the ID of the first theme

      if (error) throw error;

      // Update state on successful API call
      setSelectedOption(newTheme.color_theme);
      setImages(getImagesFromTheme(newTheme));
      setSuccess('Theme updated successfully.');

      // Auto-hide success alert after 2 seconds
      const id = setTimeout(() => setSuccess(''), 2000);
      setTimeoutId(id);

    } catch (error) {
      setError('Failed to update the theme.');
      // Keep current option on failure
    } finally {
      setUpdateLoading(false);
    }
  };

  // Responsive layout values
  const colorBoxSize = useBreakpointValue({ base: '30px', md: '30px', lg: '40px' });
  const imageBoxSize = useBreakpointValue({ base: '200px', md: '250px', lg: '300px' });

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box p={4}>

      {/* Display Error Alert */}
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
          <CloseButton position="absolute" right="8px" top="8px" onClick={() => setError('')} />
        </Alert>
      )}

      {/* Display Success Alert */}
      {success && (
        <Alert status="success" mb={4}>
          <AlertIcon />
          <AlertTitle>Success</AlertTitle>
          <AlertDescription>{success}</AlertDescription>
          <CloseButton position="absolute" right="8px" top="8px" onClick={() => setSuccess('')} />
        </Alert>
      )}

      {/* Show loading spinner during the update */}
      {updateLoading && (
        <Flex justify="center" align="center" p={10}>
          <Spinner size="md" />
        </Flex>
      )}

      {/* Color Box Selector */}
      <Flex
        direction="row"
        wrap="wrap"
        justify="start"
        mb={8}
      >
        {themes.map((theme, index) => (
          <Box
            key={index}
            w={colorBoxSize}
            h={colorBoxSize}
            bg={colorMapping[theme.color_theme]} // Use the color theme for the background color
            borderRadius="full"
            boxShadow="lg"
            border={selectedOption === theme.color_theme ? '3px solid black' : '1px solid gray'} // Add border for selected state
            cursor="pointer"
            onClick={() => handleOptionChange(theme)}
            m={2} // Add margin for spacing
          />
        ))}
      </Flex>

      {/* Image List */}
      <Flex
        direction="row"
        wrap="wrap"
        justify="start"
      >

     {/* Image List with SimpleGrid */}
     <SimpleGrid
        columns={{ base: 2, md: 3, lg: 4 }}
        spacing={4}
      >
        {images.map((url, index) => (
          index != 0 ? <></> :
          <Box
          borderWidth="1px"
          borderRadius="lg"
          p={4}
          cursor="pointer"
          position="relative"
          minW={200}
        >
          <Box position="relative" width="100%" pt={0}>
    
            <Box
              borderRadius="3xl"
              overflow="hidden"
              boxShadow="md"
            >

            <AspectRatio ratio={200/435}>
              <Image
                src={url}
                alt={`Image ${index + 1}`}
                objectFit="cover"
              />
            </AspectRatio>
            </Box>
          </Box>
        </Box>
        ))}
      </SimpleGrid>

      </Flex>
    </Box>
  );
};

export default ThemesPage;

