// FormFields/SwitchField.jsx
import React from 'react';
import { FormControl, FormLabel, Switch } from '@chakra-ui/react';

const SwitchField = ({ label, value, onChange, ...rest }) => (
  <FormControl mb={4}>
    <FormLabel>{label}</FormLabel>
    <Switch
      isChecked={value || false}
      onChange={(e) => onChange(e.target.checked)}
      {...rest}
    />
  </FormControl>
);

export default SwitchField;