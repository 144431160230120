import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditCouponModal = ({ isOpen, onClose, initialData, onSave }) => {
  const couponDataStructure = {
    // coupon_code: { type: 'text', label: 'Coupon Code', required: true },
    // image_url: { type: 'image', label: 'Image' },
    redemption_start_date: { type: 'date', label: 'Redemption Start Date', required: true },
    redemption_end_date: { type: 'date', label: 'Redemption End Date', required: true },
    total_quantity: { type: 'number', label: 'Total Quantity', required: true },
    remaining_quantity: { type: 'number', label: 'Remaining Quantity' },
    effective_start_date: { type: 'date', label: 'Effective Start Date', required: true },
    effective_end_date: { type: 'date', label: 'Effective End Date', required: true },
    points_required: { type: 'number', label: 'Points Required' },
    translations: {
      fields: {
        caption: { type: 'text', label: 'Caption', required: true },
        detail: { type: 'textarea', label: 'Description', required: true },
        tnc: { type: 'textarea', label: 'TNC'},
        image_url: { type: 'image', label: 'Image'},
      }
    }
  };

  return (
    <BaseAddEditModal
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={couponDataStructure}
      tableName="coupons"
    />
  );
};

export default AddEditCouponModal;








