import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTitle = ({ titles, defaultTitle }) => {
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    const currentTitle = titles[pathname] || defaultTitle;
    document.title = currentTitle;
    document.querySelector('title[itemprop="name"]').textContent = currentTitle;
  }, [location, titles, defaultTitle]);

  return null; // This component doesn't render anything
};

export default PageTitle;