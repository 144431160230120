
// FormFields/DateField.jsx
import React, { useRef } from 'react';
import { FormControl, FormLabel, InputGroup, InputRightElement, Button } from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateField = ({ label, value, onChange, ...rest }) => {
  const datePickerRef = useRef(null);

  return (
    <FormControl mb={4}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <DatePicker
          selected={value ? new Date(value) : null}
          onChange={(date) => onChange(date)}
          dateFormat="yyyy/MM/dd"
          className="chakra-input css-1c6j008"
          wrapperClassName="chakra-datepicker"
          ref={datePickerRef}
          {...rest}
        />
        <InputRightElement width="3rem">
          <Button h="1.75rem" size="sm" onClick={() => datePickerRef.current.setOpen(true)}>
            <CalendarIcon />
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

export default DateField;