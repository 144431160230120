// BannerManagementPage.jsx
import BannerService from "../../../../axios/service/BannerService"

import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Button,
  IconButton,
  Divider,
  Heading,
  Container,
  Grid,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon, RepeatIcon, DeleteIcon } from '@chakra-ui/icons';
import BannerCard from './BannerCard';
import DeleteBannerDialog from './DeleteBannerDialog';
import UpdateBannerDialog from './UpdateBannerDialog';
import FullImagePreview from './FullImagePreview';
import { mockBanners } from './mockData';

const BannerManagementPage = (props) => {

  const { bannerType } = props;

  const [banners, setBanners] = useState({ pending: [], active: [], other: [] });
  const [isLoading, setIsLoading] = useState(true);
//   const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isAddNewOpen, onOpen: onAddNewOpen, onClose: onAddNewClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [bannerToDelete, setBannerToDelete] = useState(null);
  const cancelRef = React.useRef();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);

    // Supabase fetching data
    const data = (bannerType === 'home_banner') ? await BannerService.fetchAllHomeBanners() : await BannerService.fetchAllMainBanners();
    console.log('data',data);
    setBanners(data);
    setIsLoading(false);
    
    // // Simulate fetching data
    // setTimeout(() => {
    //   setBanners(mockBanners);
    //   setIsLoading(false);
    // }, 1000);
  };

  const handleEdit = (banner) => {
    setSelectedBanner(banner);
    onEditOpen();
  };

  const handleAddNew = () => {
    setSelectedBanner(null);
    onAddNewOpen();
  };

  const handleView = (banner) => {
    setPreviewImage(getFirstImageUrl(banner));
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
    setIsPreviewOpen(false);
  };

  const handleDelete = (banner) => {
    setBannerToDelete(banner);
    onDeleteOpen();
  };

  const getFirstImageUrl = (banner) => {
    const translations = banner.translations;
    for (const lang in translations) {
      if (translations[lang]?.image_url) {
        return translations[lang].image_url;
      }
    }
    return 'https://via.placeholder.com/236x150?text=No+Image'; // Placeholder if no image URL is found
  };

  const handleSubmit = () => {
    // Refresh the banners from the server
    // onClose();
    loadData();
  };

  return (
    <Box p={3}>
      <VStack spacing={8} align="start" w="100%">
        <HStack justifyContent="space-between" w="100%" wrap="wrap">
          <Heading as="h1" size="xl" flexShrink={0} isTruncated>
            {/* { bannerType === 'home_banner' ? 'Home Banner' : 'Main Banner'  } */}
          </Heading>
          <HStack spacing={4} mt={[4, 0]}>
            <IconButton
              icon={<RepeatIcon />}
              aria-label="Refresh Banners"
            //   colorScheme="teal"
              onClick={loadData}
              ml={[2, 4]}  // Added margin-left for spacing
            />
            <IconButton icon={<AddIcon />} aria-label="Add Coupon" onClick={handleAddNew} />
          </HStack>
        </HStack>
        <Divider />
        {isLoading ? (
          <Box w="100%" display="flex" justifyContent="center" alignItems="center" p={8}>
            <Spinner size="xl" />
          </Box>
        ) : (
          <VStack spacing={8} w="100%" align="start">
            {banners.active.length > 0 && (
              <>
                <Section title={bannerType === 'home_banner' ? "Home Banners" : "Main Banners"} banners={banners.active} onEdit={handleEdit} onView={handleView} onDelete={handleDelete} />
                <Divider />
              </>
            )}
            {banners.pending.length > 0 && (
              <>
                <Section title="Pending Banners" banners={banners.pending} onEdit={handleEdit} onView={handleView} onDelete={handleDelete} />
                <Divider />
              </>
            )}
            {banners.other.length > 0 && (
              <>
                <Section title="Submitted Banners" banners={banners.other} onEdit={handleEdit} onView={handleView} onDelete={handleDelete} />
              </>
            )}
          </VStack>
        )}
      </VStack>
      {isPreviewOpen && (
        <FullImagePreview isOpen={isPreviewOpen} onClose={handleClosePreview} imageUrl={previewImage} />
      )}

      <UpdateBannerDialog
        isOpen={isEditOpen}
        onClose={onEditClose}
        bannerToEdit={selectedBanner}
        onSubmit={handleSubmit}
        bannerType={bannerType}
      />

      <UpdateBannerDialog
        isOpen={isAddNewOpen}
        onClose={onAddNewClose}
        onSubmit={handleSubmit}
        bannerType={bannerType}
      />

      <DeleteBannerDialog
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        bannerToEdit={bannerToDelete}
        onSubmit={handleSubmit}
        bannerType={bannerType}
      />

    </Box>
  );
};

const Section = ({ title, banners, onEdit, onView, onDelete }) => (
  <VStack spacing={4} w="100%" align="start">
    <Text fontSize="lg" fontWeight="bold" textAlign="left">{title}</Text>
    <Grid
      templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
      gap={4}
      w="100%"
      maxW="100%"
    >
      {banners.map((banner, index) => (
        <BannerCard
          key={banner.id}
          banner={banner}
          onEdit={() => onEdit(banner)}
          onView={() => onView(banner)}
          onDelete={() => onDelete(banner)}
        />
      ))}
    </Grid>
  </VStack>
);

export default BannerManagementPage;
