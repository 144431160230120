// FormFields/ColorField.jsx
import React from 'react';
import { FormControl, FormLabel, Box } from '@chakra-ui/react';
import { ChromePicker } from 'react-color';

const predefinedColors = [
  '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF',
  '#800000', '#008000', '#000080', '#808000', '#800080', '#008080',
];

const ColorField = ({ label, value, onChange, ...rest }) => (
  <FormControl mb={4}>
    <FormLabel>{label}</FormLabel>
    <Box>
      <Box
        height="36px"
        width="100%"
        borderRadius="md"
        bg={value || '#fff'}
        borderWidth="1px"
        borderColor="gray.200"
        mb={2}
      />
      <Box mb={4}>
        <Box d="flex" flexWrap="wrap">
          {predefinedColors.map((color) => (
            <Box
              key={color}
              height="36px"
              width="36px"
              bg={color}
              borderRadius="md"
              borderWidth="1px"
              borderColor={value === color ? 'blue.500' : 'gray.200'}
              m={1}
              cursor="pointer"
              onClick={() => onChange(color)}
            />
          ))}
        </Box>
      </Box>
      <ChromePicker
        color={value || '#fff'}
        onChangeComplete={(color) => onChange(color.hex)}
      />
    </Box>
  </FormControl>
);

export default ColorField;
