import React from 'react';
import { Box, Text, Flex, Button } from '@chakra-ui/react';

const MaintainenceCard = ({ item, onEdit, onDelete, language }) => {

  const name = item?.name?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="md" p={4} minW={160} minH={100}>
      <Text fontWeight="bold">{name}</Text>
      <Text>{item.description}</Text>
      {/* <Flex mt={2}>
        <Button size="sm" onClick={onEdit} mr={2}>
          Edit
        </Button>
        <Button size="sm" onClick={onDelete} colorScheme="red">
          Delete
        </Button>
      </Flex> */}
    </Box>
  );
  return <></>
};

export default MaintainenceCard;
