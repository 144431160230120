// FormFields/TextareaField.jsx
import React from 'react';
import { FormControl, FormLabel, Textarea, Button, Flex } from '@chakra-ui/react';

const TextareaField = ({ label, value, onChange, showTranslateButton, onTranslate, ...rest }) => (
  <FormControl mb={4}>
    <FormLabel>{label}</FormLabel>
    <Flex align="center">
      <Textarea
        flex="1"
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />
      {showTranslateButton && (
        <Button colorScheme="blue" ml={2} onClick={() => onTranslate(value)}>
          Translate
        </Button>
      )}
    </Flex>
  </FormControl>
);

export default TextareaField;