import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Image,
  useColorMode,
} from '@chakra-ui/react';

const FullImagePreview = ({ isOpen, onClose, imageUrl }) => {
  const { colorMode } = useColorMode();

  const handleOverlayClick = (e) => {
    // Prevent closing if the click originates from within the image itself
    if (e.target.tagName === 'IMG') {
      e.stopPropagation();
    } else {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent bg='black.300' onClick={handleOverlayClick}>
        <ModalCloseButton  onClick={onClose} mt='40px' mr='40px' />
        <ModalBody display="flex" justifyContent="center" alignItems="center">
          <Image src={imageUrl} maxH="90vh" maxW="90vw" objectFit="contain" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FullImagePreview;
