import axios from 'axios';
import { supabase } from "../../api/supabase";

const table_name = 'cms_user_roles'

const RoleService = {
    async getRoles(body) {
        const res = await supabase
            .from(table_name)
            .select()
        return res
    },

    async createRole(body) {
        const res = await supabase
            .from(table_name)
            .insert(body)
        return res
    },

    async deleteRole(id) {
        const res = await supabase
            .from(table_name)
            .delete()
            .eq('user_id', id)
        return res
    },

    async updateRole(id, body) {
        const res = await supabase
            .from(table_name)
            .update(body)
            .eq('user_id', id)
        return res
    }

}

export default RoleService