import BaseAddEditModal from '../../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditIconModal = ({ isOpen, onClose, initialData, onSave }) => {
  const dataStructure = {
    icon_url: { type: 'image', label: 'Image', required: true},
  };

  return (
    <BaseAddEditModal
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="icon_settings"
    />
  );
};

export default AddEditIconModal;








