// utils/DataTransformer.js

const DataTransformer = (data, tableName) => {
    console.log('originalData', data, tableName);
  
    const transformData = (originalData) => {
      switch (tableName) {
        case 'coupons':
          return transformCouponData(originalData);
        case 'force_update_history':
          return transformForceUpdateData(originalData);
        case 'tutorial_settings':
          return transformTutorialSettingsData(originalData);
        // Add more cases for other tables as needed
        default:
          return originalData;
      }
    };
  
    const transformCouponData = (originalData) => {
      const {
        id,
        points_required,
        effective_start_date,
        effective_end_date,
        redemption_start_date,
        redemption_end_date,
        total_quantity,
        remaining_quantity,
        translations
      } = originalData;
    
      const transformedTranslations = {};
      translations.forEach(translation => {
        transformedTranslations[translation.language] = {
          image_url: translation.image_url,
          caption: translation.caption,
          detail: translation.detail,
          tnc: translation.tnc
        };
      });
    
      return {
        coupon_id: id,
        points_required,
        effective_start_date,
        effective_end_date,
        redemption_start_date,
        redemption_end_date,
        total_quantity,
        remaining_quantity,
        translations: transformedTranslations
      };
    };
  
    const transformForceUpdateData = (originalData) => {
      // Implement force update history specific transformations here if needed
      return originalData;
    };
  
    const transformTutorialSettingsData = (originalData) => {
      // Remove 'sort_order' key if it exists
      const { sort_order, ...rest } = originalData;
      return rest;
    };
  
    // Transform the data
    let transformedData = transformData(data);
  
    // Remove translations if they're empty
    if (transformedData.translations && Object.keys(transformedData.translations).length === 0) {
      const { translations, ...rest } = transformedData;
      transformedData = rest;
    }
  
    return transformedData;
  };
  
  export default DataTransformer;