import React, { useCallback } from 'react';

import { Box, Text, Flex, Button, Switch, IconButton, Spacer } from '@chakra-ui/react';
import { StarIcon, ViewIcon, CloseIcon, CheckIcon } from '@chakra-ui/icons';

const TabbarsCard = ({  item, onDelete, onEdit, onFav, onEnableDisable, onView, onToggleActive }) => {

  const handleFav = useCallback(() => onFav(item), [onFav, item]);
  const handleView = useCallback(() => onView(item), [onView, item]);
  const handleEnableDisable = useCallback(() => onEnableDisable(item), [onEnableDisable, item]);
  const handleEdit = useCallback(() => onEdit(item), [onEdit, item]);
  const handleDelete = useCallback(() => onDelete(item), [onDelete, item]);
  const handleToggleActive = useCallback(() => onToggleActive(item), [onToggleActive, item]);

  const {
    item_type = '',
    url = null,
    sort_order = 0,
    is_active = 0,
    created_at = '',
    updated_at = ''
  } = item || {};


  // console.log('item',item)
  const name = item_type?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="md" p={4} minW={160} minH={100}>
      <Text fontWeight="bold">{name}</Text>
      <Text>{item.description}</Text>

      <Flex mt={6} justify="space-between" align="center">
      {/* <IconButton icon={<StarIcon />} onClick={() => onFav(item)} aria-label="Favorite" />
      <IconButton icon={<ViewIcon />} onClick={() => onView(item)} aria-label="View" />
      <IconButton icon={item.enabled ? <CloseIcon /> : <CheckIcon />} onClick={() => onEnableDisable(item)} aria-label="Enable/Disable" />
      <Button onClick={() => onEdit(item)} size="sm">Edit</Button> */}
      {/* <Button onClick={() => onDelete(item)} size="sm" colorScheme="red">Delete</Button> */}
      {/* <Spacer></Spacer> */}
      <Switch isChecked={item.is_active} onChange={handleToggleActive} />
      {/* <IconButton key={item.item_type} icon={item.is_active ? <CloseIcon /> : <CheckIcon />} onClick={() => handleToggleActive(item)} aria-label="Enable/Disable" /> */}
    </Flex>
    </Box>
  );
};

export default TabbarsCard;
