// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import VIPLevelsPage from "./VIPLevelsPage";
import Card from "components/card/Card";

export default function Settings() {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card
                direction='column'
                w='100%'
                px='20px'
                overflowX={{ sm: "scroll", lg: "hidden" }}>
                <VIPLevelsPage></VIPLevelsPage>
            </Card>
        </Box>
    );
}
