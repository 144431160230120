// BannerManagementPage.jsx
import BannerService from "../../../../../axios/service/BannerService"

import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Button,
  IconButton,
  Divider,
  Heading,
  Container,
  Grid,
  Spinner,
  useDisclosure,
  Spacer,
  Flex,
} from '@chakra-ui/react';
import { AddIcon, RepeatIcon, DeleteIcon } from '@chakra-ui/icons';
import ImageCard from './ImageCard';
import DeleteBannerDialog from '../../../components/banners/DeleteBannerDialog';
import UpdateBannerDialog from '../../../components/banners/UpdateBannerDialog';
import FullImagePreview from '../../../components/banners/FullImagePreview';
import { mockBanners } from '../../../components/banners/mockData';

const ImageManagementPage = (props) => {

  const { bannerType } = props;

  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
//   const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isAddNewOpen, onOpen: onAddNewOpen, onClose: onAddNewClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [bannerToDelete, setBannerToDelete] = useState(null);
  const cancelRef = React.useRef();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);

    // Supabase fetching data
    const data = await BannerService.fetchTutorials();
    setBanners(data);
    setIsLoading(false);
    
    // // Simulate fetching data
    // setTimeout(() => {
    //   setBanners(mockBanners);
    //   setIsLoading(false);
    // }, 1000);
  };

  const handleEdit = (banner) => {
    setSelectedBanner(banner);
    onEditOpen();
  };

  const handleAddNew = () => {
    setSelectedBanner(null);
    onAddNewOpen();
  };

  const handleView = (banner) => {
    setPreviewImage(getFirstImageUrl(banner));
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
    setIsPreviewOpen(false);
  };

  const handleDelete = (banner) => {
    setBannerToDelete(banner);
    onDeleteOpen();
  };

  const getFirstImageUrl = (banner) => {
    const translations = banner.translations;
    for (const lang in translations) {
      if (translations[lang]?.image_url) {
        return translations[lang].image_url;
      }
    }
    return 'https://via.placeholder.com/236x150?text=No+Image'; // Placeholder if no image URL is found
  };

  const handleSubmit = () => {
    // Refresh the banners from the server
    // onClose();
    loadData();
  };

  return (
    <Box p={3}>
      <VStack spacing={8} align="start" w="100%">
        <HStack justifyContent="space-between" w="100%" wrap="wrap">
          <Heading as="h1" size="xl" flexShrink={0} isTruncated>
            {/* { bannerType === 'home_banner' ? 'Home Banner' : 'Main Banner'  } */}
          </Heading>
          <HStack spacing={4} mt={[4, 0]}>
            <IconButton
              icon={<RepeatIcon />}
              aria-label="Refresh Banners"
            //   colorScheme="teal"
              onClick={loadData}
              ml={[2, 4]}  // Added margin-left for spacing
            />
            <IconButton icon={<AddIcon />} aria-label="Add Coupon" onClick={handleAddNew} />
          </HStack>
        </HStack>
        <Divider />
        {isLoading ? (
          <Box w="100%" display="flex" justifyContent="center" alignItems="center" p={8}>
            <Spinner size="xl" />
          </Box>
        ) : (
          <VStack spacing={8} w="100%" align="start">
 
              {banners.length > 0 &&
                banners.map((banner, index) => (
                  <>
                    <Section title={'Position ' + banner.sort_order} banners={banner.translations} onEdit={handleEdit} onView={handleView} onDelete={handleDelete} />
                    <Divider />
                  </>
                ))
              }

          </VStack>
        )}
      </VStack>
      {isPreviewOpen && (
        <FullImagePreview isOpen={isPreviewOpen} onClose={handleClosePreview} imageUrl={previewImage} />
      )}

      <UpdateBannerDialog
        isOpen={isEditOpen}
        onClose={onEditClose}
        bannerToEdit={selectedBanner}
        onSubmit={handleSubmit}
        bannerType={bannerType}
      />

      <UpdateBannerDialog
        isOpen={isAddNewOpen}
        onClose={onAddNewClose}
        onSubmit={handleSubmit}
        bannerType={bannerType}
      />

      <DeleteBannerDialog
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        bannerToEdit={bannerToDelete}
        onSubmit={handleSubmit}
        bannerType={bannerType}
      />

    </Box>
  );
};

const Section = ({ title, banners, onEdit, onView, onDelete }) => (
  <VStack spacing={4} w="100%" align="start">
    <Flex w="100%">
      <Text fontSize="lg" fontWeight="bold" textAlign="left">{title}</Text>
      <Spacer></Spacer>
      <IconButton icon={<DeleteIcon />} aria-label="Delete" />
    </Flex>
    <Grid
      ml={4}
      templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
      gap={4}
      w="100%"
      maxW="100%"
    >

      {
      banners.map((banner, index) => (
        <ImageCard
          key={banner.id}
          banner={banner}
          onEdit={() => onEdit(banner)}
          onView={() => onView(banner)}
          onDelete={() => onDelete(banner)}
        />
      ))
      }

    </Grid>

  </VStack>
);

export default ImageManagementPage;
