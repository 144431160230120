// TableOperations.js

const defaultOperations = {
  add: async (tableName, data) => {
    return await global.genericapi.addRecord(tableName, data);
  },
  update: async (tableName, id, data, mainKey) => {
    return await global.genericapi.updateRecord(tableName, id, data, mainKey);
  }
};

const TableOperations = {
  force_update_history: {
    add: (data) => global.genericapi.updateForceRecord(data),
    update: (data) => global.genericapi.updateForceRecord(data),
  },
  coupons: {
    add: (data) => global.genericapi.rpcRecord('create_coupon', data),
    update: (data) => global.genericapi.rpcRecord('update_coupon', data),
  },
  latest_news: {
    add: (data) => global.genericapi.rpcRecord('create_latest_news', data),
    update: (data) => global.genericapi.rpcRecord('update_latest_news', data),
  },
  // Add more tables as needed

  // Default operations
  default: defaultOperations
};

export default TableOperations;