// {
//   "id": 1,
//   "district_id": 9,
//   "latitude": 22.3120024,
//   "longitude": 114.2185664,
//   "created_at": "2024-04-22T09:56:02.702594+00:00",
//   "updated_at": "2024-04-22T09:56:02.702594+00:00",
//   "store_translation": [
//       {
//           "id": 1,
//           "address": "address en",
//           "remarks": null,
//           "language": "en",
//           "store_id": 1,
//           "created_at": "2024-04-22T09:56:57.551359+00:00",
//           "updated_at": "2024-04-22T09:56:57.551359+00:00",
//           "branch_name": "branch name en",
//           "business_hours": "9:00-6:00"
//       }
//   ]
// }

import React, { useState } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent';
import AddEditStoreModal from './AddEditStoreModal';
import StoreCard from './StoreCard';

const StoresPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'store';
  const joins = [
    { table: 'store_translation', fields: '*', foreignKey: 'id' }
  ];

  const keyMappings = {
    // id: 'ID',
    district_id: 'District ID',
    branch_name: 'Branch Name',
    address: 'Address',
    business_hours: 'Business Hours',
    latitude: 'Latitude',
    longitude: 'Longitude',
    created_at: 'Created At',
    updated_at: 'Updated At'
  };

  const tableHeaders = [
    // 'id',
    'district_id', 
    'store_translation.0.branch_name',
    'store_translation.0.address', 
    'store_translation.0.business_hours',
    'latitude', 
    'longitude', 
    'created_at', 
    'updated_at',
  ];

  return (
    <BasePageComponent
      txt="Store Info"
      addItemComponent={AddEditStoreModal}
      itemCardComponent={StoreCard}
      showToggleView={false}
      tableName={tableName}
      joins={joins}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} // Pass key mappings to BasePageComponent
    />
  );
};

export default StoresPage;