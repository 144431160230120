import React, { useCallback } from 'react';
// import { Box, Text, Flex, Button, Switch, Spacer } from '@chakra-ui/react';
import { Box, Text, Image, VStack, HStack, Button, IconButton, Badge, Flex, Spacer } from '@chakra-ui/react';
import { EditIcon, ViewIcon, DeleteIcon } from '@chakra-ui/icons';

const IconCard = ({ item, onDelete, onEdit, onFav, onEnableDisable, onView, onToggleActive }) => {

  const {
    id = '',
    icon_url = null,
    created_at = '',
    updated_at = ''
  } = item || {};

  const handleToggleActive = useCallback(() => onToggleActive(item), [onToggleActive, item]);

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
      boxShadow="md"
      minWidth="200px"
      maxWidth="200px"
    >
      <Box
          w="full"
          h="120px"
          bg="gray.200"
          // borderRadius="md"
          overflow="hidden"
          // display="flex"
          // alignItems="center"
          // justifyContent="center"
        >
          <Image
            src={icon_url}
            alt=" "
            objectFit="contain" // w 232 h 120  Changed to "contain" to ensure the image fits within the container
            w="full"
            h="full"
          />
        </Box>

      <Flex w="100%" mt={4}>
          <IconButton icon={<EditIcon />} aria-label="Edit" onClick={() => onEdit(item)} />
          <IconButton icon={<DeleteIcon />} aria-label="Delete" onClick={() => onDelete(item)} />
        </Flex>
    </Box>
  );
};

export default IconCard;
