// VIPLevelCardList.js
import React from 'react';
import { Box, Text, Flex, Button, Image } from '@chakra-ui/react';

const VIPLevelCardList = ({ item, onEdit, onDelete }) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} mb={2}>
      <Text fontWeight="bold">{item.level}</Text>
      <Text>{item.benefits}</Text>
      <Text>Required Points: {item.required_points}</Text>
      <Text>Valid Period: {item.valid_period} months</Text>
      <Text>Expense Point Ratio: {item.expense_point_ratio}</Text>
      {item.card_face && <Image src={item.card_face} alt="Card Face" mt={2} />}
      <Flex mt={2}>
        <Button size="sm" onClick={onEdit} mr={2}>
          Edit
        </Button>
        <Button size="sm" onClick={onDelete} colorScheme="red">
          Delete
        </Button>
      </Flex>
    </Box>
  );
};

export default VIPLevelCardList;
