// {
//   "id": 1,
//   "target": "topic",
//   "topic_id": 1,
//   "fcm_tokens": null,
//   "deep_link": null,
//   "status": "draft",
//   "scheduled_at": null,
//   "sent_at": null,
//   "created_by": "a0829146-72a5-481b-9cfc-2079f6ddcd6f",
//   "created_at": "2024-04-24T10:18:22.500587+00:00",
//   "updated_at": "2024-04-24T10:18:22.500587+00:00",
//   "push_notification_translations": [
//       {
//           "id": 1,
//           "title": "test",
//           "message": "test",
//           "language": "en",
//           "created_at": "2024-04-24T10:23:24.752236+00:00",
//           "updated_at": "2024-04-24T10:23:24.752236+00:00",
//           "push_notification_id": 1
//       }
//   ],
//   "push_notification_topics": {
//       "id": 1,
//       "name": "member_tier_basic",
//       "created_at": "2024-04-24T08:49:27.107036+00:00",
//       "updated_at": "2024-04-24T08:49:27.107036+00:00",
//       "description": "member_token_basic"
//   }
// }

import React, { useState } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent';
import AddEditPushNotificationModal from './AddEditPushNotificationModal';
import PushNotificationCard from './PushNotificationCard';

const PushNotificationsPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'push_notifications';
  const joins = [
    { table: 'push_notification_translations', fields: '*', foreignKey: 'id' },
    { table: 'push_notification_topics', fields: '*', foreignKey: 'id' }
  ];

  const keyMappings = {
    title: 'Title',
    message: 'Message',
    // language: 'Language',
    target: 'Target',
    name: 'Topic Name',
    description: 'Topic Desc',
    deep_link: 'Deep Link',
    status: 'Status',
    scheduled_at: 'Scheduled At',
    sent_at: 'Sent At',
    created_by: 'Created By',
    created_at: 'Created At',
    updated_at: 'Updated At'
  };

  const tableHeaders = [
    'translations.0.title',
    'translations.0.message',
    // 'translations.0.language',
    'target', 
    'push_notification_topics.name', 
    'push_notification_topics.description',
    'deep_link', 
    'status', 
    'scheduled_at', 
    'sent_at', 
    'created_by', 
    'created_at', 
    'updated_at',
  ];

  return (
    <BasePageComponent
      txt="Push Notification"
      addItemComponent={AddEditPushNotificationModal}
      itemCardComponent={PushNotificationCard}
      showToggleView={false}
      tableName={tableName}
      joins={joins}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} // Pass key mappings to BasePageComponent
    />
  );
};

export default PushNotificationsPage;


