import BaseAddEditModal from '../../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditForceUpdateModal = ({ isOpen, onClose, initialData, onSave }) => {
  
  const dataStructure = {
    platform: { type: 'select', label: 'Platform', options: [
        {label:'iOS',value:'ios'},
        {label:'Android',value:'android'},
    ],required: true },
    mandatory_update_version: { type: 'text', label: 'Mandatory Update Version', required: true },
  };

  // const keyMappings = {
  //   id: 'ID',
  //   platform: 'Platform',
  //   version: 'Version',
  //   created_at: 'Created At',
  //   // updated_at: 'Updated At'
  // };

  const { platform } = dataStructure;
  const { options } = platform;
  const defaultPlatform = options.length > 1 ? {'platform': options[0].value} : null;

  return (
    <BaseAddEditModal
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData || defaultPlatform}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="force_update_history"
      // mainKey='tier'
    />
  );
};
export default AddEditForceUpdateModal;










