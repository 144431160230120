// StoreCard.js
import React, { useCallback } from 'react';
import { Box, Text, Flex, Button } from '@chakra-ui/react';

var recount = 1;

const StoreCard = React.memo(({ item, onEdit, onDelete, language }) => {

  //   {
  //     "store_translation": [
  //         {
  //             "id": 1,
  //             "address": "address en",
  //             "remarks": null,
  //             "language": "en",
  //             "store_id": 1,
  //             "created_at": "2024-04-22T09:56:57.551359+00:00",
  //             "updated_at": "2024-04-22T09:56:57.551359+00:00",
  //             "branch_name": "branch name en",
  //             "business_hours": "9:00-6:00"
  //         }
  //     ]
  // }

  const translation = item?.store_translation?.find((trans) => trans.language === language) || '';

  // console.log('recount',recount++)
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="md" p={4}>
      <Text fontWeight="bold">{translation?.branch_name}</Text>
      <Text>{translation?.language}</Text>
      <Text>{translation?.business_hours}</Text>
      <Text>{translation?.address}</Text>
      <Flex mt={2}>
        <Button size="sm" onClick={onEdit} mr={2}>
          Edit
        </Button>
        <Button size="sm" onClick={onDelete} colorScheme="red">
          Delete
        </Button>
      </Flex>
    </Box>
  );
});

export default StoreCard;
