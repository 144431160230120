// FormFields/RadioField.jsx (continued)
import React from 'react';
import { FormControl, FormLabel, RadioGroup, Radio, Stack } from '@chakra-ui/react';

const RadioField = ({ label, value, onChange, options, ...rest }) => (
  <FormControl mb={4}>
    <FormLabel>{label}</FormLabel>
    <RadioGroup value={value || ''} onChange={onChange}>
      <Stack direction="row">
        {options.map((option, index) => (
          <Radio key={index} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  </FormControl>
);

export default RadioField;


