
// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import UserCreatePage from "./userCreatePage";
import React from "react";

export default function Settings() {


    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid
                // mb='20px'
                // columns={{ sm: 1, md: 2 }}
                spacing={{ base: "20px", xl: "20px" }}>
                <UserCreatePage
                />
            </SimpleGrid>
        </Box>
    );
}
