import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Image,
  Text,
  IconButton,
  Button,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useMediaQuery,
  Flex,
  useBreakpointValue,
  Wrap,
  WrapItem,
  Grid,
  AspectRatio,
  SimpleGrid
} from '@chakra-ui/react';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { ColorEnum, colorMapping, colorThemeImages, configData, configData1 } from './constants';
import { translations, translations_languages, displayNames } from './translations';

const CheckmarkCircle = ({ isSelected }) => (
    <Box
      as="svg"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        fill={isSelected ? "#5A96E9" : "transparent"}
        stroke={isSelected ? "#5A96E9" : "#EAEAEA"}
        strokeWidth="1"
      />
      {isSelected && (
        <path
          d="M7 13l3 3 7-7"
          fill="none"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </Box>
  );

  const ColorThemeCard = ({ theme, isSelected, onClick }) => (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={4}
      cursor="pointer"
      bg={isSelected ? "rgba(90, 150, 233, 0.15)" : "white"}
      borderColor={isSelected ? "#5A96E9" : "#EAEAEA"}
      onClick={onClick}
      position="relative"
    >
      <Box
        position="absolute"
        top={3}
        left={2}
        w={6}
        h={6}
        borderRadius="full"
        bg={colorMapping[theme]}
        zIndex={1}
        boxShadow="0 0 0 2px white"
      />
      <Box position="relative" width="100%" pt={0}>

        <Box
          borderWidth="8px"
          borderColor="black"
          borderRadius="3xl"
          overflow="hidden"
          boxShadow="md"
          mx="auto"
        >
          <AspectRatio ratio={200 / 435} width="100%">
            <Image 
              src={colorThemeImages[theme]} 
              alt={`${theme} Theme Preview`} 
              objectFit="cover"
            />
          </AspectRatio>
        </Box>
      </Box>
    </Box>
  );
  

export const FormControlComponent = ({
  question, formData, handleChange, errors, currentLang, isLargerThan768,handleImageUploadBefore
}) => {
  const editorMinHeight = useBreakpointValue({ base: '120px', md: '200px', lg: '300px' });

  const renderLabel = (labelText) => {
    if (!labelText) {
      return null; // Return null if labelText is undefined or null
    }

    if (typeof labelText === 'string') {
      return <FormLabel>{labelText}</FormLabel>;
    }

    if (typeof labelText === 'object' && labelText !== null) {
    //   if (isLargerThan768) {
    //     return (
    //       <VStack align="start" spacing={1} mb={2}>
    //         {Object.entries(labelText).map(([lang, text]) => (
    //           <Text key={lang}>{text || ''}</Text> // Use empty string if text is undefined
    //         ))}
    //       </VStack>
    //     );
    //   }
      return <FormLabel>{labelText[currentLang] || Object.values(labelText)[0] || ''}</FormLabel>;
    }

    // If labelText is neither a string nor an object, return null
    return null;
  };

const renderRadioStyleCheckbox = (options, value, onChange) => (
    <Wrap mt={4} spacing={3} direction={{ base: "column", md: "row" }} width={isLargerThan768 ? "100%" : "80%"}>
      {options.map((option) => {
        const isSelected = value?.includes(option);
        const isDisabled = value?.length <= 1 && value?.includes(option);
        return (
          <WrapItem key={option} width={{ base: "100%", md: "30%" }}>
            <Flex 
              alignItems="center" 
              justifyContent="space-between"
              borderWidth="1px"
              borderRadius={10}
              p={2}
              bg={isSelected ? "rgba(90, 150, 233, 0.15)" : "#FFFFFF"}
              borderColor={isSelected ? "#5A96E9" : "#EAEAEA"}
              cursor={isDisabled ? "not-allowed" : "pointer"}
              opacity={isDisabled ? 0.5 : 1}
              width="100%"
              onClick={() => {
                if (!isDisabled) {
                  const newValue = isSelected
                    ? value.filter(v => v !== option)
                    : [...(value || []), option];
                  onChange(newValue);
                }
              }}
            >
              <Text ml={2} flex="1" noOfLines={1}>{displayNames[option] || option}</Text>
              <CheckmarkCircle isSelected={isSelected} />
            </Flex>
          </WrapItem>
        );
      })}
    </Wrap>
  );

  const renderColorThemeSetting = () => {
    return (
    <SimpleGrid mt={4} columns={{ base: 2, md: 4, xl: 4 }} gap={4}>
    {/* <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4}> */}
      {question.options.map((option) => (
        <ColorThemeCard
          key={option}
          theme={option}
          isSelected={formData[question.id] === option}
          onClick={() => handleChange(question.id, option)}
        />
      ))}
    </SimpleGrid>
  )
};


  const renderFormControl = () => {
    switch (question.id) {
        case 'color_theme_setting':
          return (
            <FormControl key={question.id} id={`question-${question.id}`} isInvalid={errors[question.id]}>
              {renderLabel(question.question)}
              {renderColorThemeSetting()}
              {errors[question.id] && <Text color="red.500">{errors[question.id]}</Text>}
            </FormControl>
          );
        case 'supported_languages':
          return (
            <FormControl key={question.id} id={`question-${question.id}`} isInvalid={errors[question.id]}>
              {renderLabel(question.question)}
              {renderRadioStyleCheckbox()}
              {errors[question.id] && <Text color="red.500">{errors[question.id]}</Text>}
            </FormControl>
          );
        case 'bottom_navigation_settings':
            return (
              <FormControl key={question.id} id={`question-${question.id}`} isInvalid={errors[question.id]}>
                <FormLabel>{question.question}</FormLabel>
                <CheckboxGroup value={formData[question.id] || question.options} onChange={(value) => handleChange(question.id, value)}>
                  <VStack align="start">
                    {question.options.map((option, index) => (
                      <Checkbox
                        key={index}
                        value={option}
                        isDisabled={formData[question.id]?.length <= 1 && formData[question.id]?.includes(option)}
                      >
                        {displayNames[option] || option}
                      </Checkbox>
                    ))}
                  </VStack>
                </CheckboxGroup>
                {errors[question.id] && <Text color="red.500">{errors[question.id]}</Text>}
              </FormControl>
            );
        case 'left_menu_settings':
          return (
            <FormControl key={question.id} id={`question-${question.id}`} isInvalid={errors[question.id]}>
              {renderLabel(question.question)}
              {renderRadioStyleCheckbox()}
              {errors[question.id] && <Text color="red.500">{errors[question.id]}</Text>}
            </FormControl>
          );
        case 'language_and_menu_settings':
            return (
              <FormControl isInvalid={errors[question.id]}>
                <VStack align="start" spacing={6} width="100%">
                  <Box width="100%">
                    {renderLabel(question.question.supported_languages)}
                    {renderRadioStyleCheckbox(
                      question.options.supported_languages,
                      formData[question.id]?.supported_languages,
                      (value) => handleChange(question.id, { supported_languages: value })
                    )}
                  </Box>
                  <Box width="100%">
                    {renderLabel(question.question.left_menu_settings)}
                    {renderRadioStyleCheckbox(
                      question.options.left_menu_settings,
                      formData[question.id]?.left_menu_settings,
                      (value) => handleChange(question.id, { left_menu_settings: value })
                    )}
                  </Box>
                </VStack>
                {errors[question.id] && <Text color="red.500">{errors[question.id]}</Text>}
              </FormControl>
        );
        case 'about_us':
          return (
            <FormControl key={question.id} id={`question-${question.id}`} isInvalid={errors[question.id]}>
              {renderLabel(question.question)}
              <SunEditor
                setContents={formData[question.id]}
                onChange={(value) => handleChange(question.id, value)}
                onImageUploadBefore={handleImageUploadBefore}
                setOptions={{
                  buttonList: [
                    ['undo', 'redo'],
                    ['font', 'fontSize', 'formatBlock'],
                    ['paragraphStyle', 'blockquote'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                    ['fontColor', 'hiliteColor', 'textStyle'],
                    ['removeFormat'],
                    '/', // Line break
                    ['outdent', 'indent'],
                    ['align', 'horizontalRule', 'list', 'lineHeight'],
                    ['table', 'link', 'image', 'video', 'audio' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
                    /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                    ['fullScreen', 'showBlocks', 'codeView'],
                    // ['preview', 'print'],
                    isLargerThan768 ? ['preview', 'print'] : [],
                    ['save', 'template'],
                  ],
                  // height: '400px',
                  // height: 'auto',
                  minHeight: editorMinHeight,
                  placeholder: 'Start writing...'
                }}
              />
              {errors[question.id] && <Text color="red.500">{errors[question.id]}</Text>}
            </FormControl>
          );
        default:
          return null;
      }
  }

  const renderHeading = (labelText) => {
    if (!labelText) {
      return null; // Return null if labelText is undefined or null
    }

    if (typeof labelText === 'string') {
      return <FormLabel>{labelText}</FormLabel>;
    }

    if (typeof labelText === 'object' && labelText !== null) {
    //   if (isLargerThan768) {
    //     return (
    //       <VStack align="start" spacing={1} mb={2}>
    //         {Object.entries(labelText).map(([lang, text]) => (
    //           <Heading as="h3" size="md" key={lang}>{text || ''}</Heading>
    //         ))}
    //       </VStack>
    //     );
    //   }
      return <Heading as="h3" size="md" mb={2} >{labelText[currentLang] || Object.values(labelText)[0] || ''}</Heading>
    }
    return null;
  };

  return (
    <Box
      key={question.id}
      id={`question-${question.id}`}
      borderWidth={isLargerThan768 ? "1px" : "0"}
      borderRadius={10}
      p={isLargerThan768 ? 10 : 4}
      mb={0}
      boxShadow={isLargerThan768 ? "sm" : "none"}
      w="100%"
    >
      {renderHeading(question.title)}
      {renderFormControl()}
    </Box>
  );
};
