
// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import Card from "components/card/Card";
import RegionsPage from './RegionsPage';

export default function Settings() {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card
                // borderWidth="1px" 
                // borderRadius="lg" 
                // overflow="hidden" 
                // boxShadow="md"
                direction='column'
                w='100%'
                px='20px'
                overflowX={{ sm: "scroll", lg: "hidden" }}>
                <RegionsPage></RegionsPage>
            </Card>
        </Box>
    );
}
