import BannerService from "../../../../axios/service/BannerService"

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
} from '@chakra-ui/react';
import Banner from 'components/card/Mastercard';

const DeleteBannerDialog = ({ isOpen, onClose, bannerToEdit, onSubmit, bannerType }) => {

  const cardBg = '#FFFFFF'; // Replace with your preferred background color
  const cardBorderColor = '#E2E8F0'; // Replace with your preferred border color

  const [loading, setLoading] = useState(false); // Loading state

  const handleDelete = async () => {
    try {
      // Simulate API call to delete banner
      const resp = (bannerType === 'home_banner') ? await BannerService.deleteHomeBanners(bannerToEdit.id) :  await BannerService.deleteMainBanners(bannerToEdit.id);
      if (resp.error) throw resp.error;
      alert('Banner Deleted', 'The banner has been deleted successfully.');
      onClose();
      onSubmit();
    } catch (error) {
      console.error('Error deleting banner:', error.message);
      alert(error.message);
    }
  };

  useEffect(() => {
    console.log(bannerToEdit)
    setLoading(false)
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <ModalHeader>Delete Banner</ModalHeader>
        <ModalCloseButton />
        <ModalBody>

        {loading && (
              <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                <Spinner />
              </Box>
            )}
          
        <Box
            bg={cardBg}
            borderRadius="xl"
            border="1px"
            borderColor={cardBorderColor}
            p={8}
            boxShadow="xl"
            textAlign="center"
          >
            <Heading size="md" mb={4}>
              Are you sure you want to delete this banner?
            </Heading>
            <Box>
              {/* <Heading size="sm">Banner ID: {id}</Heading> */}
              {/* <Box mt={2}>
                <Heading size="xs">URL:</Heading>
                <Box></Box>
              </Box> */}
              {/* Add more details of the banner as needed */}
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleDelete}>
            Delete
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteBannerDialog;
