import React, { useState } from 'react';
import RegionCard from './RegionCard';

import BasePageComponent from '../../components/base/BasePageComponent';

const RegionsPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'region';
  const joins = [
    { table: 'region_translation', fields: '*', foreignKey: 'id' }
  ];
  
  const filterFields = [
    { value: 'name', label: 'Name' },
  ];

  const sortFields = [
    { value: 'name', label: 'Name' },
  ];

  const itemsPerPageOptions = [5, 10, 15];

  const languages = ['en', 'fr', 'jp', 'de', 'es']; // Example languages

  return (
    <BasePageComponent
      txt="Regions"
      itemCardComponent={RegionCard}
      viewStyle="grid" // Set the initial view style to grid
      tableName={tableName}
      joins={joins}
      gridView={true}
      showAddIcon={false}
    />
  );
};

export default RegionsPage;
