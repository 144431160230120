// FormFields/CheckboxField.jsx
import React from 'react';
import { FormControl, FormLabel, Checkbox, Stack } from '@chakra-ui/react';

const CheckboxField = ({ label, value, onChange, options, ...rest }) => (
  <FormControl mb={4}>
    <FormLabel>{label}</FormLabel>
    <Stack direction="row">
      {options.map((option, index) => (
        <Checkbox
          key={index}
          value={option.value}
          isChecked={(value || []).includes(option.value)}
          onChange={(e) => {
            const newValue = e.target.checked
              ? [...(value || []), option.value]
              : (value || []).filter(v => v !== option.value);
            onChange(newValue);
          }}
        >
          {option.label}
        </Checkbox>
      ))}
    </Stack>
  </FormControl>
);

export default CheckboxField;