import React, { useState } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent';
import AddEditVIPLevelModal from './AddEditVIPLevelModal';
import VIPLevelCard from './VIPLevelCard';
import VIPLevelCardList from './VIPLevelCardList';

const VIPLevelsPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'member_tier_config';

  const itemsPerPageOptions = [5, 10, 15];

  const keyMappings = {
    tier: 'Tier',
    points_required: 'Points Required',
    valid_period: 'Valid Period',
    card_face: 'Card Face',
    expense_point_ratio: 'Expense Point Ratio',
    created_at: 'Created At',
    updated_at: 'Updated At'
  };

  const tableHeaders = [
    'tier',
    'points_required', 
    'valid_period', 
    'card_face', 
    'expense_point_ratio', 
    'created_at', 
    'updated_at',
  ];
  return (
    <BasePageComponent
      txt="Member Tier"
      addItemComponent={AddEditVIPLevelModal}
      itemCardComponent={VIPLevelCard}
      itemCardListComponent={VIPLevelCardList}
      showToggleView={false}
      tableName={tableName}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} // Pass key mappings to BasePageComponent
      defaultSortKey={'tier'} // Add default sort key
      defaultSortOrder={'desc'}
    />
  );
};

export default VIPLevelsPage;