import React, { useState } from 'react';
import BasePageComponent from '../../../components/base/BasePageComponent';
import AddEditForceUpdateModal from './AddEditForceUpdateModal';
import ForceUpdateCard from './ForceUpdateCard';
// import VIPLevelCardList from './VIPLevelCardList';

// {
//   "id": 1,
//   "platform": "ios",
//   "version": "1.0.0",
//   "created_at": "2024-04-17T08:24:26.119227+00:00"
// }

const ForceUpdatesPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'force_update_history';

  const itemsPerPageOptions = [5, 10, 15];

  const keyMappings = {
    // id: 'ID',
    platform: 'Platform',
    // existing_version: 'Existing Version',
    mandatory_update_version: 'Mandatory Update Version',
    last_modified_date: 'Last Modify Date',
    effective_date: 'Effective Date',
    // updated_at: 'Updated At'
  };

  const tableHeaders = [
    // 'id',
    'platform', 
    // 'existing_version',
    'mandatory_update_version', 
    'last_modified_date', 
    'effective_date'
    // 'updated_at', 
  ];
  return (
    <BasePageComponent
      txt="Force Update"
      addItemComponent={AddEditForceUpdateModal}
      itemCardComponent={ForceUpdateCard}
      // itemCardListComponent={VIPLevelCardList}
      showToggleView={false}
      tableName={tableName}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} // Pass key mappings to BasePageComponent
    />
  );
};

export default ForceUpdatesPage;
