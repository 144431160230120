// api/coupons.js
export const fetchCoupons = async () => {
  // Sample data generation function
  const generateCoupons = () => {
    const coupons = [];
    for (let i = 1; i <= 50; i++) {
      coupons.push({
        id: i,
        title: `Coupon ${i}`,
        description: `Description for coupon ${i}`,
        coupon_code: `CODE${i}`,
        redeem_start_date: `2024-06-01T00:00:00+00:00`,
        redeem_end_date: `2024-12-31T23:59:59+00:00`,
        total_quantity: 100,
        redeem_quantity: Math.floor(Math.random() * 100),
        require_condition: `Condition for coupon ${i}`,
        effect_start_date: `2024-07-01T00:00:00+00:00`,
        effect_end_date: `2024-12-31T23:59:59+00:00`,
        create_date: `2024-05-01T00:00:00+00:00`,
        update_date: `2024-05-15T00:00:00+00:00`,
      });
    }
    return coupons;
  };

  // Return the generated sample data
  return generateCoupons();
};
