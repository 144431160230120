import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import SignInCentered from "views/auth/signIn/index";

import QuestionnairePage from "views/preview/index";

import PageTitle from './PageTitle';



import './api/global';

const titles = {
	'/preview': 'Customize App',
	// Add more paths and titles as needed
};

const defaultTitle = 'RewardGo CMS'; // This will be used when no specific title is found

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<ThemeEditorProvider>
				<HashRouter>
					<PageTitle titles={titles} defaultTitle={defaultTitle} />
					<Switch>
						<Route path={`/preview`} render={() => (
							<QuestionnairePage />
						)} />
						<Route path={`/auth`} component={AuthLayout} />
						<Route path={`/admin`} render={() => (
							localStorage.getItem('authenticated') ? (
								<AdminLayout />
							) : (
								<Redirect to="/auth/sign-in" />
							)
						)} />
						<Redirect from='/' to='/auth/sign-in'/>
					</Switch>
				</HashRouter>
			</ThemeEditorProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);

