import axios from 'axios';
import { supabase } from "../../api/supabase";

const table_name = 'cms_users'

const UserService = {
    async getAllCMSUsers(body) {
        const res = await supabase
            .from('cms_users')
            .select(`*, cms_user_roles (*)`)
            // .is('deleted_at', null)
            .order('created_at')
        return res
       
    },

    async createUser(body) {

        const reqBody = { 'name': body['name'], 'email': body['email'], 'password': body['password'], 'role':body['role'] }

        const { data, error } = await supabase.functions.invoke('cms_user_registration',{ body: reqBody,method: 'POST' });
        if (error) {
            console.error('Error user registration:', error);
        } else {
            console.log('User registration successfully', data);
        }
        return { data, error }
    },

    async deleteUser(id) {

        const reqBody = { 'userId': id.toString() }

        const { data, error } = await supabase.functions.invoke('cms_user_deletion', { body: reqBody, method: 'POST' });
        if (error) {
            console.error('Error user deletion:', error);
        } else {
            console.log('User deletion successfully', data);
        }
        return { data, error }
    },

    async updateUser(id, body) {

        const userBody = {'name':body['name'],'email':body['email']}
        const userRes = await supabase
            .from(table_name)
            .update(userBody)
            .eq('user_id', id)
    
        if (userRes.error) {
            return userRes;
        }
    
        // role
        const roleBody = {'role':body['role']}
        const roleRes = await supabase
            .from('cms_user_roles')
            .update(roleBody)
            .eq('user_id', id)
    
        if (roleRes.error) {
            return roleRes;
        }
    
        return userRes;
    }

}

export default UserService