import FullImagePreview from "../banners/PreviewBannerDialog"

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Flex, Select, Spinner, Text, useDisclosure, SimpleGrid, Alert, AlertIcon } from '@chakra-ui/react';
import PageControl from './PageControl';
import { AddIcon, SearchIcon } from '@chakra-ui/icons';
import CustomTable from './CustomTable'; // Import CustomTable
import Pagination from './Pagination';

const BasePageComponent = ({
  txt,
  fetchData,
  addItemComponent: AddEditModal,
  itemCardComponent: ItemCard,
  itemCardListComponent: ItemCardList,
  searchPlaceholder = '',
  filterFields = [],
  sortFields = [],
  itemsPerPageOptions = [5, 10, 15],
  defaultSortKey = 'id',
  defaultSortOrder = 'asc',
  showAddIcon = 'always', // Updated prop: can be boolean or 'always', 'never', 'hideWhenData'
  showFilter = true,
  showSearch = true,
  showSort = true,
  showPagination = true,
  tableName,
  joins = [],
  primaryKey = 'id',
  tableHeaders = [], // Custom keys for table headers
  keyMappings = {}, // Key mappings for display names
  saveData, // Function to save data (create/update)
  gridView = false,
  tempData = [],
  columns=[],

  dateCondition = {
    type: 'none', // 'none', 'single', 'range', or 'custom'
    fields: {
      start: 'startDate',
      end: 'endDate',
      single: 'effectiveDate'
    },
    customLogic: null
  },
  disabledButtons = [], // Array of buttons to disable: ['edit', 'delete']

}) => {
  const [data, setData] = useState(tempData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState(`${defaultSortKey}-${defaultSortOrder}`);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [isGridView, setIsGridView] = useState(gridView);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [alert, setAlert] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);


  // Updated function to determine whether to show the add icon
  const shouldShowAddIcon = useCallback(() => {
    if (typeof showAddIcon === 'boolean') {
      return showAddIcon;
    }
    switch (showAddIcon) {
      case 'never':
        return false;
      case 'hideWhenData':
        return data.length === 0;
      case 'always':
      default:
        return true;
    }
  }, [showAddIcon, data.length]);

  // Checks if the current date is within the effective date range for a given item.
  const isButtonDisabled = useCallback((item, buttonType) => {
    if (!disabledButtons.includes(buttonType)) {
      return false;
    }

    const currentDate = new Date();

    switch (dateCondition.type) {
      case 'single':
        const effectiveDate = new Date(item[dateCondition.fields.single]);
        return currentDate < effectiveDate;

      case 'range':
        const startDate = new Date(item[dateCondition.fields.start]);
        const endDate = new Date(item[dateCondition.fields.end]);
        return currentDate >= startDate && currentDate <= endDate;

      case 'custom':
        return dateCondition.customLogic ? dateCondition.customLogic(item, currentDate) : false;

      case 'none':
      default:
        return false;
    }
  }, [dateCondition, disabledButtons]);

  // Clear alert after 2 seconds
  useEffect(() => {
    if (alert && alert.status === "success") {
      const timer = setTimeout(() => {
        setAlert(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const handleRefresh = useCallback(async () => {
    setLoading(true);
    try {
      let result;
      
      result = await global.genericapi.fetchRecords(tableName, joins);

      let filteredData = result;

      // Apply filter if any
      if (filter) {
        filteredData = filteredData.filter(item => item[filterFields[0].value] === filter);
      }

      // Apply search if any
      if (searchQuery) {
        filteredData = filteredData.filter(item =>
          Object.values(item).some(val =>
            String(val).toLowerCase().includes(searchQuery.toLowerCase())
          )
        );
      }

      // Apply sorting
      const sortedData = sortData(filteredData, sort);
      setData(sortedData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [fetchData, joins, filter, sort, searchQuery, filterFields, tableName]);

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleSearch = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearchQuery('');
    handleRefresh();
  }, []);

  const handleFilterChange = useCallback((e) => {
    setFilter(e.target.value);
    handleRefresh();
  }, []);

  const handleSortChange = useCallback((sortField) => {
    setSort((prevSort) => {
      const [field, direction] = prevSort.split('-');
      const newDirection = field === sortField && direction === 'asc' ? 'desc' : 'asc';
      return `${sortField}-${newDirection}`;
    });
    handleRefresh();
  }, []);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((e) => {
    setItemsPerPage(Number(e.target.value));
  }, []);

  const handleToggleView = useCallback(() => {
    setIsGridView(!isGridView);
  }, [isGridView]);

  const sortData = (data, sort) => {
    const [field, direction] = sort.split('-');
    return data.sort((a, b) => {
      if (direction === 'asc') {
        return a[field] > b[field] ? 1 : -1;
      }
      return a[field] < b[field] ? 1 : -1;
    });
  };

  const handleDelete = async (item) => {
    setLoading(true);
    try {
      await global.genericapi.deleteRecord(tableName, item[primaryKey], primaryKey);
      // setAlert({ title: "Item deleted successfully", status: "success" });
      handleRefresh();
    } catch (error) {
      setAlert({ title: "Error deleting item", status: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    onOpen();
  };

  const handleAdd = () => {
    setSelectedItem(null);
    onOpen();
  };

  const handleView = (item) => {
    console.log(item)
    setPreviewImage(item);
    setIsPreviewOpen(true);
    console.log('setIsPreviewOpen',isPreviewOpen)
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
    setIsPreviewOpen(false);
  };

  const handleFav = async (item) => {
    setLoading(true);
    try {
      await global.genericapi.updateRecord(tableName, item[primaryKey], { fav: !item.fav }, primaryKey);
      // setAlert({ title: "Favorite updated", status: "success" });
      handleRefresh();
    } catch (error) {
      setAlert({ title: "Error updating favorite", status: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleEnableDisable = async (item) => {
    setLoading(true);
    try {
      await global.genericapi.updateRecord(tableName, item[primaryKey], { enabled: !item.enabled }, primaryKey);
      // setAlert({ title: "Status updated", status: "success" });
      handleRefresh();
    } catch (error) {
      setAlert({ title: "Error updating status", status: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleToggleActive = async (item) => {
    setLoading(true);
    try {
      await global.genericapi.updateRecord(tableName, item[primaryKey], { is_active: !item.is_active }, primaryKey);
      // setAlert({ title: "Activation status updated", status: "success" });
      handleRefresh();
    } catch (error) {
      setAlert({ title: "Error updating activation status", status: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (data) => {
    handleRefresh();
  };

  const filteredData = data;
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Button key={i} onClick={() => setCurrentPage(i)} isActive={currentPage === i} mx={1} size="sm">
          {i}
        </Button>
      );
    }
    return pages;
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <Box p={1}>
      {alert && (
        <Alert status={alert.status} mb={1}>
          <AlertIcon />
          {alert.title}
        </Alert>
      )}
      <PageControl
        txt={txt}
        onAddClick={handleAdd}
        onSearchChange={searchPlaceholder?.length == 0 ? null: handleSearch}
        searchPlaceholder={searchPlaceholder?.length == 0 ? null: searchPlaceholder}
        searchQuery={searchPlaceholder?.length == 0 ? null: searchQuery}
        onSearchClear={searchPlaceholder?.length == 0 ? null: handleSearchClear}
        filterFields={filterFields}
        onFilterChange={filterFields?.length == 0 ? null : handleFilterChange}
        filter={filter}
        sortFields={sortFields}
        onSortChange={sortFields?.length == 0 ? null :handleSortChange}
        sort={sort}
        showAddIcon={shouldShowAddIcon()} // Use the updated function here
        showSearch={showSearch}
        showFilter={showFilter}
        showSort={showSort}
        isGridView={isGridView}
        onToggleView={handleToggleView}
        onRefresh={handleRefresh}
      />

      { loading ? (
        <Box w="100%" display="flex" justifyContent="center" alignItems="center" p={1}>
            <Spinner size="xl" />
        </Box>
      ) : error ? (
        <Text color="red.500">{error.message}</Text>
      ) : data.length === 0 ? (
        <Text mt={6}>No data available</Text> // Empty data style
      ) : isGridView ? (
        <SimpleGrid columns={[1, null, 3]} spacing={6} mt={6}>
          {paginatedData.map((item) => (
            <ItemCard
              key={item[primaryKey]}
              item={item}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onFav={handleFav}
              onEnableDisable={handleEnableDisable}
              onView={handleView}
              onToggleActive={handleToggleActive}
            />
          ))}
        </SimpleGrid>
      ) : (
        <CustomTable
          data={paginatedData}
          tableHeaders={tableHeaders}
          sort={sort}
          handleSortChange={handleSortChange}
          handleView={handleView}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleFav={handleFav}
          handleEnableDisable={handleEnableDisable}
          handleToggleActive={handleToggleActive}
          keyMappings={keyMappings} // Pass key mappings to CustomTable
          columns={columns}
          isEditDisabled={(item) => isButtonDisabled(item, 'edit')}
          isDeleteDisabled={(item) => isButtonDisabled(item, 'delete')}
        />
      )}

      {showPagination && (

        <Flex justify="space-between" mt={4}>
          <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              itemsPerPageOptions={itemsPerPageOptions}
          />
        </Flex>
            
      )}

      {showPagination && (
        <Flex mt={4} justify="space-between" align="center">
          <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              itemsPerPageOptions={itemsPerPageOptions}
              maxPaginationButtons={5}
            />
        </Flex>
      )}

        {AddEditModal && (
                    <AddEditModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onRefresh={handleRefresh}
                    initialData={selectedItem}
                    onSave={handleSave}
                  />
      )}


{/* <FullImagePreview isOpen={true} onClose={handleClosePreview} imageUrl={'hwww'} /> */}
      {/* {isPreviewOpen && (
        <FullImagePreview isOpen={true} onClose={handleClosePreview} imageUrl={previewImage} />
      )} */}
    {/* <FullImagePreview isOpen={isPreviewOpen} onClose={handleClosePreview} imageUrl={previewImage} /> */}
    </Box>
  );
};

export default BasePageComponent;









