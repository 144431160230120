import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditVIPLevelModal = ({ isOpen, onClose, initialData, onSave }) => {
  const dataStructure = {
    tier: { type: 'select', label: 'Tier', options: [
        {label:'Gold',value:'gold'},
        {label:'Silver',value:'silver'},
        {label:'Basic',value:'basic'},
    ],required: true },
    points_required: { type: 'number', label: 'Points Required', required: true },
    valid_period: { type: 'number', label: 'Valid Period', required: true },
    card_face: { type: 'image', label: 'Card Face', required: false },
    expense_point_ratio: { type: 'number', label: 'Expense Point Ratio', required: true },
  };

  return (
    <BaseAddEditModal
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="member_tier_config"
      mainKey='tier'
    />
  );
};
export default AddEditVIPLevelModal;










