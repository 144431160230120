import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditStoreModal = ({ isOpen, onClose, initialData, onSave }) => {
  const couponDataStructure = {
    latitude: { type: 'text', label: 'Latitude' },
    longitude: { type: 'text', label: 'Longitude' },
  };

  return (
    <BaseAddEditModal
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={couponDataStructure}
      tableName="store"
    />
  );
};

export default AddEditStoreModal;








