import {
  Button,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import Banner from "../../../views/admin/profile/components/AdminBanner";
import avatar from "../../../assets/img/avatars/avatar4_icon.png";

export default function SidebarDocs() {
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const borderColor = useColorModeValue("white", "navy.800");
  const role = localStorage.getItem('role')
  const username = localStorage.getItem('username')
  return (
      <Banner
          gridArea='1 / 1 / 2 / 2'
          avatar={avatar}
          name={username}
          job={role}
      />
  );
}
