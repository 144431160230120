import React, { useState } from 'react';
import TabbarsCard from './TabbarsCard';

import BasePageComponent from '../../../components/base/BasePageComponent';

const TabbarsPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'bottom_navigation_settings';

  const sortFields = [
    { value: 'name', label: 'Name' },
  ];

  const itemsPerPageOptions = [5, 10, 15];

  return (
    <BasePageComponent
      txt="Tab Menu"
      itemCardComponent={(props) => <TabbarsCard {...props} />}
      sortFields={sortFields}
      itemsPerPageOptions={itemsPerPageOptions}
      maxPaginationButtons={5}
      viewStyle="grid" // Set the initial view style to grid
      tableName={tableName}
      primaryKey='item_type'
      defaultSortKey='sort_order' // Add default sort key
      gridView={true}
      showAddIcon={false}
    />
  );
};

export default TabbarsPage;
