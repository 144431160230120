import { supabase } from "../../api/supabase";

const AuthService = {

    async signIn(body) {
        const res = await supabase.auth.signInWithPassword({
            email: body['email'],
            password: body['password'],
        })
        return res
    },

    async signIn(body) {
        const res = await supabase.auth.signInWithPassword({
            email: body['email'],
            password: body['password'],
        })
        return res
    },

    async signOut(body) {
        const res = await supabase.auth.signOut()
        return res
    },

    async signUp(body) {
        const res = await supabase.auth.signUp({
            email: body['email'],
            password: body['password'],
        })
        return res
    },

    async getUser() {
        const res = await supabase.auth.getUser()
        return res
    },

    async updateLast() {
        const { data, error } = await supabase.rpc('update_cms_user_last_sign_in');
        if (error) {
            console.error('Error updating last sign-in timestamp:', error);
        } else {
            console.log('Last sign-in timestamp updated successfully');
        }
        return { data, error }
    },



}
export default AuthService