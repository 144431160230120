import React, { useState } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent';
import AddEditCouponModal from './AddEditCouponPage';
import CouponCard from './CouponCard';
import CouponCardList from './CouponCardList';

import { fetchCoupons } from './mockData'; 

const CouponsPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'coupons';
  const joins = [
    { table: 'coupon_translations', fields: '*', foreignKey: 'id' },
    { table: 'coupon_redemptions', fields: '*', foreignKey: 'id' }
  ];

  const filterFields = [
    { value: 'valid', label: 'Valid' },
    { value: 'expired', label: 'Expired' },
  ];

  const sortFields = [
    { value: 'title', label: 'Title' },
    { value: 'redemption_start_date', label: 'Redemption Start Date' },
    { value: 'redemption_end_date', label: 'Redemption End Date' },
    { value: 'created_at', label: 'Create Date' },
    { value: 'updated_at', label: 'Update Date' },
  ];

  const itemsPerPageOptions = [5, 10, 15];

  const keyMappings = {
    caption: 'Caption',
    image_url: 'Image',
    detail: 'Detail',
    // language: 'Language',
    effective_start_date: 'Effective Start Date',
    effective_end_date: 'Effective End Date',
    redemption_start_date: 'Redemption Start Date',
    redemption_end_date: 'Redemption End Date',
    total_quantity: 'Total Quantity',
    remaining_quantity: 'Remaining Quantity',
    points_required: 'Points Required',
  };

  const tableHeaders = [
    'translations.0.caption',
    'translations.0.image_url',
    'translations.0.detail',
    // 'translations.0.language',
    'redemption_start_date', 
    'redemption_end_date', 
    'total_quantity', 
    'remaining_quantity', 
    'effective_start_date', 
    'effective_end_date', 
    'points_required', 
  ];

  return (
    <BasePageComponent
      txt="Coupons"
      fetchData={fetchCoupons}
      addItemComponent={AddEditCouponModal}
      itemCardComponent={CouponCard}
      itemCardListComponent={CouponCardList}
      showToggleView={false}
      tableName={tableName}
      joins={joins}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} // Pass key mappings to BasePageComponent
    />
  );
};

export default CouponsPage;
