/* eslint-disable */
import React, { useReducer, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import {
  Box, Flex, HStack, Text, useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Collapse,
  Stack,
  Center,
  Icon,
  Link,
  Spacer

} from "@chakra-ui/react";

import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdSettings,
  MdBrokenImage,
  MdLogout,
  MdManageAccounts,
  MdManageHistory,
  MdNotificationAdd,
  MdSpaceDashboard,
  MdOutlineCardMembership,
  MdCardMembership,
  MdMap,
  MdOutlineLoyalty,
  MdNewspaper,
  MdGroups
} from "react-icons/md";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes } = props;

  // /admin/promotion/home /promotion/main false

  const activeModule = (route) => {
      let ele = route.subs[0]
      return location.pathname.includes(ele)
  };

  const tintFont = (routePath) => {
    return location.pathname.includes(routePath.toLowerCase()) ? "bold" : "normal"
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (links) => {


  const member = {
    'name':'Dashboard',
    'path': '/admin/dashboard',
    'icon': <Icon as={MdHome} width='20px' height='20px' color='inherit' />
  }

    const users = {
      'name': 'User Management', 'path': 'admin/users', 'items': [
        // { 'path': '/admin/users/roles', 'name': 'User Permissions' },
        { 'path': '/admin/users/accounts', 'name': 'User Accounts' },
        { 'path': '/admin/users/log', 'name': 'User Activity Logs' },
        // { 'path': '/admin/users/flow', 'name': 'Approval Flow' }
      ],
      'icon': <Icon as={MdManageAccounts} width='20px' height='20px' color='inherit' />
    }

    const setting = {
      'name': 'Front Setting', 'path': 'admin/setting', 'items': [
        {
          'path': 'admin/setting/app', 'name': 'App Setting', 'items': [
            { 'path': '/admin/setting/app/icon', 'name': 'App Icon' },
            { 'path': '/admin/setting/app/launch', 'name': 'Tutorial Image' },
            { 'path': '/admin/setting/app/theme', 'name': 'Theme' },
            { 'path': '/admin/setting/app/language', 'name': 'Support Language' },
            { 'path': '/admin/setting/app/tabbar', 'name': 'Tab Menu' },
            { 'path': '/admin/setting/app/leftbar', 'name': 'Sliding Menu' }
          ]
        },
        {
          'path': 'admin/setting/versions/list', 'name': 'Force Update', 'items': [
            { 'path': '/admin/setting/versions/list', 'name': 'Force Update' }
          ]
        },
        {
          'path': 'admin/setting/maintainence', 'name': 'Maintainence', 'items': [
            { 'path': '/admin/setting/maintainence', 'name': 'Maintainence' }
          ]
        },
      ],
      'icon': <Icon as={MdSettings} width='20px' height='20px' color='inherit' />
    }

    const promotion = {
      'name':'Promotion','path':'admin/promotion', 'items': [
        { 'path': '/admin/promotion/main', 'name': 'Main Banners' },
        { 'path': '/admin/promotion/home', 'name': 'Home Banners' }
      ],
      'icon': <Icon as={MdBrokenImage} width='20px' height='20px' color='inherit' />,
    }

    const latestnews = {
      'name':'Latest News',
      'path': '/admin/latestnews',
      'icon': <Icon as={MdNewspaper} width='20px' height='20px' color='inherit' />
    }

    const storelocator = {
      'name':'Store Locator','path':'admin/storelocator', 'items': [
        { 'path': '/admin/storelocator/regions', 'name': 'Regions' },
        { 'path': '/admin/storelocator/storeinfo', 'name': 'Store Info' }
      ],
      'icon': <Icon as={MdMap} width='20px' height='20px' color='inherit' />
    }

    const membership = {
      'name':'Membership','path':'admin/membership/tier', 'items': [
        { 'path': '/admin/membership/tier', 'name': 'Member Tier' }
      ],
      'icon': <Icon as={MdGroups} width='20px' height='20px' color='inherit' />
    }

    const reward = {
      'name':'Reward','path':'admin/reward', 'items': [
        { 'path': '/admin/reward/coupons', 'name': 'Coupons' }
      ],
      'icon': <Icon as={MdOutlineLoyalty} width='20px' height='20px' color='inherit' />
    }

    const notification = {
      'name':'Push Notification','path':'admin/pushnotification', 'items': [
        { 'path': '/admin/pushnotification/generic', 'name': 'Generic Push' }
      ],
      'icon': <Icon as={MdNotificationAdd} width='20px' height='20px' color='inherit' />
    }

    const logout = {'name':'Log out','path':'/auth/sign-out',
      'icon': <Icon as={MdLogout} width='20px' height='20px' color='inherit' />
    }


    return (
      <Accordion allowToggle allowMultiple>
        {createLevel1(member)}
        {createLevel1(latestnews)}
        {createLevel2(promotion)}
        {createLevel2(reward)}
        {createLevel2(storelocator)}
        {createLevel2(membership)}
        {createLevel2(notification)}
        {createLevel3(setting)}
        {createLevel2(users)}
        {createLevel1(logout)}
        
      </Accordion>
    );
  };

  const createLevel1 = (route) => {

    const name = route.name
    const icon = route.icon
    const path = route.path

    return (
      <AccordionItem border='none'>
        <h2>
          <NavLink to={path}>
            <AccordionButton
              color={location.pathname.includes(path) ? activeColor : textColor}
            >

              <HStack w='100%'>
                <Box
                  w='20px'
                  h='20px'
                >
                  {icon}
                </Box>
                <Text
                  me='auto'
                >
                  {name}
                </Text>
              </HStack>

            </AccordionButton>
          </NavLink>
        </h2>
      </AccordionItem>
    );
  }

  const createLevel2 = (route) => {

    const name = route.name
    const icon = route.icon
    const path = route.path
    const items = route.items
    
    return (
      <AccordionItem border='none'>
        <h2>
          <AccordionButton
            color={location.pathname.includes(path) ? activeColor : textColor}
          >
              <HStack w='100%'>
                <Box
                  w='20px'
                  h='20px'
                >
                  {icon}
                </Box>
                <Text
                  me='auto'
                >
                  {name}
                </Text>
              </HStack>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={1} pl={7}>
          {/* Level 2 */}
          <Accordion allowToggle>
            {
              items.map((item, index) => {
                return (
                  <AccordionItem border='none'>
                    <h2>
                      <NavLink to={item.path}>
                        <AccordionButton
                          color={location.pathname.includes(item.path) ? activeColor : textColor}
                        >
                          <Box flex="1" textAlign="left">
                            <Text
                              me='auto'
                            >
                              {item.name}
                            </Text>
                          </Box>
                        </AccordionButton>
                      </NavLink>
                    </h2>
                  </AccordionItem>
                );
              })
            }
          </Accordion>
        </AccordionPanel>
      </AccordionItem>
    );
  }


  const createLevel3 = (route) => {

    const name = route.name
    const icon = route.icon
    const path = route.path
    const items = route.items

    return (
      <AccordionItem border='none'>
        <h2>
          <AccordionButton
            color={location.pathname.includes(path) ? activeColor : textColor}
          >
              <HStack w='100%'>
                <Box
                  w='20px'
                  h='20px'
                >
                  {icon}
                </Box>
                <Text
                  me='auto'
                >
                  {name}
                </Text>
              </HStack>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={1} pl={7} pr={0}>
          {/* Level 2 */}
          <Accordion allowToggle>
            {
              items.map((item, index) => {
                return (
                  <AccordionItem border='none' >
                    <h2>
                      <AccordionButton
                        color={location.pathname.includes(item.path) ? activeColor : textColor}
                      >
                        <Box flex="1" textAlign="left">
                          {item.name}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pt={0.5} pb={1}>
                      {/* Level 3 */}
                      <Accordion allowToggle allowMultiple>
                        {
                          item.items.map((subItem, index) => {
                            return (
                              <AccordionItem border='none'>
                                <h2>
                                  <NavLink to={subItem.path}>
                                    <AccordionButton
                                      color={location.pathname.includes(subItem.path) ? activeColor : textColor}
                                    >
                                      <Box flex="1" textAlign="left">
                                        {subItem.name}
                                      </Box>
                                    </AccordionButton>
                                  </NavLink>
                                </h2>
                              </AccordionItem>
                            )
                          })
                        }
                      </Accordion>
                    </AccordionPanel>
                  </AccordionItem>
                );
              })
            }
          </Accordion>
        </AccordionPanel>
      </AccordionItem>
    );
  }



  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
