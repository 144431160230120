import {
  Flex,
  Stack,
  Heading,
  Text,
  Input,
  Button,
  Box,
  Icon,
  useColorModeValue,
  createIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
  useMediaQuery,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import Icon_3 from './image/Icon_3.png'; // Assuming you've moved the icon to a separate file
import GradientSubmitButton from './GradientSubmitButton';


  export default function ErrorPopup({ isOpen, onClose, message }) {

    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
    
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.5)" onClick={onClose} />
      <ModalContent
        bg='white'
        color='white'
        mx="auto"
        my="auto"
        borderRadius="md"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal content
        w={["90%", "80%", "60%", "40%"]} // Responsive width for mobile, tablet, small desktop, and larger screens
        // maxW="500px" // Maximum width
      >
        <ModalBody display="flex" alignItems="center" justifyContent="center">
          <Flex
            direction="column"
            align="center"
            justify="center"
            textAlign="center"
            w="full"
          >
            <Box h={10}></Box>
            <Image src={Icon_3} w={24} h={24} mb={4} />
            <Heading fontSize={["2xl", "3xl"]} mb={4} color="black" fontWeight="bold">
              Registration Fail
            </Heading>
            <Text fontSize={["md", "lg"]} mb={6} color="black">
              {message}
            </Text>
            <Box width="100%" pt={0}>
                <GradientSubmitButton 
                  onClick={onClose}
                  borderRadius={10}
                  isLargerThan768={isLargerThan768}
                  width="100%"
                  height="44px"
                >
                  Close
                </GradientSubmitButton>
              </Box>
            <Box h={10}></Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
};

