export const displayNames = {
    "en": "English",
    "zh-Hant": "Traditional Chinese",
    "zh-Hans": "Simplified Chinese",
  
    "about_us": "About Us",
    "terms_and_conditions": "Terms and Conditions",
    "privacy_policy": "Privacy Policy",
    
    "language_setting": "Language Setting",
    "notification_setting": "Notification Setting",
    "member_tier": "Member Tier",
    "terms_and_conditions": "Terms and Conditions",
    "privacy_policy": "Privacy Policy",
  
    "settings": "Settings",
    
    "home": "Home",
    "redeem": "Redeem",
    "reward": "Reward",
    "profile": "Profile",
  };

  export const translations = {
    title: {
      'en': "Customize Your Own App with​",
      'zh-Hant': "自訂您的應用程式​",
    },
    subtitle: {
      'en': "​",
      'zh-Hant': "​",
    },
    submitButton: {
      'en': "Next​",
      'zh-Hant': "下一步​",
    },

  emailPlaceholder: {
    en: "Email",
    es: "Correo electrónico",
    fr: "E-mail",
    de: "E-Mail",
  },
  questions: {
    color_theme_setting: {
      'en': 'Choose Your Preferred App Theme Color',
      'zh-Hant': '選擇您偏好的應用程式主題顏色​',
    },
    supported_languages: {
      'en': 'Select Your App Supported Languages',
      'zh-Hant': '選擇您的應用程式支援的語言​',
    },
    left_menu_settings: {
      'en': 'Select Items for the App Sliding Menu',
      'zh-Hant': '選擇應用程式側邊選單項目​'
    },
    about_us: {
      'en': 'Customize Your Company Information - About Us',
      'zh-Hant': '自訂您的公司資訊 - 關於我們​'
    }
  },
  questions_title: {
    color_theme_setting: {
      'en': 'Appearance',
      'zh-Hant': '外觀'
    },
    language_and_menu_settings: {
      'en': 'Preference',
      'zh-Hant': '偏好'
    },
    about_us: {
      'en': 'Introduction',
      'zh-Hant': '簡介'
    }
  }
};

export const translations_languages = [
    "en",
    "zh-Hant"
  ]
