import {
    Button,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    NumberInput,
    NumberInputField,
    Input,
    Select,
    FormControl,
    FormLabel,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Spinner,
    InputGroup,
    InputRightElement,
    Alert, AlertIcon, Box, AlertTitle, AlertDescription, CloseButton,
    IconButton,
    HStack
} from "@chakra-ui/react";
import React, { useReducer, useEffect, useState } from "react";
import Card from "components/card/Card";
import UserService from "../../../../axios/service/UserService"
import AuthService from "../../../../axios/service/AuthService"
import RoleService from "../../../../axios/service/RoleService"

import { ChevronDownIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";

export default function UserCreatePage() {
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const [newUser, setNewUser] = useState({'role':'client_admin'});
    const [updateUser, setUpdateUser] = useState({});
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [userList, setUserList] = useState([]);

    const cancelRef = React.useRef();
    const cancelUpdateRef = React.useRef();
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [updateDialog, setUpdateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState('');
    const [updateUserId, setUpdateUserId] = useState('')
    const [loadingData, setLoadingData] = useState(true)
    const [showAlert, setShowAlert] = useState(false)
    const [showUpdateAlert, setShowUpdateAlert] = useState(false)

    const [roleList, setRoleList] = useState([]);
    const [updateRoles, setUpdateRoles] = useState([]);
    // const [roleDisabled, setRoleDisabled] = useState(true)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    useEffect(() => {
        getUsers()
    }, [])

    const deleteUserDialogOnClose = () => {
        setDeleteDialog(false);
    }

    const updateDialogOnClose = () => {
        setUpdateDialog(false);
        setShowUpdateAlert(false)
    }


    const openCreateUserDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setShowAlert(false)
    };

    const handleEmail = (e) => {
        setNewUser(preUser => ({ ...preUser, email: e.target.value }));
    };

    const handleUpdateEmail = (e) => {
        setUpdateUser(preUser => ({ ...preUser, email: e.target.value }));
    };

    const handleName = (e) => {
        setNewUser(preUser => ({ ...preUser, name: e.target.value }));
    };

    const handleUpdateName = (e) => {
        setUpdateUser(preUser => ({ ...preUser, name: e.target.value }));
    };

    const handlePassword = (e) => {
        setNewUser(preUser => ({ ...preUser, password: e.target.value }));
    };

    const handleUpdatePassword = (e) => {
        setUpdateUser(preUser => ({ ...preUser, password: e.target.value }));
    };

    const handleRole = (e) => {
        setNewUser(preUser => ({ ...preUser, role: e.target.value }));
    };

    const handleUpdateRole = (e) => {
        setUpdateUser(preUser => ({ ...preUser, role: e.target.value }));
    };

    const getUsers = () => {
        setLoadingData(true)
        UserService.getAllCMSUsers().then(response => {
            console.log("User response is ", response)
            setLoadingData(false)
            if (response.status == 200) {
                setUserList(response.data)
            } else {
                setUserList([])
            }
        })
        setRoleList(['client_admin', 'client_editor', 'client_viewer'])
        setUpdateRoles(['client_admin', 'client_editor', 'client_viewer'])
    }

    const deleteUserDialogOnOpen = (userId) => {
        setDeleteUserId(userId)
        setDeleteDialog(true);
    };

    const updateUserDialogOnOpen = (userId) => {
        setUpdateUserId(userId)
        const user = userList.find(user => user.user_id === userId)
        const updateBody = { 'user_id': user.user_id, 'name': user.name, 'email': user.email, 'role': user.cms_user_roles.role }
        setUpdateUser(updateBody)
        setUpdateDialog(true);
    };

    //API
    const createUser = () => {
        
        console.log("创建的User为:", newUser)
        setError('')
        if (newUser.role && newUser.name && newUser.password) {
            setShowAlert(false)
            setLoading(true)
            UserService.createUser(newUser).then(response => {
                console.log("CreateUser", response)
                setLoading(false)
                setDialogOpen(false)
                if (response?.error) {
                    setShowError(true)
                    setError(response?.error?.message)
                }else {
                    getUsers()
                }
            })
        } else {
            setLoading(false)
            setShowAlert(true)
        }
    }

    const handleUpdateUser = () => {
        console.log('updateUser',updateUser)
        if (updateUser.email && updateUser.name && updateUser.role) {
            setShowUpdateAlert(false)
            setUpdateLoading(true)
            const updateBody = { 'name': updateUser.name, 'email': updateUser.email, 'role': updateUser.role }
            UserService.updateUser(updateUser.user_id, updateBody).then(response => {
                console.log("UpdateUser api", updateBody, updateUser.user_id, response)
                setUpdateLoading(false)
                if (response.status == 204) {
                    setUpdateUser({})
                    updateDialogOnClose()
                    getUsers()
                } else {
                    alert("Something went wrong: " + response.error.message)
                }
            })
        }else {
            setShowUpdateAlert(true)
        }
    }

    const handleDeleteUser = () => {
        
        if (deleteUserId) {
            setDeleteLoading(true)
            UserService.deleteUser(deleteUserId).then(response => {
                console.log("deleteUser ", deleteUserId, response)
                setDeleteLoading(false)
                if (response.error) {
                    alert("Something went wrong: " + response.error?.message)
                } else {
                    deleteUserDialogOnClose()
                    getUsers()
                }
            })
        }
    }

    const showRole = (role) => {
        var value = ''
        if (role == 'super_admin') {
            value = 'Admin'
        } else if (role == 'client_admin') {
            value = 'Admin'
        } else if (role == 'client_editor') {
            value = 'Editor'
        } else if (role == 'client_viewer') {
            value = 'Viewer'
        } else {

        }
        return value
    }

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)

    const handleDate = (dat) => {
        if (dat == null) { return ''; }
        const milliseconds = Date.parse(dat);
        const date = new Date(milliseconds);

        const options = { year: "numeric", month: "long", day: "numeric" };
        const dateString = date.toLocaleDateString("en-US", options);
        return dateString;
    }

    const handleTime = (dat) => {
        if (dat == null) { return ''; }
        const milliseconds = Date.parse(dat);
        const date = new Date(milliseconds);
        const options = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: '2-digit', hour12: true };
        const dateString = date.toLocaleDateString("en-US", options);
        return dateString;
    }

    return (
        <>
            <Card
                direction='column'
                w='100%'
                px='0px'
                overflowX={{ sm: "scroll", lg: "hidden" }}>
                <Flex px='25px' justify='space-between' mb='20px' align='center'>
                    <Text
                        color={textColor}
                        fontSize='22px'
                        fontWeight='700'
                        lineHeight='100%'>
                        User Accounts
                    </Text>
                    <Button variant='action' ml="auto" onClick={openCreateUserDialog}>Create User</Button>
                </Flex>

                <Table variant='simple' color='gray.500' mb='24px'>
                    <Thead>
                        <Tr>
                            <Th
                                pe='10px'
                                pl='15px'
                                pr='15px'
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color='gray.400'>
                                    Display Name
                                </Flex>
                            </Th>
                            <Th
                                pe='10px'
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color='gray.400'>
                                    Email
                                </Flex>
                            </Th>
                            <Th
                                pe='10px'
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color='gray.400'>
                                    Role
                                </Flex>
                            </Th>
                            <Th
                                pe='10px'
                                pl='15px'
                                pr='15px'
                                borderColor={borderColor}>
                                
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color='gray.400'>
                                    JOIN DATE
                                </Flex>
                            </Th>
                            <Th
                                pe='10px'
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color='gray.400'>
                                    Last Login Time
                                </Flex>
                            </Th>
                            <Th
                                pe='10px'
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color='gray.400'>
                                    ACTIONS
                                </Flex>
                            </Th>
                            <Th
                                pe='10px'
                                borderColor={borderColor}>
                            </Th>
                        </Tr>
                    </Thead>
                    {loadingData ? (
                        <Flex align='center' justify='center' minHeight='100px' marginLeft='auto' w='100%'>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                            />
                        </Flex>
                    ) : (
                        <Tbody>
                            {
                                userList.map((user, index) => {
                                    return (
                                        <Tr key={user.user_id}>
                                            <Td align='center'>
                                                <Text color={textColor} fontSize='ml'>
                                                    {user.name}
                                                </Text>
                                            </Td>
                                            <Td align='center'>
                                                <Text
                                                    color={textColor}
                                                    fontSize='15px'
                                                    // fontWeight='700'
                                                    lineHeight='100%'>
                                                    {user.email}
                                                </Text>
                                            </Td>
                                            <Td align='center'>
                                                <Text
                                                    color={textColor}
                                                    fontSize='15px'
                                                    // fontWeight='700'
                                                    lineHeight='100%'>
                                                    {showRole(user.cms_user_roles?.role)}
                                                </Text>
                                            </Td>
                                            <Td align='center'>
                                                <Text
                                                    color={textColor}
                                                    fontSize='15px'
                                                    // fontWeight='700'
                                                    lineHeight='100%'>
                                                    {handleDate(user.created_at)}
                                                </Text>
                                            </Td>
                                            <Td align='center'>
                                                <Text
                                                    color={textColor}
                                                    fontSize='15px'
                                                    // fontWeight='700'
                                                    lineHeight='100%'>
                                                    {handleTime(user.last_sign_in_at)}
                                                </Text>
                                            </Td>

                                            <Td>
                                                <HStack>
                                                    <IconButton
                                                        onClick={() => updateUserDialogOnOpen(user.user_id)}
                                                        icon={<EditIcon />}
                                                        colorScheme="blue"
                                                        aria-label="Edit"
                                                        size="sm"
                                                        _disabled={{ opacity: 0.4, cursor: 'not-allowed' }}
                                                    />

                                                    <IconButton
                                                        onClick={() => deleteUserDialogOnOpen(user.user_id)}
                                                        icon={<DeleteIcon />}
                                                        colorScheme="red"
                                                        aria-label="Delete"
                                                        size="sm"
                                                        _disabled={{ opacity: 0.4, cursor: 'not-allowed' }}
                                                    />
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    )}

                </Table>
                <AlertDialog
                    isOpen={isDialogOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={handleCloseDialog}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Create User
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                <FormControl isRequired>
                                    <FormLabel>Email</FormLabel>
                                    <Input placeholder='email' onChange={handleEmail} />
                                    <FormLabel>User Name</FormLabel>
                                    <Input placeholder='name' onChange={handleName} />
                                    <FormLabel mt={4}>Password</FormLabel>
                                    <InputGroup size='md' marginBottom={2}>
                                        <Input placeholder='password' type={show ? 'text' : 'password'}
                                            onChange={handlePassword} />
                                        <InputRightElement width='4.5rem'>
                                            <Button h='1.75rem' size='sm' onClick={handleClick}>
                                                {show ? 'Hide' : 'Show'}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <Text fontSize='xs' marginBottom={3}>(Password must be at least 8 characters long
                                        and contain at least one uppercase letter, one lowercase letter, one digit, and
                                        one special character.)</Text>
                                    <FormLabel mt={4}>Role</FormLabel>
                                    <Select value={newUser.role} onChange={handleRole}>
                                        {roleList.map((role) => (
                                            <option key={role} value={role} selected={role === newUser.role}>{showRole(role)}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>

                            </AlertDialogBody>
                            {showAlert ? (
                                <Box marginLeft='25px' marginRight='25px'>
                                    <Alert status='warning'>
                                        <AlertIcon />
                                        You didn't fill in some required fields
                                    </Alert>
                                </Box>) : (<Box></Box>)}

                            <AlertDialogFooter>
                                <Button isLoading={loading} ref={cancelRef} onClick={handleCloseDialog}>
                                    Cancel
                                </Button>
                                <Button isLoading={loading} colorScheme='red' onClick={createUser} ml={3}>
                                    Confirm
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>

                <AlertDialog
                    isOpen={deleteDialog}
                    leastDestructiveRef={cancelRef}
                    onClose={deleteUserDialogOnClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Delete User
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                Are you sure deleted this User?
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button isLoading={deleteLoading} ref={cancelRef} onClick={deleteUserDialogOnClose}>
                                    Cancel
                                </Button>
                                <Button
                                    isLoading={deleteLoading}
                                    colorScheme='red'
                                    onClick={() => handleDeleteUser()} ml={3}>
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>

                <AlertDialog
                    isOpen={updateDialog}
                    leastDestructiveRef={cancelUpdateRef}
                    onClose={updateDialogOnClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Update User
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                <FormControl isRequired>

                                    <FormLabel>Email</FormLabel>
                                    <Input disabled='false' placeholder='name' value={updateUser.email} onChange={handleUpdateName} />

                                    <FormLabel>User Name</FormLabel>
                                    <Input placeholder='email' value={updateUser.name} onChange={handleUpdateName} />

                                    <FormLabel mt={4}>Role</FormLabel>
                                    <Select value={updateUser.role} onChange={handleUpdateRole}>
                                        {updateRoles.map((role) => (
                                            <option key={role} value={role} selected={role === updateUser.role}>{showRole(role)}
                                            </option>
                                        ))}
                                    </Select>

                                </FormControl>
                            </AlertDialogBody>
                            {showUpdateAlert ? (
                                <Box marginLeft='25px' marginRight='25px'>
                                    <Alert status='warning'>
                                        <AlertIcon />
                                        You didn't fill in some required fields
                                    </Alert>
                                </Box>) : (<Box></Box>)}
                            <AlertDialogFooter>
                                <Button isLoading={updateLoading} ref={cancelUpdateRef} onClick={updateDialogOnClose}>
                                    Cancel
                                </Button>
                                <Button
                                    isLoading={updateLoading}
                                    colorScheme='red'
                                    onClick={() => handleUpdateUser()} ml={3}>
                                    Update
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>

            </Card>
            {
                showError ? (
                    <Box position="fixed" top="150px" left="50%" transform="translateX(-50%)" width="400px" display="flex"
                        justifyContent="center" alignItems="center">
                        <Box width="100%">
                            {/* 在 Box 组件中使用绝对定位将 Alert 组件放置在页面的中间位置 */}
                            {/*<Alert status="error" variant="solid" flexDirection="column" alignItems="center" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">*/}
                            {/*    <AlertIcon />*/}
                            {/*    <AlertDescription>Your Chakra experience may be degraded.</AlertDescription>*/}
                            {/*    /!*<AlertDescription>Something went wrong: {e.response.data.message}</AlertDescription>*!/*/}
                            {/*    /!*<CloseButton position="absolute" right="8px" top="8px" onClick={() => setShowUpdateAlert(false)} />*!/*/}
                            {/*</Alert>*/}
                            {/*<Alert status='error' variant='solid' flexDirection='column' alignItems='center'>*/}
                            {/*    <AlertIcon />*/}
                            {/*    <AlertTitle>Your browser is outdated!</AlertTitle>*/}

                            {/*</Alert>*/}
                            <Alert status='error'>
                                <AlertIcon />
                                <AlertTitle>System Error!</AlertTitle>
                                <AlertDescription>Something went wrong: {error}</AlertDescription>
                                <CloseButton position="absolute" right="8px" top="8px"
                                    onClick={() => setShowError(false)} />
                                {/*<AlertDescription>Your Chakra experience may be degraded.</AlertDescription>*/}
                            </Alert>
                        </Box>

                    </Box>
                ) : (<></>)
            }
        </>

    );
}
