// FormFields/SelectField.jsx
import React from 'react';
import { FormControl, FormLabel, Select } from '@chakra-ui/react';

const SelectField = ({ label, value, onChange, options, ...rest }) => (
  <FormControl mb={4}>
    <FormLabel>{label}</FormLabel>
    <Select
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      {...rest}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  </FormControl>
);

export default SelectField;
