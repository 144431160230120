import {
    Button,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    NumberInput,
    NumberInputField,
    Input,
    Select,
    FormControl,
    FormLabel,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Spinner,
    InputGroup,
    InputRightElement,
    Alert, AlertIcon, Box, AlertTitle, AlertDescription, CloseButton
} from "@chakra-ui/react";
import React, {useReducer, useEffect, useState} from "react";
import Card from "components/card/Card";
import RoleService from "../../../../axios/service/RoleService"
import AuthService from "../../../../axios/service/AuthService"

import {ChevronDownIcon} from "@chakra-ui/icons";

export default function UserCreatePage() {
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const [newUser, setNewUser] = useState({});
    const [updateUser, setUpdateUser] = useState({});
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [userList, setUserList] = useState([]);
    const cancelRef = React.useRef();
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [updateDialog, setUpdateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState('');
    const [updateUserId, setUpdateUserId] = useState('')
    const [loadingData, setLoadingData] = useState(true)
    const [showAlert, setShowAlert] = useState(false)
    const [showUpdateAlert, setShowUpdateAlert] = useState(false)
    const apiUrl = process.env.REACT_APP_FIREBASE_URL
    const [roleList] = useState(['user', 'admin'])
    const [roleDisabled, setRoleDisabled] = useState(false)
    const [error, setError] = useState('')
    const [showError,setShowError] = useState(false)
    useEffect(() => {
        getUsers()
        if (roleList.length <= 1) {
            setRoleDisabled(true)
        }
    }, [])

    const deleteUserDialogOnClose = () => {
        setDeleteDialog(false);
    }

    const updateDialogOnClose = () => {
        setUpdateDialog(false);
        setShowUpdateAlert(false)
    }


    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setShowAlert(false)
    };

    const handleName = (e) => {
        setNewUser(preUser => ({...preUser, name: e.target.value}));
    };

    const handleEmail = (e) => {
        setNewUser(preUser => ({...preUser, email: e.target.value}));
    };

    const handleUpdateName = (e) => {
        setUpdateUser(preUser => ({...preUser, name: e.target.value}));
    };

    const handlePassword = (e) => {
        setNewUser(preUser => ({...preUser, password: e.target.value}));
    };

    const handleUpdatePassword = (e) => {
        setUpdateUser(preUser => ({...preUser, password: e.target.value}));
    };

    const handleRole = (e) => {
        setNewUser(preUser => ({...preUser, role: e.target.value}));
    };

    const handleUpdateRole = (e) => {
        setUpdateUser(preUser => ({...preUser, role: e.target.value}));
    };

    const getUsers = () => {
        setLoadingData(true)
        RoleService.getRoles().then(response => {
            console.log("Roles response is ", response)
            setLoadingData(false)
            if (response.status == 200) {
                setUserList(response.data)
            }else {
                setUserList([])
            }
        })
    }
    
    const createUser = () => {
        setLoading(true)
        console.log("创建的User为:", newUser)
        if (!newUser.role) {
            console.log("进入newUser赋值")
            newUser.role = 'user'
        }
        if (newUser.role && newUser.name && newUser.password) {
            setShowAlert(false)
            const body = {'name':newUser['name'],'email':newUser['email'],'password':newUser['password'],'user_id':'a0829146-72a5-481b-9cfc-2079f6ddcd6f'}
            // const body = {'name':newUser['name'],'email':newUser['email']}

            AuthService.signUp(newUser).then(response => {
                console.log("SignUp ", body, response)
                // setLoadingData(false)
                // if (response.status == 200) {
                //     // setUserList(response.data)
                // } else {
                //     setLoading(false)
                //     setShowError(true)
                //     setError(response.error.message)
                // }
            })

            // RoleService.createUser(body).then(response => {
            //     console.log("CreateUser",body , response)
            //     setLoading(false)
            //     setDialogOpen(false)
            //     if (response.status == 201) {
            //         getUsers()
            //     } else {
            //         setShowError(true)
            //         setError(response.error.message)
            //     }
            // })
        } else {
            setLoading(false)
            setShowAlert(true)
        }


    }

    const handleDeleteUser = () => {
        setDeleteLoading(true)
        if (deleteUserId) {
            // RoleService.deleteUser(deleteUserId).then(response => {
            //     console.log("deleteUser ", deleteUserId, response)
            //     setDeleteLoading(false)
            //     if (response.status == 204) {
            //         setDeleteDialog(false)
            //         getUsers()
            //     } else {
            //         alert("Something went wrong: " + response.error.message)
            //     }
            // })
        }
    }

    const deleteUserDialogOnOpen = (userId) => {
        setDeleteUserId(userId)
        setDeleteDialog(true);
    };

    const updateUserDialogOnOpen = (userId) => {
        setUpdateUserId(userId)
        const updateUserTest = userList.find(user => user.user_id === userId)
        setUpdateUser(updateUserTest)
        setUpdateDialog(true);
    };

    const handleUpdateUser = () => {

        setUpdateLoading(true)
        if (!updateUser.role) {
            updateUser.role = 'user'
        }
        const body = {'name':updateUser.name}
        RoleService.updateRole(updateUser.user_id,body).then(response => {
            console.log("UpdateUser api", body, updateUser.user_id, response)
            setLoadingData(false)
            // if (response.da === 204) {
            //     setUpdateDialog(false)
            //     setUpdateUser({})
            //     getUsers()
            // } else {
            //     setUpdateLoading(false)
            //     alert("Something went wrong: " + response.error.message)
            // }
        })
        if (updateUser && updateUserId && updateUser.password && updateUser.role) {
            console.log("updateUser body is ", updateUser)
        } else {
            setShowUpdateAlert(true)
            setUpdateLoading(false)
        }
    }
    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)

    const handleDate = (dat) => {  
        const milliseconds = Date.parse(dat);
        const date = new Date(milliseconds);
        const options = { year: "numeric", month: "long", day: "numeric" };
        const dateString = date.toLocaleDateString("en-US", options);
        return dateString;
    }

    return (
        <>
            <Card
                direction='column'
                w='100%'
                px='0px'
                overflowX={{sm: "scroll", lg: "hidden"}}>
                <Flex px='25px' justify='space-between' mb='20px' align='center'>
                    <Text
                        color={textColor}
                        fontSize='22px'
                        fontWeight='700'
                        lineHeight='100%'>
                        User Permissions
                    </Text>
                    {/* <Button variant='action' ml="auto" onClick={handleOpenDialog}>Create Role</Button> */}
                </Flex>

                <Table variant='simple' color='gray.500' mb='24px'>
                    <Thead>
                        <Tr>
                            <Th
                                pe='10px'
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{sm: "10px", lg: "12px"}}
                                    color='gray.400'>
                                    Role
                                </Flex>
                            </Th>
                            <Th
                                pe='10px'
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{sm: "10px", lg: "12px"}}
                                    color='gray.400'>
                                    JOIN DATE
                                </Flex>
                            </Th>
                            <Th
                                pe='10px'
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{sm: "10px", lg: "12px"}}
                                    color='gray.400'>
                                    UPDATE DATE
                                </Flex>
                            </Th>
                            {/* <Th
                                pe='10px'
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{sm: "10px", lg: "12px"}}
                                    color='gray.400'>
                                    ACTION
                                </Flex>
                            </Th> */}
                        </Tr>
                    </Thead>
                    {loadingData ? (
                        <Flex align='center' justify='center' minHeight='100px' marginLeft='auto' w='100%'>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                            />
                        </Flex>
                    ) : (
                        <Tbody>
                            {
                                userList.map((user, index) => {
                                    return (
                                        <Tr key={user.user_id}>
                                            <Td align='center'>
                                                <Text
                                                    color={textColor}
                                                    fontSize='15px'
                                                    fontWeight='700'
                                                    lineHeight='100%'>
                                                    {user.role}
                                                </Text>
                                            </Td>
                                            <Td align='center'>
                                                <Text
                                                    color={textColor}
                                                    fontSize='15px'
                                                    fontWeight='700'
                                                    lineHeight='100%'>
                                                    {handleDate(user.created_at)}
                                                </Text>
                                            </Td>
                                            <Td align='center'>
                                                <Text
                                                    color={textColor}
                                                    fontSize='15px'
                                                    fontWeight='700'
                                                    lineHeight='100%'>
                                                    {handleDate(user.updated_at)}
                                                </Text>
                                            </Td>
                                            {/* <Td align='center'>
                                                <Menu>
                                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon/>}>
                                                        Actions
                                                    </MenuButton>
                                                    <MenuList>
                                                        <MenuItem
                                                            onClick={() => updateUserDialogOnOpen(user.user_id)}>Update</MenuItem>
                                                        <MenuItem
                                                            onClick={() => deleteUserDialogOnOpen(user.user_id)}>Delete</MenuItem>
                                                    </MenuList>
                                                </Menu>
                                            </Td> */}
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    )}

                </Table>
                <AlertDialog
                    isOpen={isDialogOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={handleCloseDialog}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Create User
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                {/*<Input placeholder="user name" size='md'*/}
                                {/*       value={inputValue} onChange={handleInputChange}*/}
                                {/*>*/}
                                {/*</Input>*/}
                                {/*<Input placeholder="password" size='md'*/}
                                {/*       value={inputValue} onChange={handleInputChange}*/}
                                {/*>*/}
                                {/*</Input>*/}
                                {/*<Select placeholder='role'>*/}
                                {/*    <option value='user'>user</option>*/}
                                {/*    <option value='admin'>admin</option>*/}
                                {/*</Select>*/}
                                <FormControl isRequired>
                                    <FormLabel>Email</FormLabel>
                                    <Input placeholder='email' value={newUser.email} onChange={handleEmail}/>
                                    <FormLabel>User Name</FormLabel>
                                    <Input placeholder='name' value={newUser.name} onChange={handleName}/>
                                    <FormLabel mt={4}>Password</FormLabel>
                                    <InputGroup size='md' marginBottom={2}>
                                        <Input placeholder='password' type={show ? 'text' : 'password'}
                                               value={newUser.password} onChange={handlePassword}/>
                                        <InputRightElement width='4.5rem'>
                                            <Button h='1.75rem' size='sm' onClick={handleClick}>
                                                {show ? 'Hide' : 'Show'}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <Text fontSize='xs' marginBottom={3}>(Password must be at least 8 characters long
                                        and contain at least one uppercase letter, one lowercase letter, one digit, and
                                        one special character.)</Text>
                                    <FormLabel mt={4}>Role</FormLabel>
                                    <Select isDisabled={roleDisabled} value={newUser.role} onChange={handleRole}>

                                        {/*<option value={roleList}>user</option>*/}
                                        {/*<option value='admin'>admin</option>*/}
                                        {roleList.map((role) => (
                                            <option key={role} value={role}
                                                    selected={role === newUser.role}>{role}</option>
                                        ))}
                                    </Select>
                                </FormControl>

                            </AlertDialogBody>
                            {showAlert ? (
                                <Box marginLeft='25px' marginRight='25px'>
                                    <Alert status='warning'>
                                        <AlertIcon/>
                                        You didn't fill in some required fields
                                    </Alert>
                                </Box>) : (<Box></Box>)}

                            <AlertDialogFooter>
                                <Button isLoading={loading} ref={cancelRef} onClick={handleCloseDialog}>
                                    Cancel
                                </Button>
                                <Button isLoading={loading} colorScheme='red' onClick={createUser} ml={3}>
                                    Confirm
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>

                <AlertDialog
                    isOpen={deleteDialog}
                    leastDestructiveRef={cancelRef}
                    onClose={deleteUserDialogOnClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Delete User
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                Are you sure deleted this User?
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button isLoading={deleteLoading} ref={cancelRef} onClick={deleteUserDialogOnClose}>
                                    Cancel
                                </Button>
                                <Button
                                    isLoading={deleteLoading}
                                    colorScheme='red'
                                    onClick={() => handleDeleteUser()} ml={3}>
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>

                <AlertDialog
                    isOpen={updateDialog}
                    leastDestructiveRef={cancelRef}
                    onClose={updateDialogOnClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Update User
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                <FormControl isRequired>
                                    <FormLabel>User Name</FormLabel>
                                    <Input placeholder='name' value={updateUser.name} onChange={handleUpdateName}/>

                                    {/* <FormLabel>Password</FormLabel>
                                    <InputGroup size='md' marginBottom={2}>
                                        <Input placeholder='password' type={show ? 'text' : 'password'}
                                               value={updateUser.password} onChange={handleUpdatePassword}
                                               marginBottom={2}/>
                                        <InputRightElement width='4.5rem'>
                                            <Button h='1.75rem' size='sm' onClick={handleClick}>
                                                {show ? 'Hide' : 'Show'}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    {/*<Input placeholder='password' value={updateUser.password} onChange={handleUpdatePassword} marginBottom={2}/>*/}
                                    {/* <Text fontSize='xs' marginBottom={3}>(Password must be at least 8 characters long
                                        and contain at least one uppercase letter, one lowercase letter, one digit, and
                                        one special character.)</Text> */}
                                    {/* <FormLabel mt={4}>Role</FormLabel> */} 
                                    {/* <Select isDisabled={roleDisabled} value={updateUser.role}
                                            onChange={handleUpdateRole}> */}
                                        {/*<option value='user'>user</option>*/}
                                        {/*<option value='admin'>admin</option>*/}
                                        {/* {roleList.map((role) => (
                                            <option key={role} value={role}
                                                    selected={role === newUser.role}>{role}</option>
                                        ))} */}
                                    {/* </Select> */}
                                </FormControl>
                            </AlertDialogBody>
                            {showUpdateAlert ? (
                                <Box marginLeft='25px' marginRight='25px'>
                                    <Alert status='warning'>
                                        <AlertIcon/>
                                        You didn't fill in some required fields
                                    </Alert>
                                </Box>) : (<Box></Box>)}
                            <AlertDialogFooter>
                                <Button isLoading={updateLoading} ref={cancelRef} onClick={updateDialogOnClose}>
                                    Cancel
                                </Button>
                                <Button
                                    isLoading={updateLoading}
                                    colorScheme='red'
                                    onClick={() => handleUpdateUser()} ml={3}>
                                    Update
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
                {/*<Flex justifyContent='center' alignItems='center' width='100vw' height='100vh'>*/}

                {/*</Flex>*/}


                {/*<Alert status='error' variant='solid' flexDirection='column' alignItems='center'>*/}
                {/*    <AlertIcon />*/}
                {/*    <AlertTitle>Your browser is outdated!</AlertTitle>*/}
                {/*    */}
                {/*</Alert>*/}
            </Card>
            {
                showError ? (
                    <Box position="fixed" top="150px" left="50%" transform="translateX(-50%)" width="400px" display="flex"
                         justifyContent="center" alignItems="center">
                        <Box width="100%">
                            {/* 在 Box 组件中使用绝对定位将 Alert 组件放置在页面的中间位置 */}
                            {/*<Alert status="error" variant="solid" flexDirection="column" alignItems="center" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">*/}
                            {/*    <AlertIcon />*/}
                            {/*    <AlertDescription>Your Chakra experience may be degraded.</AlertDescription>*/}
                            {/*    /!*<AlertDescription>Something went wrong: {e.response.data.message}</AlertDescription>*!/*/}
                            {/*    /!*<CloseButton position="absolute" right="8px" top="8px" onClick={() => setShowUpdateAlert(false)} />*!/*/}
                            {/*</Alert>*/}
                            {/*<Alert status='error' variant='solid' flexDirection='column' alignItems='center'>*/}
                            {/*    <AlertIcon />*/}
                            {/*    <AlertTitle>Your browser is outdated!</AlertTitle>*/}

                            {/*</Alert>*/}
                            <Alert status='error'>
                                <AlertIcon/>
                                <AlertTitle>System Error!</AlertTitle>
                                <AlertDescription>Something went wrong: {error}</AlertDescription>
                                <CloseButton position="absolute" right="8px" top="8px"
                                             onClick={() => setShowError(false)}/>
                                {/*<AlertDescription>Your Chakra experience may be degraded.</AlertDescription>*/}
                            </Alert>
                        </Box>

                    </Box>
                ) : (<></>)
            }
        </>

    );
}
